/* eslint-disable react/destructuring-assignment */
import { ReactNode } from 'react';

interface IProps {
    width?: number,
    height?: number,
    children: ReactNode,
}
const UserCardLayout = (props:IProps) => {
    require('../../assets/styles/user/components/cardlayout.scss');
    return (
        <div className="cardlayout" style={{ width: `${props?.width}px`, height: `${props?.height}px` }}>
            {props.children}
        </div>

    );
};

export default UserCardLayout;
