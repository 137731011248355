import '../../../assets/styles/modules/components/row_for_modal.scss';

interface IProps {
    label: string;
    value?: string;
    underline?: boolean;
    underlineColorClass?: string;
    children?: any;
}
const RowForModal = ({
    label, value, underline, underlineColorClass, children,
}: IProps) => (
    <div className="row ">
        <div className="col_left">{label}</div>
        <div className={`col_right ${underline ? `bold line ${underlineColorClass || ''}` : ''}`}>
            {children || (
                <span>{value}</span>
            )}

        </div>
    </div>
);
export default RowForModal;
