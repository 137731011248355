/* eslint-disable react/prop-types */
import Header from './Header';
import BackCrumbsDate from './BackCrumbsDate';

interface INameProps {
    children: React.ReactNode
}

const Layout: React.FC<INameProps> = ({ children }) => (
    <>
        <Header />
        <BackCrumbsDate />
        {children}
    </>
);

export default Layout;
