import { VStack } from '../../../../components/ui/Stack';
import Node from '../Node/Node';
import { useSchemasStore } from '../store/schemas';
import { SchemaData } from '../types/orgschema';
import styles from './Tree.module.scss';

interface TreeProps {
  treeNode: SchemaData;
}

const Tree = (props: TreeProps) => {
    const { treeNode } = props;

    const zoomCount = useSchemasStore((state) => state.zoomCount);

    return (

        <VStack max className={styles.Tree}>
            <div
                className={styles.TreeWrapper}
                style={{
                    transform: `scale(${zoomCount})`,
                    transformOrigin: 'top left',
                    transition: 'transform 0.2s',
                }}

            >
                <Node node={treeNode} isChildren={false} />
            </div>

        </VStack>

    );
};

export default Tree;
