/* eslint-disable no-nested-ternary */
import { IActions } from '../../assets/interfaces/interfaces';
import ExclamationMarkSvg from '../../assets/svgs/ExclamationMarkSvg';
import '../../assets/styles/html/actions_buttons.scss';
import SuccessSvg from '../../assets/svgs/SuccessSvg';
import CloseCrossSvg from '../../assets/svgs/CloseCrossSvg';

interface IProps {
    actions: IActions[],
    onActive: Function,
    title?: string,
}
const ActionsButtons = ({ actions, title, onActive }: IProps) => (
    <div className="actions_buttons_wrapper">

        <div className="title">{title || 'Действия'}</div>

        <div className="actions">
            {actions.map((val, index) => (
                <div
                    style={{
                        backgroundColor: (val.active && val.active_bg) ? val.active_bg
                            : val.active ? '#f59e0b'
                                : '',
                    }}
                    className={`action ${val.active ? 'active' : ''}`}
                    onClick={() => onActive(index)}
                >
                    <div className="icon">
                        {val.svg === 'ExclamationMarkSvg' ? (
                            <ExclamationMarkSvg />
                        ) : val.svg === 'SuccessSvg' ? (
                            <SuccessSvg />
                        ) : val.svg === 'CloseCrossSvg' ? (
                            <CloseCrossSvg />
                        ) : ''}
                    </div>
                    <div className="text">{val.text}</div>
                </div>
            ))}
        </div>
    </div>
);
export default ActionsButtons;
