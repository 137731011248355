/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import '../../../../../assets/styles/modules/components/doc/tabel/tabel_pagination.scss';

interface IProps {
    onClick: Function;
    pageNumbers: Array<number>;
    currentPage: number;
}
const TabelPagination = ({ onClick, pageNumbers, currentPage }: IProps) => (
    <div className="pagination_container">
        <nav>
            <ul className="pagination">

                <li
                    onClick={() => onClick(currentPage - 1)}
                    className="li"
                >
                    <span className="page"> &lt; </span>
                </li>
                {pageNumbers.map((number) => (
                    <li
                        key={number}
                        onClick={(e) => onClick(number)}
                        className="li"
                        style={{ display: (number > (currentPage + 3) || (number < (currentPage - 3))) ? 'none' : '' }}
                    >
                        <span className={`page ${currentPage === number ? 'current' : ''}`}>{number}</span>
                    </li>
                ))}
                <li
                    className="li"
                    onClick={() => onClick(currentPage + 1)}
                >
                    <span className="page"> &gt; </span>
                </li>
            </ul>
        </nav>
    </div>
);
export default TabelPagination;
