/* eslint-disable react/no-array-index-key */
import { memo, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { CSSTransition } from 'react-transition-group';
import styles from './SchemasList.module.scss';
import { HStack, VStack } from '../../../../../components/ui/Stack';
import { Button, ThemeButton } from '../../../../../components/ui/Button/Button';
import { useSchemasStore } from '../../store/schemas';
import { Text, TextBold } from '../../../../../components/ui/Text/Text';
import { classNames } from '../../../../../utils/classNames/classNames';
import { Skeleton } from '../../../../../components/ui/Skeleton/Skeleton';
import { DataSchemaIcon, EditIcon, TrashIcon } from '../../../../../assets/svgs/orgschema/icons';
import { SubmitActionModal } from '../SubmitActionModal/SubmitActionModal';
import { OrgSChemaService } from '../../services/OrgSchema.service';

interface SchemasListProps {
  className?: string;
  isLoading: boolean
}

export const SchemasList = memo((props: SchemasListProps) => {
    const { className, isLoading } = props;

    const queryClient = useQueryClient();

    const [modal, setModal] = useState(false);
    const setActiveSchema = useSchemasStore((state) => state.setActiveSchema);
    const schemasList = useSchemasStore((state) => state.schemasList);
    const activeSchemaId = useSchemasStore((state) => state.activeSchemaId);
    const setActiveSchemaId = useSchemasStore((state) => state.setActiveSchema);
    const setIsEmptySchema = useSchemasStore((state) => state.setIsEmptySchema);
    const closeModal = () => {
        setModal(false);
    };

    const openModal = () => {
        setModal(true);
    };

    const { mutate: deleteSchema } = useMutation({
        mutationKey: ['delete schema'],
        mutationFn: (id: string) => OrgSChemaService.deleteSchema(id),
        onSuccess(response) {
            toast.success('Схема успешно удалена');

            queryClient.invalidateQueries({ queryKey: ['allOrgSchemaTree'] });

            if (response.data === null) {
                setIsEmptySchema(true);
            } else {
                setActiveSchemaId(response.data);
            }
        },
        onError(error: any) {
            if (
                error.response
        && error.response.status
        && error.response.data.message
            ) {
                toast.error(error.response.data.message);
            } else {
                toast.error('Ошибка при удалении схемы');
            }
        },
    });

    const onActionDelete = (id: string) => {
        deleteSchema(id);
        closeModal();
    };

    if (isLoading) {
        return (
            <HStack gap="16" className={classNames(styles.SchemasList, {}, [className])}>
                {
                    new Array(3).fill(0).map((schema, index) => (
                        <Skeleton key={index} width={100} height={40} border="10px" />
                    ))
                }
            </HStack>
        );
    }

    if (schemasList.length === 0) {
        return null;
    }

    return (
        <HStack gap="32" className={classNames(styles.SchemasList, {}, [className])}>
            {
                schemasList.map((schema) => (
                    <HStack gap="8" className={styles.schemaWrapper}>
                        <Button disabled={schema.id === activeSchemaId} onClick={() => setActiveSchema(schema.id)} key={schema.id} theme={ThemeButton.OUTLINE} className={classNames(styles.selector, { [styles.active]: schema.id === activeSchemaId }, [])}>
                            <DataSchemaIcon />
                            <Text gap="0" bold={TextBold.BOLD} isActive={schema.id === activeSchemaId} text={schema.title} />
                        </Button>
                        <CSSTransition
                            in={schema.id === activeSchemaId}
                            timeout={300}
                            unmountOnExit
                            classNames="slide-animation"
                        >
                            <VStack gap="0" justify="between" className={styles.actionWrapper}>
                                <Button theme={ThemeButton.SVG_CLEAN}>
                                    <EditIcon size={20} />
                                </Button>
                                <Button onClick={openModal} theme={ThemeButton.SVG_CLEAN} className={styles.deleteIcon}>
                                    <TrashIcon size={20} />
                                </Button>

                            </VStack>
                        </CSSTransition>

                    </HStack>

                ))
            }
            <SubmitActionModal isOpen={modal} onAction={() => onActionDelete(activeSchemaId)} title={`Удалить схему: ${schemasList.find((schema) => schema.id === activeSchemaId)?.title}`} description="Вы точно уверены, что хотите полностью удалить схему? После удаления, схему восстановить уже будет нельзя!" onClose={closeModal} />
        </HStack>
    );
});
