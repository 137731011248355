import axios from 'axios';
import { getCookie } from '../utils/cookies';

const $host = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});
const $admin = axios.create({
    baseURL: process.env.REACT_APP_ADMIN_URL,
});
const $erp = axios.create({
    baseURL: process.env.REACT_APP_ERP_URL,
});
const $task = axios.create({
    baseURL: process.env.REACT_APP_TASK_URL,
});

// const authInterceptor = config => {
//     config.headers.authorization = `Bearer ${getCookie('access_token')}`
// }

// $authHost.interceptors.request.use(authInterceptor)

export {
    $host, $admin, $erp, $task,
};
