/* eslint-disable react/destructuring-assignment */
import { Link, useNavigate } from 'react-router-dom';
import { HELLO_ROUTE, STARTER_ROUTE } from '../../utils/consts';

import LogoSvg from '../../assets/svgs/LogoSvg';

interface IProps {
    showBack: boolean,
    children: any
}
const AuthForm = (props: IProps) => {
    require('../../assets/styles/login.scss');

    return (
        <div className="container">
            {props.showBack && (
                <div className="exit"><Link to={STARTER_ROUTE}>Назад</Link></div>
            )}
            <header className="header">
                <div className="header__container">
                    <div className="img_logo_login">
                        <LogoSvg />
                    </div>
                    {props.children}
                </div>
            </header>
        </div>

    );
};

export default AuthForm;
