import { Modal } from '../../../../../components/ui/Modal/Modal';
import { VStack } from '../../../../../components/ui/Stack';
import { Text, TextBold, TextSize } from '../../../../../components/ui/Text/Text';
import { classNames } from '../../../../../utils/classNames/classNames';
import { SchemaData } from '../../types/orgschema';
import { UpdateSchemaForm } from './UpdateSchemaForm/UpdateSchemaForm';
import styles from './UpdateSchemaModal.module.scss';

interface UpdateSchemaModalProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  node: SchemaData;
}

export const UpdateSchemaModal = ({
    className, isOpen, onClose, node,
} : UpdateSchemaModalProps) => (
    <Modal lazy className={classNames(styles.UpdateSchemaModal, {}, [className])} isOpen={isOpen}>
        <VStack max align="start" gap="16">
            <Text gap="16" size={TextSize.XL} bold={TextBold.BOLD} textPrimary text={`Редактирование схемы: ${node.title}`} />
            <UpdateSchemaForm node={node} onSuccess={onClose} />
        </VStack>
    </Modal>
);
