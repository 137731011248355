import axios from 'axios';
import { CreateSchemaData, SchemaData } from '../types/orgschema';
import { SchemaSettingsProps } from '../Node/NodeSettings/useNodeSettins';
import { IFileEditInput } from '../components/FileModal/FileModal';

export const OrgSChemaService = {
    async createSchema(data: CreateSchemaData) {
        const { data: tree } = await axios.post<SchemaData>(
            'http://localhost:4200/api/schemas',
            {
                title: data.title,
                description: data.description,
                tskp: data.tskp,
            },
        );

        return tree;
    },

    async getSchemaTreeById(id: string) {
        const { data: tree } = await axios.get<SchemaData>(
            `http://localhost:4200/api/schemas/${id}`,
        );

        return tree;
    },

    async updateFiles(id: string, type: 'schemas' | 'blocks', formData: IFileEditInput) {
        const data = await axios.patch<SchemaData>(`http://localhost:4200/api/${type}/${id}`, {
            mailLink: formData.mailLink,
            cloudLink: formData.cloudLink,
            fileUrl: formData.fileUrl,
            videoUrl: formData.videoUrl,
            audioUrl: formData.audioUrl,
        });

        return data;
    },

    async updateSchema(id: string, data: CreateSchemaData, type: 'schemas' | 'blocks') {
        const { data: schema } = await axios.patch<SchemaData>(`http://localhost:4200/api/${type}/${id}`, {
            title: data.title,
            description: data.description,
            tskp: data.tskp,
            color: data.color,
        });

        return schema;
    },

    async updateSchemaSettings(id: string, data: SchemaSettingsProps, type: 'schemas' | 'blocks') {
        const { data: schema } = await axios.patch<SchemaData>(`http://localhost:4200/api/${type}/settings/${id}`, {
            isChildrenTogether: data.isChildrenTogether,
            isSchemaHidden: data.isSchemaHidden,
        });

        return schema;
    },

    async getAllSchemaTree() {
        const { data } = await axios.get<SchemaData[]>(
            'http://localhost:4200/api/schemas',
        );

        return data;
    },

    async deleteSchema(id: string) {
        const data = await axios.delete<string | null>(`http://localhost:4200/api/schemas/${id}`);

        return data;
    },

    async deleteBlock(id: string) {
        const data = await axios.delete<string>(`http://localhost:4200/api/blocks/${id}`);

        return data;
    },

    async signEmployeesToSchema(id: string, employees: string[] | undefined, type: 'schemas' | 'blocks') {
        const data = await axios.patch<SchemaData>(
            `http://localhost:4200/api/${type}/employees/${id}`,
            {
                employeeIds: employees || [],
            },
        );

        return data;
    },

    async createNewBlockToSchema(id: string, block: CreateSchemaData, type: 'schemas' | 'blocks') {
        const data = await axios.post<SchemaData>(
            `http://localhost:4200/api/${type}/children/${id}`,
            {
                title: block.title,
                description: block.description,
                tskp: block.tskp,
            },
        );

        return data;
    },
};
