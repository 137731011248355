import { useLayoutEffect, useState } from 'react';

import {
    endOfMonth, startOfMonth, subDays, subMonths,
} from 'date-fns';
import CloseCrossSvg from '../../../../../assets/svgs/CloseCrossSvg';
import CalendarSvg from '../../../../../assets/svgs/CalendarSvg';
import { IDateRange, ITextActive } from '../../../../../assets/interfaces/interfaces';
import DateRangePicker from '../elements/DateRangePicker';
import '../../../../../assets/styles/modules/components/doc/input/doc_input_datepicker.scss';

interface IProps {
    selectedRange: IDateRange[];
    onChangeSelectedRange: Function;
}
const InputDatepicker = ({ selectedRange, onChangeSelectedRange }: IProps) => {
    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }
    const [width, height] = useWindowSize();
    const [showSelectDate, setShowSelectDate] = useState<boolean>(false);

    const [showCalendar, setShowCalendar] = useState<boolean>(false);
    const [daysForSelect, setDaysForSelect] = useState<ITextActive[]>([
        { text: 'Сегодня', active: false },
        { text: 'Вчера', active: true },
        { text: 'Последние 7 дней', active: false },
        { text: 'Последние 30 дней', active: false },
        { text: 'Этот месяц', active: false },
        { text: 'Последний месяц', active: false },
        { text: 'Пользовательский', active: false },
    ]);
    const getFormattedDate = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы идут с 0, поэтому +1
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}.${month}.${day}`;
    };
    const setSelectRangeHandler = (selectedText: string) => {
        const avalibleDays = daysForSelect.map((day) => day.text);
        const today = new Date();
        let selectedDay = { startDate: today, endDate: today, key: 'selection' };
        if (avalibleDays[6] === selectedText) {
            return;
        } if (avalibleDays[0] === selectedText) {
            selectedDay = { startDate: today, endDate: today, key: 'selection' };
        } else if (avalibleDays[1] === selectedText) {
            selectedDay = { startDate: subDays(today, 1), endDate: subDays(today, 1), key: 'selection' };
        } else if (avalibleDays[2] === selectedText) {
            selectedDay = { startDate: subDays(today, 7), endDate: today, key: 'selection' };
        } else if (avalibleDays[3] === selectedText) {
            selectedDay = { startDate: subDays(today, 30), endDate: today, key: 'selection' };
        } else if (avalibleDays[4] === selectedText) {
            selectedDay = { startDate: startOfMonth(today), endDate: endOfMonth(today), key: 'selection' };
        } else if (avalibleDays[5] === selectedText) {
            selectedDay = { startDate: startOfMonth(subMonths(today, 1)), endDate: endOfMonth(subMonths(today, 1)), key: 'selection' };
        }
        onChangeSelectedRange([selectedDay]);
    };
    const closeCalendarHandler = () => {
        setShowSelectDate(false);
        setShowCalendar(false);
    };
    const onOpenRange = () => {
        setShowSelectDate(!showSelectDate);
        if (showCalendar) {
            closeCalendarHandler();
        }
    };
    const onSetDefault = () => {
        const selectedDay = { startDate: startOfMonth(new Date()), endDate: endOfMonth(new Date()), key: 'selection' };
        onChangeSelectedRange([selectedDay]);
    };
    const onSelectRange = (index: number, isCalendarOpen?: boolean) => {
        const newRange = [...daysForSelect];
        newRange.forEach((val) => val.active = false);
        newRange[index].active = true;
        setDaysForSelect(newRange);
        setSelectRangeHandler(newRange[index].text);
        if (isCalendarOpen) {
            return;
        }
        setShowCalendar(false);
        if (newRange[index].text === 'Пользовательский') {
            setShowCalendar(true);
            setShowSelectDate(false);
        }
    };
    const onApplyChangeDateRange = (range: IDateRange[]) => {
        onChangeSelectedRange(range);
        closeCalendarHandler();
    };
    const onCancelChangeDateRange = () => {
        closeCalendarHandler();
    };
    return (
        <div className="doc_input_datepicker_wrapper">
            <div className="doc_input_datepicker">
                <div
                    className="detepicker_text"
                    onClick={() => onOpenRange()}
                >
                    {getFormattedDate(selectedRange[0].startDate)}
                    {' '}
                    -
                    {getFormattedDate(selectedRange[0].endDate)}
                </div>
                <div className="detepicker_right">
                    <div className="close" onClick={() => onSetDefault()}>
                        <CloseCrossSvg />
                    </div>
                    <div className="icon" onClick={() => onOpenRange()}>
                        <CalendarSvg />
                    </div>
                </div>
                {showSelectDate ? (
                    <div className="ranges">
                        {daysForSelect.map((day, index) => (
                            <span onClick={() => onSelectRange(index)} className={`day ${day.active ? 'active' : ''}`}>{day.text}</span>
                        ))}
                    </div>
                ) : ''}

            </div>
            {showCalendar ? (
                <div className={`doc_input_datepicker_modal ${width <= 900 ? 'column' : ''}`}>
                    <DateRangePicker
                        onApplyChangeDateRange={onApplyChangeDateRange}
                        onCancelChangeDateRange={onCancelChangeDateRange}
                        onSelectRange={onSelectRange}
                        selectedRange={selectedRange}
                        daysForSelect={daysForSelect}
                        screenWidth={width}
                    />
                </div>
            ) : ''}

        </div>

    );
};

export default InputDatepicker;
