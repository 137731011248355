const PlusSvg = () => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xmlSpace="preserve" fill="#000000">
        <g id="SVGRepo_bgCarrier" strokeWidth="0" />
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
        <g id="SVGRepo_iconCarrier">
            {' '}
            <path style={{ fill: '#A4E276' }} d="M488.727,186.182H325.818V23.273C325.818,10.418,315.398,0,302.545,0H256h-46.545 c-12.853,0-23.273,10.418-23.273,23.273v162.909H23.273C10.42,186.182,0,196.6,0,209.455v93.091 c0,12.853,10.42,23.273,23.273,23.273h162.909v162.909c0,12.853,10.42,23.273,23.273,23.273H256h46.545 c12.853,0,23.273-10.42,23.273-23.273V325.818h162.909c12.853,0,23.273-10.42,23.273-23.273v-93.091 C512,196.6,501.58,186.182,488.727,186.182z" />
            {' '}
            <path style={{ fill: '#64C37D' }} d="M209.455,0c-12.853,0-23.273,10.418-23.273,23.273v162.909H23.273C10.42,186.182,0,196.6,0,209.455 v93.091c0,12.853,10.42,23.273,23.273,23.273h162.909v162.909c0,12.853,10.42,23.273,23.273,23.273H256V0H209.455z" />
            {' '}
        </g>
    </svg>
);

export default PlusSvg;
