import '../../assets/styles/modals/sure_modal.scss';

interface IProps {
    text: string,
    onYes: Function,
    onNo: Function,
    paramsForFunc?: any,
    title?: string,
    textConfirm?: string,
    // inputField?: boolean,
}
const SureModal = ({
    title, text, onNo, onYes, textConfirm, paramsForFunc,
}: IProps) => (
    <div
        onClick={() => onNo(paramsForFunc)}
        className="modal"
    >
        <div className="modal-confirm" onClick={(e) => { e.stopPropagation(); }}>
            <div className="modal-content">
                <div className="modal-header flex-column">
                    <h4 className="modal-title w-100">{title || 'Вы уверены?'}</h4>
                    <button type="button" onClick={() => onNo(paramsForFunc)} className="close">&times;</button>
                </div>
                <div className="modal-body">
                    <p>{text}</p>
                </div>
                <div className="modal-footer justify-content-center">
                    <button type="button" onClick={() => onNo(paramsForFunc)} className="btn btn-secondary">Отмена</button>
                    <button type="button" onClick={() => onYes(paramsForFunc)} className="btn btn-danger">{textConfirm || 'Да'}</button>
                </div>
            </div>
        </div>
    </div>
);

export default SureModal;
