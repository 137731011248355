import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { IUser } from '../types/orgschema';
import { UserService } from '../services/Users.service';
import { OrgSChemaService } from '../services/OrgSchema.service';
import { useDebounce } from '../hooks/useDebounce';
import { useUsersStore } from '../store/users';

export const useUsers = () => {
    const queryClient = useQueryClient();

    const searchTerm = useUsersStore((state) => state.searchTerm);

    const debouncedSearch = useDebounce(searchTerm, 500);

    const { data: users, isLoading, isError } = useQuery<IUser[]>({
        queryKey: ['all users', debouncedSearch],
        queryFn: () => UserService.getAllUsers(debouncedSearch),
    });

    const { mutate } = useMutation({
        mutationKey: ['sign users'],
        mutationFn: ({ users, id, type }: { users: string[] | undefined; id: string; type: 'schemas' | 'blocks' }) => OrgSChemaService.signEmployeesToSchema(id, users, type),
        onSuccess: () => {
            toast.success('Список пользователей успешно обновлен');
            queryClient.invalidateQueries({ queryKey: ['orgSchema'] });
        },
        onError(error: any) {
            if (
                error.response
          && error.response.status
          && error.response.data.message
            ) {
                toast.error(error.response.data.message);
            } else {
                toast.error('Ошибка при создании схемы');
            }
        },
    });

    return useMemo(() => ({
        users, isLoading, isError, mutate,
    }), [users, isLoading, isError, mutate]);
};
