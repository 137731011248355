/* eslint-disable react/destructuring-assignment */
interface IProps {
    label: string
    htmlFor: string,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    value: string,
    type?: string,
    className?: string,
    errors?: any,
}
const AdminEditField = (props: IProps) => {
    require('../../../../assets/styles/admin/components/admin_edit_field.scss');

    return (
        <div className={`admin_edit_field ${props.className}`}>
            <label htmlFor={props.htmlFor}>{props.label}</label>
            <input
                type={props.type ? props.type : 'text'}
                id={props.htmlFor}
                name={props.htmlFor}
                onChange={props.onChange}
                value={props.value}
                placeholder=""
            />
            {props.errors && (props.errors[props.htmlFor] && (props.value.length === 0)) && (
                <div className="admin_edit_field__error">
                    Поле
                    {props.label}
                    {' '}
                    обязательно для заполнения.
                </div>
            )}
        </div>

    );
};

export default AdminEditField;
