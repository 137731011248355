/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { IMaskInput } from 'react-imask';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputLabelField from './InputLabelField';
import SuccessModal from '../Modals/SuccessModal';
import { signUp } from '../../http/userAPI';
import { STARTER_ROUTE } from '../../utils/consts';

const AuthRegister = () => {
    const [error, setError] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>({});
    const [login, setLogin] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [lastname, setLastname] = useState<string>('');
    const [position_id, setPosition] = useState<number>(1);
    const [email, setEmail] = useState<string>('');
    const [emailIsValid, setEmailIsValid] = useState(true);
    const [middlename, setMiddlename] = useState<string>('');
    const [birthday, setBirthday] = useState<string>('');

    const [self_phone, setSelfPhone] = useState<string>('');
    const [job_phones, setJobphones] = useState<string>('');
    const [gender_id, setGender] = useState<number>(1);
    const [password, setPassword] = useState<string>('');
    const [passEqual, setPassEqual] = useState<boolean>(true);
    const [passMin, setPassMin] = useState<boolean>(true);
    const [image, setImage] = useState(new FormData());
    const [password_confirmation, setPasswordConfirmation] = useState<string>('');
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const [scope, setScope] = useState('');
    const [exception, setException] = useState<boolean>(false);

    const navigate = useNavigate();

    const validatePass = () => {
        setPassEqual(password === password_confirmation);
        setPassMin((password.length >= 8) && (password_confirmation.length >= 8));
        return passEqual && passMin;
    };

    const validateEmail = (email: string) => {
        if (email !== '') {
            const validEmail = String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                );
            setEmailIsValid(false);
            if (validEmail !== null) {
                setEmailIsValid(true);
            }
        } else {
            setEmailIsValid(true);
        }
    };
    const uploadImg = (e: HTMLInputElement) => {
        if (e.files && e.files[0]) {
            const image = new FormData();
            image.append('file', e.files[0]);
            setImage(image);
            const picture = new FileReader();
            picture.readAsDataURL(e.files[0]);
            picture.addEventListener('load', (event: any) => {
                const uploadedImg = document.getElementById('uploadedImage');
                if (uploadedImg) {
                    uploadedImg.setAttribute('src', event.target.result);
                    uploadedImg.style.display = 'block';
                }
            });
        }
    };
    const checkPhones = () => {
        const phones = { self: self_phone, job: job_phones };
        if (self_phone !== null && self_phone.length === 16) {
            phones.self = self_phone;
        } else {
            phones.self = '';
        }
        if (job_phones !== null && job_phones.length === 16) {
            phones.job = job_phones;
        } else {
            phones.job = '';
        }

        return phones;
    };
    const onSuccess = () => {
        setErrors({});
        setError(false);
        setException(false);
        setShowRegisterModal(true);
        setTimeout(() => {
            setShowRegisterModal(false);
            navigate(STARTER_ROUTE);
        }, 2000);
    };
    const onAuth = async () => {
        const phones = checkPhones();
        const response = await signUp(name, login, password, password_confirmation, email, middlename, birthday, phones.self, phones.job, gender_id, lastname, position_id).catch((error) => {
            if (error.response.data.errors === 'Login already exists') {
                setErrors({ login: 'Такой пользователь уже существует' });
            }
            setError(true);
        });
        if (response.status === 200) {
            validatePass();
            if (!response.data.data.errors) {
                onSuccess();
            }
            setErrors(response.data.data.errors);
            setError(true);
        }
    };
    return (
        <>
            {showRegisterModal && (
                <SuccessModal />
            )}
            <InputLabelField
                value={login}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLogin(e.target.value)}
                onBlur={() => { }}
                label="Логин"
                htmlFor="login"
                type="text"
                errors={errors}
            />
            <InputLabelField
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                onBlur={() => { }}
                label="Имя"
                htmlFor="name"
                type="text"
                errors={errors}
            />
            <InputLabelField
                value={lastname}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastname(e.target.value)}
                onBlur={() => { }}
                label="Фамилия"
                htmlFor="lastname"
                type="text"
                errors={errors}
            />
            <InputLabelField
                value={middlename}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMiddlename(e.currentTarget.value)}
                onBlur={() => { }}
                label="Отчество"
                htmlFor="middlename"
                type="text"
                errors={errors}
            />
            <label htmlFor="lastname">Должность</label>
            <select
                value={position_id}
                onSelect={(e) => setPosition(Number(e.currentTarget.value))}
                className="dropdown"
                name="position_id"
                id="position_id"
            >
                <option value={1}>Веб разработчик</option>
            </select>

            <InputLabelField
                value={email}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => validateEmail(e.currentTarget.value)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
                label="Email"
                htmlFor="email"
                isValid={!emailIsValid}
                type="text"
                errors={errors}
            />
            <InputLabelField
                value={birthday}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBirthday(e.currentTarget.value)}
                onBlur={() => { }}
                label="День рождение"
                htmlFor="birthday"
                type="date"
                errors={errors}
            />
            <label htmlFor="phones">Личный телефон</label>
            <IMaskInput
                mask="+0(000)000-00-00"
                value={self_phone}
                onInput={(e: any) => setSelfPhone(e.currentTarget.value)}
                placeholder="+7(123)456-78-90"
            />
            <label htmlFor="job_phones">Рабочий телефон</label>
            <IMaskInput
                mask="+0(000)000-00-00"
                value={job_phones}
                onInput={(e: any) => setJobphones(e.currentTarget.value)}
                placeholder="+7(123)456-78-90"
            />
            <label htmlFor="gender_id">Пол</label>
            <select
                value={gender_id}
                onSelect={(e) => setGender(Number(e.currentTarget.value))}
                className="dropdown"
                name="gender_id"
                id="gender_id"
            >
                <option value={1}>Мужской</option>
                <option value={2}>Женский</option>
            </select>
            <label htmlFor="gender_id">Фото</label>
            <input type="file" id="readUrl" onChange={(e) => uploadImg(e.currentTarget)} />
            <img id="uploadedImage" src="#" alt="Uploaded Image" style={{ display: 'none' }} />

            <InputLabelField
                value={password}
                onChange={(e: React.FormEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)}
                label="Пароль"
                htmlFor="password"
                type="password"
                onBlur={() => validatePass()}
                errors={errors}
            />
            <InputLabelField
                value={password_confirmation}
                onChange={(e: React.FormEvent<HTMLInputElement>) => setPasswordConfirmation(e.currentTarget.value)}
                label="Повторить пароль"
                htmlFor="repass"
                type="password"
                onBlur={() => validatePass()}
                errors={errors}
                customErrors={[
                    { valid: !passEqual, error: 'Пароли не совпадают' },
                    { valid: !passMin, error: 'Минимальная длина пароля 8 символов' },
                ]}
            />
            {((error) && (errors && !errors.password)) ? (
                <div className="error">Неверный логин или пароль</div>
            ) : (exception) ? (
                <div className="error">Неверные данные</div>
            ) : null}
            <div className="btn" onClick={onAuth}>Регистрация</div>
        </>

    );
};

export default AuthRegister;
