import { useEffect, useState } from 'react';

import UnLockSvg from '../../../assets/svgs/UnLockSvg';
import LockSvg from '../../../assets/svgs/LockSvg';

import {
    IEditTask, IOpenFile, ITaskCommentFile, ITaskFile,
} from '../../../assets/interfaces/interfaces';

import ToArchiveSvg from '../../../assets/svgs/ToArchiveSvg';
import FromArchiveSvg from '../../../assets/svgs/FromArchiveSvg';
import {
    checkImage, getFileLink, hrefToFile, onShowFileSlider,
} from '../../../utils/func';
import FileTaskDeleteSvg from '../../../assets/svgs/FileTaskDeleteSvg';
import FileTaskIconSvg from '../../../assets/svgs/FileTaskIconSvg';
import UserAvatarDefault from '../../../assets/svgs/UserAvatarDefault';
import TaskCheckboxSuccesSvg from '../../../assets/svgs/TaskCheckboxSuccesSvg';
import DraggableModal from '../../Modals/DraggableModal';

interface ITaskEdit {
    errors: any;
    task: IEditTask;
    onLock: Function;
    onDeleteFile: Function;
    onUpdateCheckbox: Function;
    onAddTaskToArchive: Function;
    onDeleteTask: Function;
}

const TaskInfo = ({
    task, onDeleteTask, onLock, onAddTaskToArchive, onUpdateCheckbox, onDeleteFile, errors,
}: ITaskEdit) => {
    require('../../../assets/styles/user/components/edit/task_info.scss');
    const [openFiles, setOpenFiles] = useState<IOpenFile[]>([]);

    const onResizeTextArea = () => {
        const textArea = document.getElementById('textAreaEdit');
        if (textArea) {
            textArea.style.height = 'auto';
            textArea.style.height = `${textArea.scrollHeight}px`;
        } else {
            setTimeout(() => {
                onResizeTextArea();
            }, 0);
        }
    };

    const getFileDate = (dateString: string) => {
        const options: any = {
            day: 'numeric',
            month: 'long',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
        };

        const date = new Date(dateString);
        const formattedDate = date.toLocaleString('ru-RU', options).replace(',', '');

        return formattedDate;
    };
    const onShowFileHandler = (file: ITaskFile, index:number) => {
        setOpenFiles([]);
        setTimeout(() => {
            setOpenFiles(onShowFileSlider(task.task.id, task.task.files, file, index));
        }, 1);
    };

    return (
        <>
            {openFiles.length ? (
                <DraggableModal
                    files={openFiles}
                    onClose={() => setOpenFiles([])}
                />
            ) : ''}
            <div className="info_container">
                <div className="row">
                    <div className="col_left">Название</div>
                    <div className="col_right title">{task.task.name}</div>
                </div>
                <div className="row">
                    <div className="col_left">Описание</div>
                    <div className="col_right">{task.task.descriptions ? task.task.descriptions : 'Не указано'}</div>
                </div>
                <div className="row">
                    <div className="col_left">Статус</div>
                    <div className="col_right bold line">
                        <span>{task.status}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col_left">Сроки</div>
                    <div className="col_right">
                        <span>
                            {task.task.started_at ? `${getFileDate(task.task.started_at)}` : 'Не указано'}
                            {' '}
                            -
                            {' '}
                            {task.task.finished_at ? getFileDate(task.task.finished_at) : 'Не указано'}
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col_left">Постановщик</div>
                    <div className="col_right avatars">
                        <div className="avatar">
                            {task.task.user_id && (
                                <UserAvatarDefault />
                            )}
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col_left">Исполнитель</div>
                    <div className="col_right avatars">
                        {task.task.auditors.length ? (
                            <>
                                {task.task.performers.map(((performer) => (
                                    <div className="avatar">
                                        <UserAvatarDefault />
                                    </div>
                                )))}
                            </>
                        ) : 'Не указан'}
                        <div className="avatar" />

                    </div>
                </div>
                <div className="row">
                    <div className="col_left">Аудитор</div>
                    <div className="col_right avatars">
                        {task.task.auditors.length ? (
                            <>
                                {task.task.auditors.map(((auditor) => (
                                    <div className="avatar">
                                        <UserAvatarDefault />
                                    </div>
                                )))}
                            </>
                        ) : 'Не указан'}
                    </div>
                </div>
                <div className="row">
                    <div className="col_left">Файлы</div>
                    <div className="col_right files">
                        {task.task.files.length ? (
                            <>
                                {task.task.files.map((file, index) => (
                                    <div className="file">
                                        {checkImage(file.path)
                                            ? (
                                                <>
                                                    <div
                                                        className="icon"
                                                        onClick={() => (onShowFileHandler(file, index))}
                                                    >
                                                        <span
                                                            style={{ backgroundImage: `url('${getFileLink(task.task.id, file.path)}')` }}
                                                            className="image"
                                                        />

                                                    </div>
                                                    <div className="info">
                                                        <div
                                                            className="title"
                                                            onClick={() => (onShowFileHandler(file, index))}
                                                        >
                                                            {file.path}
                                                        </div>
                                                        <div
                                                            className="created"
                                                            onClick={() => (onShowFileHandler(file, index))}
                                                        >
                                                            {getFileDate(file.created_at)}
                                                        </div>
                                                        <div className="actions">
                                                            <span className="delete" onClick={() => onDeleteFile(task, file, index)}>
                                                                <FileTaskDeleteSvg />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            : (
                                                <>
                                                    <div
                                                        className="icon"
                                                        onClick={() => hrefToFile(task.task.id, file.path)}
                                                    >

                                                        <FileTaskIconSvg />
                                                    </div>
                                                    <div className="info">
                                                        <div className="title" onClick={() => hrefToFile(task.task.id, file.path)}>{file.path}</div>
                                                        <div className="created" onClick={() => hrefToFile(task.task.id, file.path)}>{getFileDate(file.created_at)}</div>
                                                        <div className="actions">
                                                            <span className="delete" onClick={() => onDeleteFile(task, file, index)}>
                                                                <FileTaskDeleteSvg />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                    </div>
                                ))}
                            </>
                        ) : 'Не указан'}
                    </div>
                </div>

                <div className={`checklist_container ${task.task.checklists.length ? '' : 'empty'}`}>
                    <div className="checklist_container__title">Чеклист</div>
                    <div className="col_right checklists">
                        {task.task.checklists.length ? (
                            <>
                                {task.task.checklists.map((checklist, checklist_index) => (
                                    <div className="checklist">
                                        <div className="checklist_title">{checklist.name}</div>
                                        <div className="checkboxes">
                                            {checklist.checkboxes.length ? (
                                                <>
                                                    {checklist.checkboxes.map((checkbox, checkbox_index) => (
                                                        <div
                                                            onClick={() => onUpdateCheckbox(task, checklist_index, checkbox_index)}
                                                            className={`checkbox ${checkbox.complete ? 'active' : ''}`}
                                                        >
                                                            <input type="checkbox" id="taskCheckbox6" className="taskCheckboxInput" />
                                                            <div className="boxCheckbox">
                                                                <span className="success_icon">
                                                                    <TaskCheckboxSuccesSvg />
                                                                </span>
                                                            </div>
                                                            <div className="checkbox_title">{checkbox.name}</div>

                                                        </div>
                                                    ))}
                                                </>
                                            ) : (
                                                <span className="empty">Отсутствуют</span>
                                            )}

                                        </div>

                                    </div>
                                ))}
                            </>
                        ) : (
                            <span className="not_found">Не указано</span>
                        )}

                    </div>
                </div>

                <div className="row_actions">
                    <div className="title">Действия</div>
                    <div className="actions">
                        <div className="action" onClick={() => onDeleteTask({ index: null, task })}>
                            <FileTaskDeleteSvg />
                            <span className="text">Удалить</span>
                        </div>
                        <div className="action" onClick={() => onAddTaskToArchive({ index: null, task })}>
                            {task.task.archived_at ? (
                                <>
                                    <FromArchiveSvg />
                                    <span className="text">Из архива</span>
                                </>
                            ) : (
                                <>
                                    <ToArchiveSvg />
                                    <span className="text">В архив</span>
                                </>
                            )}

                        </div>
                        {task.task.archived_at !== true && (
                            <div className="action" onClick={() => onLock(task)}>
                                {(task.task.locked) ? (
                                    <>
                                        <UnLockSvg />
                                        <span className="text">Разблокировать</span>
                                    </>
                                ) : (
                                    <>
                                        <LockSvg />
                                        <span className="text">Заблокировать</span>
                                    </>
                                )}

                            </div>
                        )}
                    </div>
                </div>

            </div>
        </>

    );
};

export default TaskInfo;
