import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { checkImage } from '../../utils/func';
import FileTaskDeleteSvg from '../../assets/svgs/FileTaskDeleteSvg';
import '../../assets/styles/html/file_dropdown.scss';

interface IProps {
    title?: string,
    files: Array<File>,
    onChangeFiles: Function,
    acceptTypes?: Array<string>,
}
const FileDropdown = ({
    onChangeFiles, title, acceptTypes, files,
}: IProps) => {
    const [drag, setDrag] = useState<boolean>(false);

    const onDragLeaveFile = () => {
        setDrag(false);
    };

    const onUploadFiles = (get_files: FileList) => {
        onDragLeaveFile();
        const newFiles:File[] = [...files];

        if (get_files) {
            for (let i = 0; i < get_files.length; i++) {
                const file = get_files[i];
                if (acceptTypes) {
                    acceptTypes.forEach((val) => {
                        if (val === file.type) {
                            newFiles.push(file);
                        }
                    });
                } else {
                    newFiles.push(file);
                }
            }
            console.log('newFiles', newFiles);
            onChangeFiles(newFiles);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles: any) => {
            onUploadFiles(acceptedFiles);
        },
    });
    const getFileSize = (sizeInBytes: number) => {
        const sizeInMegabytes = sizeInBytes / (1024 * 1024);
        return `${sizeInMegabytes.toFixed(2)} МБ`; // Ограничиваем до 2 знаков после запятой
    };

    const onRemoveFile = (file_index: number) => {
        const newFiles = [...files];
        newFiles.splice(file_index, 1);
        onChangeFiles(newFiles);
    };
    const onDravOverFile = () => {
        setDrag(true);
    };

    const showImageFile = (file: any) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => {
            const fileEl = document.getElementById(file.name);
            if (fileEl) {
                fileEl.style.backgroundImage = `url('${reader.result}')`;
            }
        };
    };

    const onClickAddFiles = () => {
        const input = document.createElement('input');
        input.type = 'file';
        if (acceptTypes) {
            input.accept = acceptTypes.join(',');
        }
        input.multiple = true;
        input.click();

        input.addEventListener('change', (e: any) => {
            onUploadFiles(e.target.files);
        });
    };

    const renderFiles = (render_files: Array<File>) => {
        const returnFiles = Array.prototype.slice.call(render_files);
        return returnFiles.map((file, index) => (
            <div className="file">
                <div className="left">
                    <div className="icon">
                        {checkImage(file.name) ? (
                            <>
                                {showImageFile(file)}
                                <span id={file.name} className="image" />
                            </>
                        ) : (
                            <img className="rounded-2 dz-image" src={require('../../assets/imgs/file.png')} alt="..." />
                        )}
                    </div>
                    <div className="info">
                        <div className="title">{file.name}</div>
                        <div className="created">{getFileSize(file.size)}</div>

                    </div>
                </div>

                <span className="delete" onClick={() => onRemoveFile(index)}>
                    <FileTaskDeleteSvg />
                </span>
            </div>
        ));
    };
    return (
        <div className="file_dropdown">
            {title ? (
                <div className="title">{title}</div>
            ) : ''}
            <div
                {...getRootProps()}
                className={`drag_zone ${drag ? 'over' : ''}`}
                onDragOver={() => onDravOverFile()}
                onDragLeave={() => onDragLeaveFile()}
                onDragEnd={() => onDragLeaveFile()}
                onClick={() => onClickAddFiles()}
            >
                <div
                    onDrop={(e) => { console.log('drop'); }}
                    className="drag_zone__space"
                >
                    <img
                        className="icon"
                        onDrop={(e) => { console.log('drop'); }}
                        src={require('../../assets/imgs/image-icon.png')}
                        width="24"
                        alt=""
                    />
                    <br />
                    Добавить файл
                </div>

            </div>
            {(files && files.length) ? (
                <div className="files">
                    {renderFiles(files)}
                </div>
            ) : ''}
        </div>
    );
};

export default FileDropdown;
