import { memo } from 'react';
import { VStack } from '../../../../../../../components/ui/Stack';
import { NoUsersBg } from '../../../../../../../assets/svgs/orgschema/NoUsersBg';
import { Text, TextBold, TextSize } from '../../../../../../../components/ui/Text/Text';
import styles from './NoUsersContainer.module.scss';

export const NoUsersContainer = memo(() => (
    <VStack max gap="16" align="center" justify="center" className={styles.NoUsersContainer}>
        <NoUsersBg size={300} />
        <Text gap="0" size={TextSize.XL} bold={TextBold.BOLD} textPrimary text="Нет пользователей в этой комнате" />
    </VStack>
));
