import { eraseCookie, getCookie, setCookie } from '../utils/cookies';
import { $erp, $host, $task } from './index';

const setCookies = async (data: any) => {
    if (data.user_id) {
        setCookie('userId', data.user_id);
    }
    if (data.fio) {
        setCookie('fio', data.fio);
    }
    if (data.onetime_token) {
        setCookie('authorization_token', data.onetime_token);
    }
    if (data.access_token && data.refresh_token) {
        setCookie('access_token', data.access_token);
        setCookie('refresh_token', data.refresh_token);
    }
};

export const refreshToken = async (refresh_token: string) => {
    const response = await $host.post('auth/refresh', { refresh_token }).catch((e) => e);
    if (response.data.data && !response.data.data.error) {
        setCookies(response.data.data);
    }
};

const sendResponse = async (response: any, callback: any) => {
    if (response.response) {
        if (response.response.status === 401 || response.response.status === 403) {
            const refresh_token = getCookie('refresh_token');
            if (refresh_token) {
                await refreshToken(refresh_token);
                if (callback.param) {
                    return callback.func(callback.param, true);
                }
                return callback.func(true);
            }
        }
    }
    return response;
};

export const sendRepeatRequest = (response:any, params?:any, repeat?: boolean) => {
    if (repeat) {
        return response;
    }
    return sendResponse(response, { func: sendRepeatRequest, param: params });
};

export const checkToken = async (token: string, activate: boolean) => {
    const response = await $host.post('auth/check-token', { token, activate }).catch((e) => e);
    return response;
};
export const signUp = async (name: string, login: string, password: string, password_confirmation: string, email: string, middlename: string, birthday: string, phones: string, job_phones: string, gender_id: number, lastname: string, position_id: number) => {
    const response = await $host.post('users/register', {
        name, login, password, password_confirmation, email, middlename, birthday, phones, job_phones, gender_id, lastname, position_id, _method: 'PUT',
    }).catch((e) => e);
    return response;
};
export const logout = async () => {
    const response = await $host.post('auth/logout', { id: getCookie('userId') }, {
        headers: { Authorization: `Bearer ${getCookie('access_token')}` },
    }).catch((e) => e.response);
    eraseCookie('refresh_token');
    eraseCookie('userId');
    eraseCookie('access_token');
    eraseCookie('fio');
    return response;
};
export const authorize = async (params: string) => {
    const response = await $host.get(`auth/sso${params}`).catch((e) => e);
    if (response) {
        if (response.data.success) {
            setCookies(response.data.data);
        }
        return response;
    }
    return { data: { success: false } };
};
export const signIn = async (login: string, password: string, scope: string, client_id: string | null | undefined, client_secret: string | null | undefined, redirect_uri: string | null | undefined) => {
    const body = (client_id && client_secret && redirect_uri) ? {
        login, password, scope, client_id, client_secret, redirect_uri,
    } : { login, password, scope };
    const response = await $host.post('auth/login', body).catch((e) => e);
    if (response.data.success) {
        setCookies(response.data.data);
    }
    return response;
};

export const signInToken = async (client_id: string, client_secret: string, username: string, password: string, scope: string) => {
    const body = (client_id && client_secret) ? {
        grant_type: 'password', username, password, scope, client_id, client_secret,
    } : {
        grant_type: 'password', username, password, scope,
    };
    const response = await $erp.post('oauth/token', body).catch((e) => e);
    if (response.data.success) {
        setCookies(response.data.data);
    }
    return response;
};
export const staySession = async (login: string, password: string, scope: string) => {
    const response = await $host.post('auth/sso', { login, password, scope }).catch((e) => e);
    return response;
};
export const getUser = async (id: number, repeat?: boolean) => {
    const response = await $host.get(`users/${id}`, {
        headers: {
            Authorization: `Bearer ${getCookie('access_token')}`,
            'Access-Control-Allow-Origin': '*',

        },
    }).catch((e) => e);
    return sendRepeatRequest(response, id);
};

export const getUsers = async () => {
    const response = await fetch('http://89.107.116.192:20802/users/', {
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    }).catch((e) => e);
    return sendResponse(response, { func: getUsers, param: null });
};
