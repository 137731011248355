import { useState } from 'react';
import DocInfoBlock from '../elements/DocInfoBlock';
import '../../../../../assets/styles/modules/components/doc/doc_starter_tab.scss';

const DocStarterTab = () => {
    const [files, setFiles] = useState<Array<any>>([
        { name: 'Пример тетсового задания.odt', img: false },
        { name: 'Пример тетсового задания.odt', img: true },
    ]);
    const onDeleteFile = (index:number) => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    };
    return (
        <div className="doc_starter_tab">
            <DocInfoBlock
                title="Последний отправленный документ"
            />
            <DocInfoBlock
                title="Последний полученный документ"
            />
            <DocInfoBlock
                title="Статистика"
                stats
            />
            <div className="last_block">
                <DocInfoBlock
                    title="Рабочая папка модуля"
                    files={files}
                    onDeleteFile={onDeleteFile}
                />
            </div>

        </div>
    );
};

export default DocStarterTab;
