/* eslint-disable no-use-before-define */
import { useEffect, useRef } from 'react';
import { checkImage, getFileLink } from '../../utils/func';
import { IOpenFile } from '../../assets/interfaces/interfaces';

interface IProps {
    files: IOpenFile[],
    onClose: Function,
}
const DraggableModal = ({ files, onClose }: IProps) => {
    require('../../assets/styles/modals/draggable_modal.scss');

    function dragElement(elmnt: any) {
        let pos1 = 0; let pos2 = 0; let pos3 = 0; let
            pos4 = 0;
        const header = document.getElementById(`${elmnt.id}header`);
        if (header) {
            /* if present, the header is where you move the DIV from: */
            header.onmousedown = dragMouseDown;
        } else {
            /* otherwise, move the DIV from anywhere inside the DIV: */
            elmnt.onmousedown = dragMouseDown;
        }

        function dragMouseDown(e: any) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e: any) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            elmnt.style.top = `${elmnt.offsetTop - pos2}px`;
            elmnt.style.left = `${elmnt.offsetLeft - pos1}px`;
        }

        function closeDragElement() {
            /* stop moving when mouse button is released: */
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }
    const handleSlideChange = (swiper: any) => {
        console.log('swiper', swiper);
        // setActiveIndex(swiper.activeIndex);
    };
    useEffect(() => {
        dragElement(document.getElementById('mydiv'));
    });
    return (
        <div
            className="draggeble_modal"
            data-backdrop="false"
            id="mydiv"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="myModalLabel"
            style={{ display: files[0].path === '' ? 'none' : '' }}
        >
            <div
                id="mydivheader"
                className="daraggable_dialog"
                role="document"
            >
                <div
                    id="daraggableDialogheader"
                    className="daraggable_content"
                >
                    <div className="daraggable_header">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => onClose()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 className="modal-title" id="myModalLabel">{files[0].path}</h4>
                    </div>
                    <div className="daraggable_body">
                        <div className="arrow_bg left" />
                        <div className="arrow_bg right" />
                        {files.length > 1 ? (
                            <swiper-container
                                style={
                                    {
                                        '--swiper-navigation-color': '#fff',
                                        '--swiper-pagination-color': '#fff',
                                    }
                                }
                                onSlideChange={handleSlideChange}
                                pagination-clickable="true"
                                navigation="true"
                                class="mySwiper"
                            >
                                <>

                                    {files.map((file) => (
                                        <swiper-slide lazy="true">
                                            <div className="file">

                                                <img
                                                    src={getFileLink(file.task_id, file.path, file.task_comment_id)}
                                                    loading="lazy"
                                                    alt=""
                                                />
                                            </div>

                                        </swiper-slide>

                                    ))}
                                </>

                            </swiper-container>
                        ) : (
                            <span
                                style={{ backgroundImage: `url('${getFileLink(files[0].task_id, files[0].path, files[0].task_comment_id)}')` }}
                                className="image"
                            />

                        )}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default DraggableModal;
