import { useState } from 'react';
import UserBlockNews from '../UserBlockNews';
import UserCalendar from '../UserCalendar';
import UserHeader from '../UserHeader';
import UserWeekend from '../UserWeekend';
import UserBlockWhatsapp from '../UserBlockWhatsapp';

const UserMainPage = () => {
    require('../../../assets/styles/user/pages/user_main_page.scss');
    interface INews {
        number: number,
        title: string,
        bg: string,
        content:string,
    }
    const [news, setNews] = useState<INews[]>([
        {
            number: 29, title: 'Наведение порядка на рабочих местах', bg: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcoolwallpapers.me%2Fpicsup%2F3056884-blur_flower_indoors_keyboard_table_technology_white_wooden_work_workplace_workspace.jpg&f=1&nofb=1&ipt=f7d2f9bf14b9eee0e2fe329e8d8f8cfb7fa20bc44c5d487f35e1380b0f91a08b&ipo=images', content: 'Уважаемые коллеги! Для обеспечения безопасной, чистой и организованной рабочей среды просим всех навести порядок на своих рабочих местах. Мы понимаем, что поддержание чистоты и порядка на рабочем месте может занять некоторое время, поэтому мы просим вас уделять этому немного времени каждый день. Маленькие усилия со стороны каждого сотрудника могут иметь большое значение в поддержании чистоты при организованности нашей рабочей среды. ',
        },
    ]);
    return (
        <div className="user_main_page">
            <UserHeader />
            <div className="user_main_page__content">
                <div className="news">
                    {news.map((newBlock: INews) => (
                        <UserBlockNews news={newBlock} />
                    ))}
                    <UserBlockWhatsapp />
                </div>
                <div className="user_main_page__dates">
                    <UserCalendar />
                    <UserWeekend />
                </div>
            </div>
        </div>
    );
};

export default UserMainPage;
