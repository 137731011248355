const HeartSvg = () => (
    <svg fill="#000000" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" strokeWidth="0" />
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
        <g id="SVGRepo_iconCarrier">
            <title>heart</title>
            <path d="M30.943 8.494c-0.816-2.957-3.098-5.239-5.994-6.040l-0.060-0.014c-0.651-0.159-1.399-0.25-2.169-0.25-2.624 0-5 1.062-6.722 2.779l0-0c-1.558-1.505-3.682-2.433-6.023-2.433-0.77 0-1.516 0.1-2.226 0.288l0.060-0.014c-3.104 0.882-5.499 3.277-6.365 6.317l-0.016 0.065c-0.171 0.648-0.269 1.393-0.269 2.16 0 2.588 1.117 4.915 2.896 6.525l0.008 0.007 11.381 12.619c0.138 0.153 0.336 0.248 0.557 0.248s0.419-0.095 0.556-0.247l0.001-0.001 11.369-12.605c2.002-1.789 3.256-4.379 3.256-7.261 0-0.759-0.087-1.498-0.252-2.208l0.013 0.066zM26.85 16.851l-0.025 0.028-10.824 12.002-10.851-12.030c-1.526-1.359-2.483-3.329-2.483-5.523 0-0.621 0.077-1.224 0.221-1.8l-0.011 0.051c0.736-2.588 2.733-4.585 5.267-5.307l0.054-0.013c0.53-0.149 1.138-0.235 1.766-0.236h0.001c2.18 0.065 4.126 1.015 5.5 2.503l0.005 0.005c0.138 0.131 0.325 0.211 0.53 0.211s0.392-0.080 0.531-0.211l-0 0c1.507-1.742 3.722-2.838 6.192-2.838 0.63 0 1.244 0.071 1.833 0.206l-0.055-0.011c2.439 0.674 4.321 2.555 4.982 4.944l0.012 0.050c0.124 0.534 0.195 1.147 0.195 1.776 0 2.471-1.097 4.685-2.83 6.184l-0.010 0.009z" />
        </g>
    </svg>
);

export default HeartSvg;
