/* eslint-disable no-unused-expressions */
import { useState } from 'react';
import { IEditTask, ITask, ITaskErrors } from '../../assets/interfaces/interfaces';
import CloseTaskSvg from '../../assets/svgs/CloseTaskSvg';
import EditTaskSvg from '../../assets/svgs/EditTaskSvg';
import TaskInfo from './edit/TaskInfo';
import TaskComments from './edit/TaskComments';
import TaskLogs from './edit/TaskLogs';
import TaskEditForm from './edit/TaskEditForm';

interface ITaskEdit {
    task: IEditTask,
    closeEdit: Function,
    onSave: Function,
    errors: ITaskErrors,
    onEditComment: Function,
    onDeleteComment: Function,
    onAddComment: Function,
    onChangeComment: Function,
    onEditDesc: Function,
    onLock: Function,
    onChangeDeadline: Function,
    onEnableEditTitle: Function,
    onChangeFiles: Function,
    onDeleteFile: Function,
    onSetEditValue: Function,
    onAddCheckboxesChecklist: Function,
    onChangeChecklistInput: Function,
    onUpdateCheckbox: Function,
    onAddCheckList: Function,
    onChangeCheckboxName: Function,
    onEnableEdidCheckboxName: Function,
    onDeleteCheckbox: Function,
    onAddTaskToArchive: Function,
    onDeleteTask: Function,
    onEnableEdidChecklistName: Function,
    onDeleteChecklist: Function,
    onEditChecklistName: Function,
    onTaskToComplete: Function,
    onOpenEdit: Function,

}
const TaskEdit = ({
    task, errors, onDeleteCheckbox, onOpenEdit, onTaskToComplete, onEditChecklistName, onDeleteChecklist, onEnableEdidChecklistName, onDeleteTask, onAddTaskToArchive, onEnableEdidCheckboxName, onChangeCheckboxName, onAddCheckboxesChecklist, onAddCheckList, onUpdateCheckbox, onChangeChecklistInput, onSetEditValue, closeEdit, onSave, onEnableEditTitle, onEditComment, onDeleteFile, onChangeFiles, onDeleteComment, onChangeDeadline, onAddComment, onChangeComment, onLock, onEditDesc,
}: ITaskEdit) => {
    require('../../assets/styles/user/components/task_edit.scss');
    const [tabs, setTabs] = useState<Array<any>>([{ active: true, title: 'Информация' }, { active: false, title: 'Комментарии' }, { active: false, title: 'Логи' }]);

    const onTab = (tabTitle: string) => {
        const index = tabs.map((e) => e.title).indexOf(tabTitle);
        const newTabs = [...tabs];
        newTabs.map((tab) => tab.active = false);
        newTabs[index].active = true;
        setTabs(newTabs);
    };

    return (
        <div
            className="edti_modal_wrapper"
            onClick={() => closeEdit(task)}
        >
            <div className="modal-dialog" onClick={(e) => { e.stopPropagation(); }}>
                <div className="modal">
                    {task.edit ? (
                        <TaskEditForm
                            task={task}
                            onChangeFiles={onChangeFiles}
                            onChangeDeadline={onChangeDeadline}
                            onSetEditValue={onSetEditValue}
                            onAddCheckList={onAddCheckList}
                            onChangeChecklistInput={onChangeChecklistInput}
                            onAddCheckboxesChecklist={onAddCheckboxesChecklist}
                            onDeleteCheckbox={onDeleteCheckbox}
                            onEnableEdidCheckboxName={onEnableEdidCheckboxName}
                            onChangeCheckboxName={onChangeCheckboxName}
                            onDeleteChecklist={onDeleteChecklist}
                            onEnableEdidChecklistName={onEnableEdidChecklistName}
                            onEditChecklistName={onEditChecklistName}
                            onUpdateCheckbox={onUpdateCheckbox}
                            errors={errors}
                        />
                    ) : (
                        <div className="modal_content">
                            <div className="modal_header_wrapper">
                                <div className="modal_header" />
                            </div>
                            <div className="modal_tabs">
                                {tabs.map((tab) => (
                                    <div
                                        onClick={() => onTab(tab.title)}
                                        className={`tab ${tab.active && 'active'}`}
                                    >
                                        {tab.title}
                                    </div>
                                ))}
                            </div>

                            <div className="modal_content">
                                <div className="modal_info">
                                    {tabs[0].active && (
                                        <TaskInfo
                                            task={task}
                                            onLock={onLock}
                                            onDeleteFile={onDeleteFile}
                                            onUpdateCheckbox={onUpdateCheckbox}
                                            onAddTaskToArchive={onAddTaskToArchive}
                                            onDeleteTask={onDeleteTask}
                                            errors={errors}
                                        />
                                    )}
                                    {tabs[1].active && (
                                        <TaskComments
                                            task={task}
                                            onEditComment={onEditComment}
                                            onDeleteComment={onDeleteComment}
                                            onAddComment={onAddComment}
                                            onChangeComment={onChangeComment}
                                        />
                                    )}

                                    {tabs[2].active && (
                                        <TaskLogs
                                            task={task.task}
                                        />
                                    )}

                                </div>
                            </div>

                        </div>
                    )}

                    <div className="modal-footer">
                        <button type="button" className="btn btn_close" onClick={() => closeEdit(task)}>
                            <CloseTaskSvg />
                            Закрыть
                        </button>
                        {task.task.archived_at !== true && (
                            <button type="button" onClick={() => { task.edit && onSave(task); onOpenEdit(task); }} className="btn btn_edit">
                                {task.edit ? 'Сохранить' : (
                                    <>
                                        Редактировать
                                        <EditTaskSvg />
                                    </>
                                )}

                            </button>
                        )}
                    </div>

                </div>
            </div>

        </div>
    );
};

export default TaskEdit;
