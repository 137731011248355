import { ITaskData } from '../assets/interfaces/interfaces';

export interface ITaskColumn {
    status: keyof ITaskData,
    task_state_id: number,
}
export const TASK_COLUMNS:ITaskColumn[] = [
    { status: 'Новые', task_state_id: 1 },
    { status: 'В работе', task_state_id: 2 },
    { status: 'Отложенные', task_state_id: 3 },
    { status: 'Ждут проверки', task_state_id: 5 },
    { status: 'Завершенные', task_state_id: 4 },
];
