import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { authRoutes, publicRoutes } from '../routes';
import { getCookie } from '../utils/cookies';
import { HELLO_ROUTE } from '../utils/consts';
import Hello from '../pages/Hello';

const AppRouter: React.FC<any> = observer(() => {
    const location = useLocation();
    useEffect(() => {

    }, [location]);
    return (
        <Routes>
            {(getCookie('access_token') && getCookie('userId')) && authRoutes.map(({ path, component }) => <Route key={path} path={path} element={component} />)}
            {publicRoutes.map(({ path, component }) => <Route key={path} path={path} element={component} />)}
            <Route key={'/*'} path={'/*'} element={<Hello />} />
        </Routes>

    );
});

export default AppRouter;
