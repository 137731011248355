/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../components/Layout';
import ModuleTabs from './components/ModuleTabs';
import { IModuleTab } from '../../assets/interfaces/interfaces';
import DocOutgoingTab from './components/doc/tabs/DocOutgoingTab';
import DocIncomingTab from './components/doc/tabs/DocIncomingTab';
import DocArchiveTab from './components/doc/tabs/DocArchiveTab';
import { DOC_ROUTE } from '../../utils/consts';
import {
    ARCHIVE_TAB, HOME_TAB, INCOMING_TAB, OUTCOMING_TAB,
} from '../../utils/tabs';
import DocStarterTab from './components/doc/tabs/DocStarterTab';
import '../../assets/styles/modules/document_module.scss';

const DocModule = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [moduleTabs, setModuleTabs] = useState<IModuleTab[]>([
        {
            name: '', text: 'Исходящие', active: true, count: 16,
        },
        {
            name: '', text: 'Входящие', active: false, count: 16,
        },
        {
            name: '', text: 'Архив', active: false, count: 16,
        },
    ]);
    const [starterTab, setStarterTab] = useState<boolean>(false);
    const getIndexTab = (tab: string) => {
        if (tab === OUTCOMING_TAB) {
            return 0;
        } if (tab === INCOMING_TAB) {
            return 1;
        } if (tab === ARCHIVE_TAB) {
            return 2;
        }
        return 3;
    };

    const changeTab = (index: number) => {
        const newTabs = [...moduleTabs];
        newTabs.forEach((val) => val.active = false);
        if (index === 3) {
            setStarterTab(true);
            setModuleTabs(newTabs);
            return;
        }
        setStarterTab(false);
        newTabs[index].active = true;
        setModuleTabs(newTabs);
    };
    const getRouteHandler = (index?: number, tab?: string) => {
        const splitted = DOC_ROUTE.split('/');
        splitted.shift();
        if (tab) {
            const index = getIndexTab(tab);
            changeTab(index);
            splitted[1] = tab;
        } else {
            let tab = '';
            if (index === 0) {
                tab = OUTCOMING_TAB;
            } else if (index === 1) {
                tab = INCOMING_TAB;
            } else if (index === 2) {
                tab = ARCHIVE_TAB;
            } else {
                tab = HOME_TAB;
            }
            splitted[1] = tab;
        }

        splitted[2] = String(0);
        const route = `/${splitted.join('/')}`;
        return route;
    };

    const onChangeModuleTab = (index: number) => {
        changeTab(index);
        const route = getRouteHandler(index);
        navigate(route);
    };
    const onCloseTab = () => {
        changeTab(3);
        const route = getRouteHandler(3);
        navigate(route);
    };

    useEffect(() => {
        const route = getRouteHandler(undefined, params.tab);
        if (window.location.pathname !== route) {
            navigate(route);
        }
    }, [params]);
    return (
        <Layout>
            <div className="docmodule_wrapper">
                <ModuleTabs
                    tabs={moduleTabs}
                    onChangeTab={onChangeModuleTab}
                />
                <div className="docmodule_content">
                    {moduleTabs[0].active ? (
                        <DocOutgoingTab
                            onCloseTab={onCloseTab}
                        />
                    ) : moduleTabs[1].active ? (
                        <DocIncomingTab
                            onCloseTab={onCloseTab}
                        />
                    ) : moduleTabs[2].active ? (
                        <DocArchiveTab
                            onCloseTab={onCloseTab}
                        />
                    ) : starterTab ? (
                        <DocStarterTab />
                    ) : ''}

                </div>
            </div>
        </Layout>
    );
};

export default DocModule;
