import { memo, useEffect, useRef } from 'react';
import styles from './NodeSettings.module.scss';
import { classNames } from '../../../../../utils/classNames/classNames';
import {
    EyeClosedIcon,
    EyeOpenedIcon, TrashIcon, TreeClosedIcon,
    TreeOpenedIcon,
    VideoIcon,
} from '../../../../../assets/svgs/orgschema/icons';
import { Text, TextBold, TextSize } from '../../../../../components/ui/Text/Text';
import { Button, ThemeButton } from '../../../../../components/ui/Button/Button';
import { useNodeSettings } from './useNodeSettins';
import { SchemaData } from '../../types/orgschema';

interface NodeSettingsProps {
  className?: string;
  onClose: () => void;
  onDeleteBlock: () => void;
  node: SchemaData
  onOpenShowFiles: () => void;
}

export const NodeSettings = memo((props: NodeSettingsProps) => {
    const {
        className, onClose, onDeleteBlock, node, onOpenShowFiles,
    } = props;

    const cardRef = useRef<HTMLUListElement>(null);

    const { mutate } = useNodeSettings();

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const deleteBlock = () => {
        onDeleteBlock();
        onClose();
    };

    const updateIsSchemaHidden = () => {
        const data = {
            id: node.id,
            data: {
                isSchemaHidden: !node.isSchemaHidden,
                isChildrenTogether: node.isChildrenTogether,
            },
            type: node.type,
        };

        mutate(data);
        onClose();
    };

    const updateisChildrenTogether = () => {
        const data = {
            id: node.id,
            data: {
                isSchemaHidden: node.isSchemaHidden,
                isChildrenTogether: !node.isChildrenTogether,
            },
            type: node.type,
        };

        mutate(data);
        onClose();
    };

    return (
        <ul ref={cardRef} className={classNames(styles.NodeSettings, {}, [className])}>
            {
                node.children && node.children.length >= 1 && (
                    <li className={styles.item}>
                        <Button onClick={updateIsSchemaHidden} theme={ThemeButton.CLEAR} className={styles.btn}>
                            {
                                node.isSchemaHidden ? (
                                    <EyeOpenedIcon size={20} />
                                ) : (
                                    <EyeClosedIcon size={20} />
                                )
                            }
                            <Text gap="0" bold={TextBold.BOLD} textPrimary className={styles.text} text={node.isSchemaHidden ? 'Показывать подразделения' : 'Скрыть подразделения'} />
                        </Button>
                    </li>
                )
            }
            {
                node.children && node.children.length > 1 && (
                    <li className={styles.item}>
                        <Button onClick={updateisChildrenTogether} theme={ThemeButton.CLEAR} className={styles.btn}>
                            {
                                node.isChildrenTogether ? (
                                    <TreeOpenedIcon size={20} />
                                ) : (
                                    <TreeClosedIcon size={20} />
                                )
                            }
                            <Text gap="0" bold={TextBold.BOLD} textPrimary className={styles.text} text={node.isChildrenTogether ? 'Разделить на ветки' : 'В единый блок'} />
                        </Button>
                    </li>
                )
            }

            {
                (node.audioUrl || node.videoUrl || node.fileUrl) && (
                    <li className={styles.item}>
                        <Button onClick={onOpenShowFiles} theme={ThemeButton.CLEAR} className={styles.btn}>
                            <VideoIcon size={20} />
                            <Text gap="0" bold={TextBold.BOLD} textPrimary className={styles.text} text="Смотреть файлы" />
                        </Button>
                    </li>
                )
            }

            {
                node.type !== 'schemas' && (
                    <li className={styles.item}>
                        <Button onClick={deleteBlock} theme={ThemeButton.CLEAR} className={styles.deleteBtn}>
                            <TrashIcon size={20} />
                            <Text gap="0" bold={TextBold.BOLD} textPrimary className={styles.text} text="Удалить блок" />
                        </Button>
                    </li>
                )
            }
            {
                node.type === 'schemas' && node.children && node.children.length === 0 && (
                    <li>
                        <Text gap="4" bold={TextBold.BOLD} size={TextSize.XS} title="У данной схемы пока нет доступных настроек!" text="Добавьте подразделения для схемы, чтобы можно было их редактировать" />
                    </li>
                )
            }

        </ul>
    );
});
