/* eslint-disable no-nested-ternary */
import RowForModal from '../../RowForModal';
import FilesForModal from '../../FilesForModal';
import CommentTab from '../../CommentTab';
import { ITextActive } from '../../../../../assets/interfaces/interfaces';
import { IDocTableData } from '../../../../../assets/interfaces/modules/doc_interface';
import { docGetTextStatus } from '../../../../../utils/modules/doc/funcs';

interface IProps {
    detailsTabs: ITextActive[];
    openDetails: IDocTableData;
    onDeleteFile: Function;

}
const DetailsModalContent = ({ detailsTabs, openDetails, onDeleteFile }: IProps) => (
    <>
        {detailsTabs[0].active ? (
            <div className="info_container">
                <RowForModal
                    label="Название"
                    value={openDetails.name}
                />
                <RowForModal
                    label="От кого"
                    value={openDetails.from}
                />
                <RowForModal
                    label="Дата создания"
                    value={openDetails.created_at}
                />
                <RowForModal
                    label="Файлы"
                >
                    <FilesForModal
                        files={openDetails.details.files}
                        onDeleteFile={onDeleteFile}
                    />
                </RowForModal>
                <RowForModal
                    label="Статус"
                    value={docGetTextStatus(openDetails.status)}
                    underline
                />
                {(openDetails.details.status.success === true || openDetails.details.status.success === false) ? (
                    <RowForModal
                        label="Принятие"
                        value={openDetails.details.status.success === true ? 'Принят'
                            : openDetails.details.status.cancel === true ? 'Отклонен'
                                : 'Ожидается'}
                        underline
                        underlineColorClass={openDetails.details.status.cancel === true ? 'danger'
                            : openDetails.details.status.success === true ? 'success' : 'wait'}
                    />
                ) : ''}

                <RowForModal
                    label="Подписанты"
                    value={openDetails.details.sign}
                />
                <RowForModal
                    label="Порядковый номер"
                    value={openDetails.details.number}
                />
                <RowForModal
                    label="Город"
                    value={openDetails.details.city}
                />
            </div>

        ) : ''}
        {detailsTabs[1].active ? (
            <CommentTab
                comments={openDetails.details.comments}
                onAddComment={() => { }}
                onChangeComment={() => { }}
                onDeleteComment={() => { }}
                onEditComment={() => { }}
                addCommentAvalible
            />
        ) : ''}
    </>
);
export default DetailsModalContent;
