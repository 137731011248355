/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import ArchiveSvg from '../../../../../assets/svgs/ArchiveSvg';
import ExitSvg from '../../../../../assets/svgs/ExitSvg';
import ThreeDotsSvg from '../../../../../assets/svgs/ThreeDotsSvg';
import PaperAirplaneSvg from '../../../../../assets/svgs/PaperAirplaneSvg';
import FileDeleteSvg from '../../../../../assets/svgs/FileDeleteSvg';
import TrashSvg from '../../../../../assets/svgs/TrashSvg';
import ButtonBorderRound from '../../../../../components/html/buttons/ButtonBorderRound';
import { IDocTableData } from '../../../../../assets/interfaces/modules/doc_interface';
import { docGetTextStatus } from '../../../../../utils/modules/doc/funcs';
import '../../../../../assets/styles/modules/components/doc/tabel/module_table_td_doc.scss';

interface IProps {
    td: IDocTableData;
    index: number;
    isArchive: boolean;
    onShowDetail: Function;
    onHideDetail: Function;
    onOpenDetails: Function;
    onOpenTask: Function;
    onShowIcons: Function;
    onShowFileHandler: Function;
    onDeleteDoc: Function;
    onDenyAgreement: Function;
    onAddToArchive: Function;
    onChangeComment: Function;
    onOpenSelectUser: Function;
    onChangeStatus?: Function;
}
const DocIncomingTabelTr = ({
    td, isArchive, onChangeStatus, onOpenSelectUser, index, onOpenTask, onChangeComment, onShowDetail, onHideDetail, onOpenDetails, onShowIcons, onShowFileHandler, onDenyAgreement, onAddToArchive, onDeleteDoc,
}: IProps) => {
    function onTdClick(td: IDocTableData) {
        onOpenDetails(td);
    }
    function getIsIncoming(td: IDocTableData) {
        return !!((td.details.status.success === true || td.details.status.success === false));
    }
    return (
        <>
            <tr
                className="doc_tbody_tr"
                onMouseOver={() => onShowDetail(index)}
                onMouseLeave={() => onHideDetail(index)}
            // onClick={() => onOpenDetails(td)}
            >
                <td
                    onClick={() => onTdClick(td)}
                    className="td"
                >
                    {td.name}
                </td>
                <td
                    onClick={() => onTdClick(td)}
                    className="td"
                >
                    {td.to}
                </td>
                <td
                    onClick={() => onTdClick(td)}
                    className="td"
                >
                    {td.created_at}
                </td>
                <td
                    onClick={() => onTdClick(td)}
                    className="td"
                >
                    {td.send_at}
                </td>
                <td
                    onClick={() => onTdClick(td)}
                    className="td"
                >
                    <span
                        className={`status ${docGetTextStatus(td.status) === 'Согласован' ? 'active'
                            : docGetTextStatus(td.status) === 'Отмена' ? 'cancel'
                                : docGetTextStatus(td.status) === 'В процессе' ? 'process'
                                    : docGetTextStatus(td.status) === 'На согласовании' ? 'send'
                                        : docGetTextStatus(td.status) === 'Сохранен' ? 'save'
                                            : ''}`}
                    >
                        {docGetTextStatus(td.status)}
                    </span>
                </td>
                <td
                    onClick={() => {
                        td.performer.status === 0 ? onTdClick(td)
                            : td.performer.status === 1 ? onOpenTask(td)
                                : td.performer.status === 2 ? onOpenSelectUser(td)
                                    : onTdClick(td);
                    }}
                    className="td performer"
                >
                    <div className="performer">
                        {td.performer.status === 0 ? (
                            <span className="text">Документ еще не согласован</span>
                        ) : td.performer.status === 1 ? (
                            <div className="performet_btn_container">
                                <ButtonBorderRound
                                    text="Ссылка на задачу"
                                    classes="button_info"
                                />
                            </div>

                        ) : td.performer.status === 2 ? (
                            <div className="performet_btn_container">
                                <ButtonBorderRound
                                    text="Добавить задачу"
                                    classes="button_success"
                                />
                            </div>
                        ) : ''}
                    </div>
                </td>

                <td className="td">
                    <div
                        className={`icons ${td.showDo === true ? 'show' : ''}`}
                    >
                        <div
                            className="icon"
                            onMouseEnter={() => onShowIcons(index, true)}
                            onMouseLeave={() => onShowIcons(index, false)}
                            onTouchStart={() => onShowIcons(index, true)}
                            onTouchEnd={() => onShowIcons(index, false)}
                        >
                            <ThreeDotsSvg />
                        </div>
                        <div
                            onMouseLeave={() => onShowIcons(index, false)}
                            onMouseEnter={() => onShowIcons(index, true)}
                            onTouchStart={() => onShowIcons(index, true)}
                            onTouchEnd={() => onShowIcons(index, false)}
                            className="hover_icons"
                        >
                            {!isArchive ? (
                                <>
                                    <div
                                        // onClick={() => onDeleteDoc(td)}
                                        className="hover_icon disable"
                                    >
                                        <PaperAirplaneSvg />
                                    </div>
                                    <div
                                        className="hover_icon"
                                        onClick={() => onDenyAgreement(td)}
                                    >
                                        <FileDeleteSvg />
                                    </div>
                                    <div
                                        className="hover_icon"
                                        onClick={() => onDeleteDoc(td)}
                                    >
                                        <TrashSvg />
                                    </div>
                                </>
                            ) : ''}
                            <div
                                className="hover_icon"
                                onClick={() => onShowFileHandler(td.details.files)}
                            >
                                <span className="icon_exit">
                                    <ExitSvg />
                                </span>
                            </div>

                            <div
                                className="hover_icon"
                                onClick={() => onAddToArchive(td)}
                            >
                                <ArchiveSvg />
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr
                onMouseOver={() => onShowDetail(index)}
                onMouseLeave={() => onHideDetail(index)}
                className={`doc_tbody_tr subtr ${td.details.show ? 'show' : ''}`}
            >
                <td className="td">
                    <div className="subtd">
                        <div className="title">Подписанты</div>
                        <div className="value">
                            {td.details.sign}
                        </div>
                    </div>
                </td>
                <td className="td">
                    <div className="subtd">
                        <div className="title">
                            Комментарий
                        </div>
                        <div className="value">
                            {getIsIncoming(td) ? (
                                <input
                                    type="text"
                                    className="input_comment"
                                    onChange={(e) => onChangeComment(index, e.target.value)}
                                    value={td.details.comment}
                                />
                            ) : td.details.comment}

                        </div>
                    </div>
                </td>
                <td className="td">
                    <div className="subtd">
                        <div className="title">Дата</div>
                        <div className="value">
                            {td.details.created_at}
                        </div>
                    </div>
                </td>
                <td className="td" />
                <td className="td">
                    <div className="subtd">
                        <div className="title">Статус</div>
                        <div className="value">
                            {(getIsIncoming(td) && onChangeStatus) ? (
                                <div className="accept_buttons_status">
                                    <span
                                        className={`button success ${td.details.status.success ? 'active' : ''}`}
                                        onClick={() => onChangeStatus(index, { success: true, cancel: false })}
                                    >
                                        Принять
                                    </span>
                                    <span
                                        onClick={() => onChangeStatus(index, { success: false, cancel: true })}
                                        className={`button danger ${td.details.status.cancel ? 'active' : ''}`}
                                    >
                                        Отклонить
                                    </span>
                                </div>
                            ) : (
                                <span
                                    className={`status ${docGetTextStatus(td.details.status.status) === 'Согласован' ? 'active'
                                        : docGetTextStatus(td.details.status.status) === 'Отмена' ? 'cancel'
                                            : docGetTextStatus(td.details.status.status) === 'В процессе' ? 'process'
                                                : docGetTextStatus(td.details.status.status) === 'На согласовании' ? 'send'
                                                    : docGetTextStatus(td.details.status.status) === 'Сохранен' ? 'save'
                                                        : ''}`}
                                >
                                    {docGetTextStatus(td.details.status.status)}
                                </span>
                            )}

                        </div>
                    </div>

                </td>
                <td className="td" />
                <td className="td" />
                <td className="td" />

            </tr>
        </>
    );
};
export default DocIncomingTabelTr;
