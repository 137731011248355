const ArchiveSvg = () => (
    <svg fill="#000000" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" strokeWidth="0" />
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
        <g id="SVGRepo_iconCarrier">
            <path d="M28 1.25h-24c-1.518 0.002-2.748 1.232-2.75 2.75v4.001c0 0.414 0.336 0.75 0.75 0.75h1.25v19.247c0.002 1.518 1.232 2.748 2.75 2.75h20c1.518-0.002 2.748-1.232 2.75-2.75v-19.247h1.25c0.414-0 0.75-0.336 0.75-0.75v0-4.001c-0.002-1.518-1.232-2.748-2.75-2.75h-0zM27.25 27.998c-0.001 0.69-0.56 1.249-1.25 1.25h-20c-0.69-0.001-1.249-0.56-1.25-1.25v-19.247h22.5zM29.25 7.251h-26.5v-3.251c0.001-0.69 0.56-1.249 1.25-1.25h24c0.69 0.001 1.249 0.56 1.25 1.25v0zM10 14.75h12c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0h-12c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0z" />
        </g>
    </svg>
);

export default ArchiveSvg;
