import Calendar from './Components/Calendar';
import UserCardLayout from './UserCardLayout';

const UserCalendar = () => {
    require('../../assets/styles/user/calendar.scss');

    return (
    // <UserCardLayout width={316}>
    //     <div className="cardlayout__title">Производственный календарь</div>
    //     <div className="calendar_wrapper">
    //         <Calendar/>
    //     </div>
    // </UserCardLayout>

        // Ошибка была, убрал в коммит компонент
        // <Calendar />
        null

    );
};

export default UserCalendar;
