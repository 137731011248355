// import Swiper JS
import { useEffect, useState } from 'react';
import 'swiper/css';
import { Link } from 'react-router-dom';
import CommentSvg from '../../assets/svgs/CommentSvg';
import HeartSvg from '../../assets/svgs/HeartSvg';

const StarterSlider = ({ children }: any) => {
    require('../../assets/styles/starter/components/starter_slider.scss');
    const [slides, setSlides] = useState([
        {
            title: 'Наведение порядка на рабочих местах', img: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fretrokings.nl%2Fwp-content%2Fuploads%2F2014%2F08%2Fsmb3.bmp&f=1&nofb=1&ipt=f04977d5e056d9ba3bfacd5408371db3762c80381be5ba0515ef3daeefba7a07&ipo=images', desc: 'Уважаемые коллеги! Для обеспечения безопасной, чистой и организованной рабочей среды просим всех навести порядок на своих рабочих местах. Мы понимаем, что поддержание чистоты и порядка на рабочем месте может занять некоторое время, поэтому мы просим вас уделять этому немного времени каждый день. Маленькие усилия со стороны каждого сотрудника могут иметь большое значение в поддержании чистоты при организованности нашей рабочей среды.', comm: 0, likes: 0,
        },
        {
            title: 'Наведение порядка на рабочих местах', img: 'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fwww.female-gamers.nl%2Fwp-content%2Fuploads%2F2015%2F11%2FIMG_BindingofIsaac_1.jpeg&f=1&nofb=1&ipt=0507a95c4adfed5b859014e88fa198cb87d28ea95093854f9c44ad4585735171&ipo=images', desc: 'Уважаемые коллеги! Для обеспечения безопасной, чистой и организованной рабочей среды просим всех навести порядок на своих рабочих местах. Мы понимаем, что поддержание чистоты и порядка на рабочем месте может занять некоторое время, поэтому мы просим вас уделять этому немного времени каждый день. Маленькие усилия со стороны каждого сотрудника могут иметь большое значение в поддержании чистоты при организованности нашей рабочей среды.', comm: 0, likes: 0,
        },
        {
            title: 'Наведение порядка на рабочих местах', img: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2F1.bp.blogspot.com%2F-EzkL85TO5bY%2FV-KxwPQnmZI%2FAAAAAAAAAc8%2FiAxQf2Wip5cQRShzVre8vFn1B_m83k69gCLcB%2Fs1600%2Fmaxresdefault.jpg&f=1&nofb=1&ipt=ec54a487a9676df9763f7fc80c0759fdc0eb14ded1ae413e809652a2f976faee&ipo=images', desc: 'Уважаемые коллеги! Для обеспечения безопасной, чистой и организованной рабочей среды просим всех навести порядок на своих рабочих местах. Мы понимаем, что поддержание чистоты и порядка на рабочем месте может занять некоторое время, поэтому мы просим вас уделять этому немного времени каждый день. Маленькие усилия со стороны каждого сотрудника могут иметь большое значение в поддержании чистоты при организованности нашей рабочей среды.', comm: 0, likes: 0,
        },
        {
            title: 'Наведение порядка на рабочих местах', img: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.ytimg.com%2Fvi%2FBejcMXOew54%2Fmaxresdefault.jpg&f=1&nofb=1&ipt=21f364e168518cd717abc080a078015256936458d080912c950964003934d932&ipo=images', desc: 'Уважаемые коллеги! Для обеспечения безопасной, чистой и организованной рабочей среды просим всех навести порядок на своих рабочих местах. Мы понимаем, что поддержание чистоты и порядка на рабочем месте может занять некоторое время, поэтому мы просим вас уделять этому немного времени каждый день. Маленькие усилия со стороны каждого сотрудника могут иметь большое значение в поддержании чистоты при организованности нашей рабочей среды.', comm: 0, likes: 0,
        },
        {
            title: 'Наведение порядка на рабочих местах', img: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimages.gamersyde.com%2Fimage_stream-34512-3436_0002.jpg&f=1&nofb=1&ipt=4f35cb86c706ea434cea8b32475b5b75c0bab70b1faab2d328af2cfea89f47c2&ipo=images', desc: 'Уважаемые коллеги! Для обеспечения безопасной, чистой и организованной рабочей среды просим всех навести порядок на своих рабочих местах. Мы понимаем, что поддержание чистоты и порядка на рабочем месте может занять некоторое время, поэтому мы просим вас уделять этому немного времени каждый день. Маленькие усилия со стороны каждого сотрудника могут иметь большое значение в поддержании чистоты при организованности нашей рабочей среды.', comm: 0, likes: 0,
        },
        {
            title: 'Наведение порядка на рабочих местах', img: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.ytimg.com%2Fvi%2FddcEuzayd6c%2Fmaxresdefault.jpg&f=1&nofb=1&ipt=d2bb3c5976f8ceb843945a8cb7d52de05f44fed22dbd6cb4772758a44ee4947e&ipo=images', desc: 'Уважаемые коллеги! Для обеспечения безопасной, чистой и организованной рабочей среды просим всех навести порядок на своих рабочих местах. Мы понимаем, что поддержание чистоты и порядка на рабочем месте может занять некоторое время, поэтому мы просим вас уделять этому немного времени каждый день. Маленькие усилия со стороны каждого сотрудника могут иметь большое значение в поддержании чистоты при организованности нашей рабочей среды.', comm: 0, likes: 0,
        },
        {
            title: 'Наведение порядка на рабочих местах', img: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.ytimg.com%2Fvi%2FmJEEeNjEUiQ%2Fmaxresdefault.jpg&f=1&nofb=1&ipt=d20cce585bc5b1eebaf478940b0ecd2b5dcb89a01b787b359f16d58857a7ded6&ipo=images', desc: 'Уважаемые коллеги! Для обеспечения безопасной, чистой и организованной рабочей среды просим всех навести порядок на своих рабочих местах. Мы понимаем, что поддержание чистоты и порядка на рабочем месте может занять некоторое время, поэтому мы просим вас уделять этому немного времени каждый день. Маленькие усилия со стороны каждого сотрудника могут иметь большое значение в поддержании чистоты при организованности нашей рабочей среды.', comm: 0, likes: 0,
        },
    ]);
    useEffect(() => {

    }, []);
    return (
        <main className="starter_slider">
            <div className="block_title">Новости</div>
            <swiper-container
                style={
                    {
                        '--swiper-navigation-color': '#fff',
                        '--swiper-pagination-color': '#fff',
                    }
                }
                pagination-clickable="true"
                navigation="true"
                class="mySwiper"
            >
                {slides.map((slide) => (
                    <swiper-slide lazy="true">
                        <div className="overlay">
                            <div className="content">
                                <div className="top">
                                    <div className="title">{slide.title}</div>
                                    <div className="reaction_btn">
                                        <div className="comm_btn">
                                            <CommentSvg />
                                            <span className="count">{slide.comm}</span>
                                        </div>
                                        <div className="like_btn">
                                            <HeartSvg />
                                            <span className="count">{slide.likes}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="desc">
                                    <span className="text">{slide.desc}</span>
                                    <Link className="more" to="/">Подробнее</Link>
                                </div>
                            </div>

                        </div>
                        <img src={slide.img} loading="lazy" alt="" />
                    </swiper-slide>
                ))}
            </swiper-container>
        </main>

    );
};

export default StarterSlider;
