import '../../../assets/styles/html/buttons/button_border_round.scss';

interface IProps {
    text: string;
    classes?: string;
}
const ButtonBorderRound = ({ text, classes }: IProps) => (
    <button
        type="button"
        className={`button_border_round ${classes}`}
    >
        {text}
    </button>
);
export default ButtonBorderRound;
