import { memo, useCallback, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import styles from './AddBlockForm.module.scss';
import { Input } from '../../../../../../components/ui/Input/Input';
import { Text, TextBold, TextSize } from '../../../../../../components/ui/Text/Text';
import { Button, ThemeButton } from '../../../../../../components/ui/Button/Button';
import { HStack, VStack } from '../../../../../../components/ui/Stack';
import { classNames } from '../../../../../../utils/classNames/classNames';
import { BlockSelect, TestEnum } from '../../BlockSelect/BlockSelect';
import { OrgSChemaService } from '../../../services/OrgSchema.service';
import { CreateSchemaData, SchemaData } from '../../../types/orgschema';
import { useSchemasStore } from '../../../store/schemas';

export interface AddBlockFormProps {
  className?: string;
  onSuccess: () => void;
  node: SchemaData;
 }

const AddBlockForm = memo((props: AddBlockFormProps) => {
    const { onSuccess, className, node } = props;

    const queryClient = useQueryClient();

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tskp, setTskp] = useState('');

    const activeSchemaId = useSchemasStore((state) => state.activeSchemaId);

    const onChangeTitle = (value: string) => {
        setTitle(value);
    };

    const onChangeDescription = (value: string) => {
        setDescription(value);
    };

    const onChangeTskp = (value: string) => {
        setTskp(value);
    };

    const { mutate } = useMutation({
        mutationKey: ['add new block orgSchema'],
        mutationFn: ({ id, data }: {id: string, data: CreateSchemaData}) => OrgSChemaService.createNewBlockToSchema(id, data, node.type),
        onSuccess: (response) => {
            toast.success('Блок успешно создан');

            queryClient.invalidateQueries({
                queryKey: ['orgSchema', activeSchemaId],
            });
        },
        onError(error: any) {
            if (
                error.response
          && error.response.status
          && error.response.data.message
            ) {
                toast.error(error.response.data.message);
            } else {
                toast.error('Ошибка при создании блока');
            }
        },
    });

    const handleCreateNewBlock = () => {
        const data = {
            id: node.id,
            data: {
                title,
                description,
                tskp,
            },
        };
        mutate(data);
        onSuccess();
    };

    return (
        <VStack gap="16" align="start" max className={classNames(styles.AddBlockForm, {}, [className])}>
            <Input onChange={onChangeTitle} value={title} className={styles.input} placeholder="Введите название блока" label="Название" />
            <Input onChange={onChangeDescription} value={description} className={styles.input} placeholder="Введите должность" label="Должность" />
            <Input onChange={onChangeTskp} value={tskp} className={styles.input} placeholder="Введите ЦКП" label="ЦКП" />

            <HStack justify="end" max gap="16" align="center">
                <Button onClick={onSuccess} theme={ThemeButton.OUTLINE}>Закрыть</Button>
                <Button onClick={handleCreateNewBlock} theme={ThemeButton.DEFAULT}>Сохранить</Button>
            </HStack>

        </VStack>
    );
});

export default AddBlockForm;
