import { create } from 'zustand';
import { SchemaData } from '../types/orgschema';

export interface SchemasStore {
  totalCount: number;
  activeSchemaId: string;
  zoomCount: number;
  newSchemaTitle: string;
  isEmptySchema: boolean;
  newSchemaDescription: string;
  schemasList: Pick<SchemaData, 'title' | 'id'>[]
  setZoomCount: (value: number) => void;
  setSchemas: (schemas: SchemaData[]) => void; // Метод для обновления состояния
  setActiveSchema: (id: string) => void;
  setNewSchemaTitle: (title: string) => void;
  setIsEmptySchema: (isEmpty: boolean) => void;
  setNewSchemaDescription: (description: string) => void; // Методы для установки новых значений полей формы создания схемы
}

export const useSchemasStore = create<SchemasStore>((set) => ({
    activeSchemaId: '1',
    isEmptySchema: true,
    zoomCount: 1,
    totalCount: 0,
    newSchemaTitle: '',
    newSchemaDescription: '',
    schemasList: [],

    setSchemas: (schemas) => {
        set({
            totalCount: schemas.length,
            schemasList: schemas.map(({ title, id }) => ({ title, id })),
        });
    },

    setActiveSchema: (id) => {
        set({ activeSchemaId: id });
    },
    setNewSchemaDescription: (description) => {
        set({ newSchemaDescription: description });
    },
    setNewSchemaTitle: (title) => {
        set({ newSchemaTitle: title });
    },
    setIsEmptySchema: (isEmpty) => {
        set({ isEmptySchema: isEmpty });
    },

    setZoomCount: (newZoom) => { // Исправлено здесь
        set((state) => ({ ...state, zoomCount: newZoom }));
    },

}));
