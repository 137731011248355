import axios from 'axios';
import { IUser, UserStatus } from '../types/orgschema';

export const UserService = {

    async getAllUsers(searchTerm?: string) {
        const { data } = await axios.get<IUser[]>(
            'http://localhost:4200/api/users',
            {
                params: {
                    _searchTerm: searchTerm,
                },
            },
        );

        return data;
    },

    async changeUserStatus(id: string, status: UserStatus) {
        const { data } = await axios.patch<IUser>(
            `http://localhost:4200/api/users/${id}`,
            {
                status,
            },
        );

        return data;
    },

};
