import { Modal } from '../../../../../components/ui/Modal/Modal';
import { VStack } from '../../../../../components/ui/Stack';
import { Text, TextBold, TextSize } from '../../../../../components/ui/Text/Text';
import { classNames } from '../../../../../utils/classNames/classNames';
import { SchemaData } from '../../types/orgschema';
import AddBlockForm from './AddBlockForm/AddBlockForm';
import styles from './AddBlockModal.module.scss';

interface AddBlockModalProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  node: SchemaData
}

export const AddBlockModal = ({
    className, isOpen, onClose, node,
} : AddBlockModalProps) => (
    <Modal lazy className={classNames(styles.AddBlockModal, {}, [className])} isOpen={isOpen} onClose={onClose}>
        <VStack max align="start" gap="16">
            <Text gap="16" size={TextSize.XL} bold={TextBold.BOLD} textPrimary text="Добавить новый блок" />
            <AddBlockForm node={node} onSuccess={onClose} />
        </VStack>
    </Modal>
);
