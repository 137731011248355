const StarterCard = ({ children }: any) => {
    require('../../assets/styles/starter/components/starter_card.scss');

    return (
        <div className="starter_card">
            {children[0]}
            {children[1]}
        </div>

    );
};

export default StarterCard;
