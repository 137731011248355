import { useState } from 'react';
import { addDays } from 'date-fns';
import InputSelect from '../input/InputSelect';
import InputDatepicker from '../input/InputDatepicker';
import InputSearch from '../input/InputSearch';
import InputClose from '../input/InputClose';
import TogglerButton from '../../../../../components/Header/TogglerButton';
import '../../../../../assets/styles/modules/components/doc/doc_header.scss';
import { IDocInputSections } from '../../../../../assets/interfaces/modules/doc_interface';
import { IDateRange } from '../../../../../assets/interfaces/interfaces';

interface IProps {
    title: string;
    inputSearch: string;
    onShowAddModal: Function;
    onInputSearchChange: Function;
    onCloseTab: Function;
}
const DocHeader = ({
    title, inputSearch, onShowAddModal, onInputSearchChange, onCloseTab,
}: IProps) => {
    const [inputSections, setInputSections] = useState<IDocInputSections[]>([
        { text: 'Все документы', active: true, count: 0 },
        { text: 'Сохраненные', active: false, count: 0 },
        { text: 'Отправленные на согласование', active: false, count: 0 },
        { text: 'В процессе согласования', active: false, count: 0 },
        { text: 'Согласованные', active: false, count: 0 },
        { text: 'Отменные', active: false, count: 0 },
    ]);
    const [inputDocTypes, setInputDocTypes] = useState<IDocInputSections[]>([
        { text: 'Все документы', active: true },
        { text: 'Передача функционала', active: false },
        { text: 'ЗРС', active: false },
    ]);
    const [selectedRange, setSelectedRange] = useState<IDateRange[]>([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection',
        },
    ]);
    const [otherSelectClicked, setOtherSelectClicked] = useState<boolean>(false);
    const [toggleActive, setToggleActive] = useState<boolean>(false);

    const getNewSections = (index: number, sections: IDocInputSections[]) => {
        const newSections = [...sections];
        newSections.map((val) => val.active = false);
        newSections[index].active = true;
        return newSections;
    };
    const changeInputSection = (index: number) => {
        setInputSections(getNewSections(index, inputSections));
    };

    const onOpenAddDocument = (open: boolean) => {
        onShowAddModal(open);
    };
    const onChangeSelectedRange = (value: IDateRange[]) => {
        setSelectedRange(value);
    };
    const changeInputDocTypes = (index: number) => {
        setInputDocTypes(getNewSections(index, inputDocTypes));
    };
    const onChangeInputSearch = (text: string) => {
        onInputSearchChange(text);
    };
    const onSelectClicked = () => {
        setOtherSelectClicked(!otherSelectClicked);
    };
    const onShowInputs = () => {
        setToggleActive(!toggleActive);
    };
    return (
        <div className="docmodule_header">
            <div className="docmodule_header__left">
                <span className="title">{title}</span>
                <button
                    type="button"
                    className="button_create"
                    onClick={() => onOpenAddDocument(true)}
                >
                    Создать документ
                </button>

            </div>
            <div className="docmodule_header__right">
                <div className="toggler_btn">
                    <span onClick={() => onShowInputs()}>
                        <TogglerButton />
                    </span>
                </div>
                <div className={`inputs ${toggleActive ? 'show' : ''}`}>
                    <div className="input">
                        <InputSelect
                            title="Тип документа"
                            inputSections={inputDocTypes}
                            onChangeInputSection={changeInputDocTypes}
                            onClickSelect={() => onSelectClicked()}
                            otherSelectClicked={otherSelectClicked}
                        />
                    </div>
                    <div className="input">
                        <InputSelect
                            title="Раздел документооборота"
                            inputSections={inputSections}
                            onChangeInputSection={changeInputSection}
                            onClickSelect={() => onSelectClicked()}
                            otherSelectClicked={otherSelectClicked}
                        />
                    </div>
                    <div className="input">
                        <InputDatepicker
                            selectedRange={selectedRange}
                            onChangeSelectedRange={onChangeSelectedRange}
                        />
                    </div>
                </div>
                <div className="input">
                    <InputSearch
                        onSearch={onChangeInputSearch}
                    />
                </div>

            </div>
            <div className="close_header">
                <InputClose
                    onClick={() => onCloseTab()}
                />
            </div>
        </div>
    );
};

export default DocHeader;
