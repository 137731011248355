import * as React from 'react';

export type IconSvgProps = React.SVGProps<SVGSVGElement> & {
  size?: number;
  width?: number;
  height?: number;
};

export const AddIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            opacity="0.4"
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228
             2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            fill="currentColor"
        />
        <path d="M16 11.25H12.75V8C12.75 7.59 12.41 7.25 12 7.25C11.59 7.25 11.25 7.59 11.25 8V11.25H8C7.59 11.25 7.25 11.59 7.25 12C7.25 12.41 7.59 12.75 8 12.75H11.25V16C11.25 16.41 11.59 16.75 12 16.75C12.41 16.75 12.75 16.41 12.75 16V12.75H16C16.41 12.75 16.75 12.41 16.75 12C16.75 11.59 16.41 11.25 16 11.25Z" fill="currentColor" />
    </svg>
);

export const BackVideo10Icon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M9.53955 16.6701C9.12955 16.6701 8.78955 16.3301 8.78955 15.9201V12.5301L8.59955 12.7401C8.31955 13.0501 7.84955 13.0701 7.53955 12.8001C7.22955 12.5201 7.20955 12.0501 7.47955 11.7401L8.97955 10.0701C9.18955 9.84008 9.51954 9.76007 9.80954 9.87007C10.0995 9.98007 10.2895 10.2601 10.2895 10.5701V15.9201C10.2895 16.3401 9.95955 16.6701 9.53955 16.6701Z" fill="currentColor" />
        <path opacity="0.4" d="M11.9996 3.47974C11.9196 3.47974 11.8396 3.48975 11.7596 3.48975L12.5796 2.46973C12.8396 2.14973 12.7896 1.66974 12.4596 1.41974C12.1396 1.15974 11.6696 1.20973 11.4096 1.53973L9.4296 4.00977C9.4196 4.01977 9.41961 4.03974 9.40961 4.04974C9.37961 4.08974 9.3596 4.13976 9.3396 4.18976C9.3196 4.22976 9.29961 4.26976 9.28961 4.31976C9.27961 4.36976 9.2796 4.40978 9.2796 4.45978C9.2796 4.50978 9.2796 4.55974 9.2796 4.60974C9.2796 4.62974 9.2796 4.63973 9.2796 4.65973C9.2896 4.68973 9.30961 4.71976 9.31961 4.74976C9.33961 4.79976 9.34961 4.83976 9.37961 4.87976C9.40961 4.91976 9.43961 4.95976 9.47961 4.99976C9.49961 5.01976 9.50961 5.04976 9.53961 5.06976C9.54961 5.07976 9.56959 5.07978 9.57959 5.08978C9.60959 5.10978 9.6396 5.12977 9.6796 5.13977C9.7296 5.16977 9.77959 5.18977 9.82959 5.19977C9.85959 5.20977 9.8996 5.20973 9.9296 5.21973C9.9596 5.21973 9.97961 5.22974 10.0096 5.22974C10.0296 5.22974 10.0596 5.21978 10.0796 5.20978C10.1096 5.20978 10.1396 5.20978 10.1796 5.20978C10.8196 5.05978 11.4196 4.98975 11.9896 4.98975C16.4796 4.98975 20.1296 8.63976 20.1296 13.1298C20.1296 17.6198 16.4796 21.2698 11.9896 21.2698C7.49959 21.2698 3.84961 17.6198 3.84961 13.1298C3.84961 11.3898 4.4196 9.70978 5.4996 8.26978C5.7496 7.93978 5.67961 7.46973 5.34961 7.21973C5.01961 6.96973 4.54959 7.03975 4.29959 7.36975C3.01959 9.06975 2.34961 11.0598 2.34961 13.1298C2.34961 18.4398 6.66959 22.7698 11.9896 22.7698C17.3096 22.7698 21.6296 18.4498 21.6296 13.1298C21.6296 7.80976 17.3096 3.47974 11.9996 3.47974Z" fill="currentColor" />
        <path d="M14 16.6698C12.48 16.6698 11.25 15.4398 11.25 13.9198V12.5698C11.25 11.0498 12.48 9.81982 14 9.81982C15.52 9.81982 16.75 11.0498 16.75 12.5698V13.9198C16.75 15.4398 15.52 16.6698 14 16.6698ZM14 11.3298C13.31 11.3298 12.75 11.8898 12.75 12.5798V13.9298C12.75 14.6198 13.31 15.1798 14 15.1798C14.69 15.1798 15.25 14.6198 15.25 13.9298V12.5798C15.25 11.8898 14.69 11.3298 14 11.3298Z" fill="currentColor" />
    </svg>
);

export const BackVideo5Icon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M12.3806 16.9198H10.0906C9.68061 16.9198 9.34061 16.5798 9.34061 16.1698C9.34061 15.7598 9.68061 15.4198 10.0906 15.4198H12.3806C12.8106 15.4198 13.1606 15.0698 13.1606 14.6398C13.1606 14.2098 12.8106 13.8598 12.3806 13.8598H10.0906C9.85061 13.8598 9.62062 13.7398 9.48062 13.5498C9.34062 13.3598 9.30062 13.0998 9.38062 12.8698L10.1406 10.5798C10.2406 10.2698 10.5306 10.0698 10.8506 10.0698H13.9106C14.3206 10.0698 14.6606 10.4098 14.6606 10.8198C14.6606 11.2298 14.3206 11.5698 13.9106 11.5698H11.3906L11.1306 12.3598H12.3806C13.6406 12.3598 14.6606 13.3798 14.6606 14.6398C14.6606 15.8998 13.6406 16.9198 12.3806 16.9198Z" fill="currentColor" />
        <path opacity="0.4" d="M11.9998 3.47994C11.9198 3.47994 11.8399 3.48995 11.7599 3.48995L12.5798 2.46993C12.8398 2.14993 12.7898 1.66994 12.4598 1.41994C12.1298 1.16994 11.6699 1.20994 11.4099 1.53994L9.42984 4.00997C9.41984 4.01997 9.41985 4.03995 9.40985 4.04995C9.37985 4.08995 9.35984 4.13996 9.33984 4.18996C9.31984 4.22996 9.29986 4.26997 9.28986 4.31997C9.27986 4.36997 9.27985 4.40998 9.27985 4.45998C9.27985 4.50998 9.27985 4.55994 9.27985 4.60994C9.27985 4.62994 9.27985 4.63993 9.27985 4.65993C9.28985 4.68993 9.30985 4.71996 9.31985 4.74996C9.33985 4.79996 9.35985 4.83997 9.37985 4.88997C9.40985 4.92997 9.43985 4.96996 9.46985 4.99996C9.48985 5.02996 9.50986 5.05997 9.53986 5.07997C9.54986 5.08997 9.56984 5.09994 9.58984 5.10994C9.61984 5.12994 9.64986 5.13993 9.66986 5.15993C9.71986 5.18993 9.77984 5.20993 9.83984 5.21993C9.86984 5.22993 9.89984 5.22995 9.92984 5.23995C9.95984 5.23995 9.97986 5.24996 10.0099 5.24996C10.0399 5.24996 10.0598 5.23994 10.0798 5.22994C10.1098 5.22994 10.1399 5.23994 10.1699 5.22994C10.8099 5.07994 11.4099 5.00997 11.9799 5.00997C16.4699 5.00997 20.1198 8.65998 20.1198 13.15C20.1198 17.64 16.4699 21.2899 11.9799 21.2899C7.48986 21.2899 3.83984 17.64 3.83984 13.15C3.83984 11.41 4.40984 9.72994 5.48984 8.28994C5.73984 7.95994 5.66984 7.48995 5.33984 7.23995C5.00984 6.98995 4.53986 7.05997 4.28986 7.38997C3.00986 9.08997 2.33984 11.08 2.33984 13.15C2.33984 18.46 6.65986 22.7899 11.9799 22.7899C17.2999 22.7899 21.6198 18.47 21.6198 13.15C21.6198 7.82998 17.3098 3.47994 11.9998 3.47994Z" fill="currentColor" />
    </svg>
);

export const LowSoundIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M19.3304 16.7498C19.1704 16.7498 19.0204 16.6998 18.8804 16.5998C18.5504 16.3498 18.4804 15.8798 18.7304 15.5498C20.3004 13.4598 20.3004 10.5398 18.7304 8.44978C18.4804 8.11978 18.5504 7.64978 18.8804 7.39978C19.2104 7.14978 19.6804 7.21978 19.9304 7.54978C21.9004 10.1698 21.9004 13.8298 19.9304 16.4498C19.7904 16.6498 19.5604 16.7498 19.3304 16.7498Z" fill="currentColor" />
        <path opacity="0.4" d="M17.0801 7.41021V16.5902C17.0801 18.3102 16.4601 19.6002 15.3501 20.2202C14.9001 20.4702 14.4001 20.5902 13.8801 20.5902C13.0801 20.5902 12.2201 20.3202 11.3401 19.7702L8.42008 17.9402C8.22008 17.8202 7.99008 17.7502 7.76008 17.7502H6.83008V6.25021H7.76008C7.99008 6.25021 8.22008 6.18021 8.42008 6.06021L11.3401 4.23021C12.8001 3.32021 14.2301 3.16021 15.3501 3.78021C16.4601 4.40021 17.0801 5.69021 17.0801 7.41021Z" fill="currentColor" />
        <path d="M6.83008 6.25V17.75H6.33008C3.91008 17.75 2.58008 16.42 2.58008 14V10C2.58008 7.58 3.91008 6.25 6.33008 6.25H6.83008Z" fill="currentColor" />
    </svg>
);

export const MaxSoundIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M18.0003 16.7498C17.8403 16.7498 17.6903 16.6998 17.5503 16.5998C17.2203 16.3498 17.1503 15.8798 17.4003 15.5498C18.9703 13.4598 18.9703 10.5398 17.4003 8.44978C17.1503 8.11978 17.2203 7.64978 17.5503 7.39978C17.8803 7.14978 18.3503 7.21978 18.6003 7.54978C20.5603 10.1698 20.5603 13.8298 18.6003 16.4498C18.4503 16.6498 18.2303 16.7498 18.0003 16.7498Z" fill="currentColor" />
        <path d="M19.8304 19.2498C19.6704 19.2498 19.5204 19.1998 19.3804 19.0998C19.0504 18.8498 18.9804 18.3798 19.2304 18.0498C21.9004 14.4898 21.9004 9.50978 19.2304 5.94978C18.9804 5.61978 19.0504 5.14978 19.3804 4.89978C19.7104 4.64978 20.1804 4.71978 20.4304 5.04978C23.5004 9.13978 23.5004 14.8598 20.4304 18.9498C20.2904 19.1498 20.0604 19.2498 19.8304 19.2498Z" fill="currentColor" />
        <path opacity="0.4" d="M15.75 7.41021V16.5902C15.75 18.3102 15.13 19.6002 14.02 20.2202C13.57 20.4702 13.07 20.5902 12.55 20.5902C11.75 20.5902 10.89 20.3202 10.01 19.7702L7.09 17.9402C6.89 17.8202 6.66 17.7502 6.43 17.7502H5.5V6.25021H6.43C6.66 6.25021 6.89 6.18021 7.09 6.06021L10.01 4.23021C11.47 3.32021 12.9 3.16021 14.02 3.78021C15.13 4.40021 15.75 5.69021 15.75 7.41021Z" fill="currentColor" />
        <path d="M5.5 6.25V17.75H5C2.58 17.75 1.25 16.42 1.25 14V10C1.25 7.58 2.58 6.25 5 6.25H5.5Z" fill="currentColor" />
    </svg>
);

export const MutedIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M22.5295 13.4202L21.0795 11.9702L22.4795 10.5702C22.7695 10.2802 22.7695 9.80018 22.4795 9.51018C22.1895 9.22018 21.7095 9.22018 21.4195 9.51018L20.0195 10.9102L18.5695 9.46018C18.2795 9.17018 17.7995 9.17018 17.5095 9.46018C17.2195 9.75018 17.2195 10.2302 17.5095 10.5202L18.9595 11.9702L17.4695 13.4602C17.1795 13.7502 17.1795 14.2302 17.4695 14.5202C17.6195 14.6702 17.8095 14.7402 17.9995 14.7402C18.1895 14.7402 18.3795 14.6702 18.5295 14.5202L20.0195 13.0302L21.4695 14.4802C21.6195 14.6302 21.8095 14.7002 21.9995 14.7002C22.1895 14.7002 22.3795 14.6302 22.5295 14.4802C22.8195 14.1902 22.8195 13.7202 22.5295 13.4202Z" fill="currentColor" />
        <path opacity="0.4" d="M15.75 7.41021V16.5902C15.75 18.3102 15.13 19.6002 14.02 20.2202C13.57 20.4702 13.07 20.5902 12.55 20.5902C11.75 20.5902 10.89 20.3202 10.01 19.7702L7.09 17.9402C6.89 17.8202 6.66 17.7502 6.43 17.7502H5.5V6.25021H6.43C6.66 6.25021 6.89 6.18021 7.09 6.06021L10.01 4.23021C11.47 3.32021 12.9 3.16021 14.02 3.78021C15.13 4.40021 15.75 5.69021 15.75 7.41021Z" fill="currentColor" />
        <path d="M5.5 6.25V17.75H5C2.58 17.75 1.25 16.42 1.25 14V10C1.25 7.58 2.58 6.25 5 6.25H5.5Z" fill="currentColor" />
    </svg>
);

export const NoSoundIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M18.0003 16.7498C17.8403 16.7498 17.6903 16.6998 17.5503 16.5998C17.2203 16.3498 17.1503 15.8798 17.4003 15.5498C18.6603 13.8698 18.9303 11.6398 18.1203 9.70983C17.9603 9.32983 18.1403 8.88983 18.5203 8.72983C18.9003 8.56983 19.3403 8.74983 19.5003 9.12983C20.5203 11.5498 20.1703 14.3598 18.6003 16.4598C18.4503 16.6498 18.2303 16.7498 18.0003 16.7498Z" fill="currentColor" />
        <path d="M19.8304 19.2502C19.6704 19.2502 19.5204 19.2002 19.3804 19.1002C19.0504 18.8502 18.9804 18.3802 19.2304 18.0502C21.3704 15.2002 21.8404 11.3802 20.4604 8.0902C20.3004 7.7102 20.4804 7.2702 20.8604 7.1102C21.2404 6.9502 21.6804 7.1302 21.8404 7.5102C23.4304 11.2902 22.8904 15.6702 20.4304 18.9502C20.2904 19.1502 20.0604 19.2502 19.8304 19.2502Z" fill="currentColor" />
        <path opacity="0.4" d="M14.0405 12.96C14.6705 12.33 15.7505 12.78 15.7505 13.67V16.6C15.7505 18.32 15.1305 19.61 14.0205 20.23C13.5705 20.48 13.0705 20.6 12.5505 20.6C11.7505 20.6 10.8905 20.33 10.0105 19.78L9.37047 19.38C8.83047 19.04 8.74047 18.28 9.19047 17.83L14.0405 12.96Z" fill="currentColor" />
        <path opacity="0.4" d="M14.02 3.78021C12.9 3.16021 11.47 3.32021 10.01 4.23021L7.09 6.06021C6.89 6.18021 6.66 6.25021 6.43 6.25021H5.5H5C2.58 6.25021 1.25 7.58021 1.25 10.0002V14.0002C1.25 16.4202 2.58 17.7502 5 17.7502H5.5H6.25L15.75 8.25021V7.41021C15.75 5.69021 15.13 4.40021 14.02 3.78021Z" fill="currentColor" />
        <path d="M21.7709 2.22988C21.4709 1.92988 20.9809 1.92988 20.6809 2.22988L2.23086 20.6899C1.93086 20.9899 1.93086 21.4799 2.23086 21.7799C2.38086 21.9199 2.57086 21.9999 2.77086 21.9999C2.97086 21.9999 3.16086 21.9199 3.31086 21.7699L21.7709 3.30988C22.0809 3.00988 22.0809 2.52988 21.7709 2.22988Z" fill="currentColor" />
    </svg>
);

export const ForwardVideo5Icon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path opacity="0.4" d="M19.4807 7.08981C19.2207 6.76981 18.7507 6.71977 18.4307 6.97977C18.1107 7.23977 18.0608 7.70981 18.3208 8.02981C19.4508 9.42981 20.0808 11.0898 20.1408 12.8298C20.3008 17.3098 16.7807 21.0898 12.2907 21.2398C7.81073 21.3998 4.03076 17.8798 3.88076 13.3898C3.73076 8.8998 7.24073 5.12977 11.7307 4.97977C12.3007 4.95977 12.9007 5.0098 13.5507 5.1398C13.5907 5.1498 13.6307 5.1398 13.6707 5.1398C13.7707 5.1898 13.8908 5.21976 14.0008 5.21976C14.1708 5.21976 14.3307 5.16977 14.4707 5.04977C14.7907 4.78977 14.8408 4.31978 14.5908 3.99978L12.6107 1.52981C12.3507 1.20981 11.8807 1.14976 11.5607 1.40976C11.2407 1.66976 11.1908 2.13981 11.4408 2.45981L12.2708 3.48978C12.0808 3.47978 11.8808 3.46977 11.6908 3.47977C6.38075 3.65977 2.21077 8.1398 2.40077 13.4498C2.59077 18.7598 7.06075 22.9298 12.3707 22.7398C17.6807 22.5498 21.8507 18.0798 21.6607 12.7698C21.5607 10.7098 20.8207 8.73981 19.4807 7.08981Z" fill="currentColor" />
        <path d="M12.3806 16.9198H10.0906C9.68065 16.9198 9.34065 16.5798 9.34065 16.1698C9.34065 15.7598 9.68065 15.4198 10.0906 15.4198H12.3806C12.8106 15.4198 13.1606 15.0698 13.1606 14.6398C13.1606 14.2098 12.8106 13.8598 12.3806 13.8598H10.0906C9.85065 13.8598 9.6206 13.7398 9.4806 13.5498C9.3406 13.3598 9.30062 13.0998 9.38062 12.8698L10.1406 10.5798C10.2406 10.2698 10.5306 10.0698 10.8506 10.0698H13.9106C14.3206 10.0698 14.6606 10.4098 14.6606 10.8198C14.6606 11.2298 14.3206 11.5698 13.9106 11.5698H11.3906L11.1306 12.3598H12.3806C13.6406 12.3598 14.6606 13.3798 14.6606 14.6398C14.6606 15.8998 13.6406 16.9198 12.3806 16.9198Z" fill="currentColor" />
    </svg>
);

export const ForwardVideo10Icon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path opacity="0.4" d="M19.6896 7.35014C19.4396 7.02014 18.9696 6.95018 18.6396 7.20018C18.3096 7.45018 18.2396 7.92017 18.4896 8.25017C19.5696 9.69017 20.1396 11.3702 20.1396 13.1102C20.1396 17.6002 16.4896 21.2502 11.9996 21.2502C7.5096 21.2502 3.85959 17.6002 3.85959 13.1102C3.85959 8.62015 7.5096 4.97014 11.9996 4.97014C12.5796 4.97014 13.1696 5.04017 13.8096 5.19017C13.8396 5.20017 13.8696 5.19017 13.9096 5.19017C13.9296 5.19017 13.9596 5.21019 13.9796 5.21019C14.0096 5.21019 14.0296 5.20018 14.0596 5.20018C14.0996 5.20018 14.1296 5.19016 14.1596 5.18016C14.2096 5.17016 14.2596 5.15016 14.3096 5.12016C14.3396 5.10016 14.3796 5.09017 14.4096 5.07017C14.4196 5.06017 14.4396 5.06016 14.4496 5.05016C14.4796 5.03016 14.4896 5.00015 14.5096 4.98015C14.5496 4.94015 14.5796 4.91015 14.6096 4.86015C14.6396 4.82015 14.6496 4.77015 14.6696 4.73015C14.6796 4.70015 14.6996 4.67018 14.7096 4.64018C14.7096 4.62018 14.7096 4.61019 14.7096 4.59019C14.7196 4.54019 14.7196 4.49017 14.7096 4.44017C14.7096 4.39017 14.7096 4.35016 14.6996 4.30016C14.6896 4.26016 14.6696 4.22015 14.6496 4.17015C14.6296 4.12015 14.6096 4.0702 14.5796 4.0302C14.5696 4.0202 14.5696 4.00016 14.5596 3.99016L12.5796 1.52019C12.3196 1.20019 11.8496 1.15019 11.5296 1.40019C11.2096 1.66019 11.1596 2.13018 11.4096 2.45018L12.2296 3.47014C12.1496 3.47014 12.0696 3.46019 11.9896 3.46019C6.67959 3.46019 2.34961 7.78014 2.34961 13.1001C2.34961 18.4201 6.66959 22.7402 11.9896 22.7402C17.3096 22.7402 21.6296 18.4201 21.6296 13.1001C21.6396 11.0401 20.9596 9.05014 19.6896 7.35014Z" fill="currentColor" />
        <path d="M9.53955 16.6701C9.12955 16.6701 8.78955 16.3301 8.78955 15.9201V12.5301L8.59955 12.7401C8.31955 13.0501 7.84955 13.0701 7.53955 12.8001C7.22955 12.5201 7.20955 12.0501 7.47955 11.7401L8.97955 10.0701C9.18955 9.84008 9.51954 9.76007 9.80954 9.87007C10.0995 9.98007 10.2895 10.2601 10.2895 10.5701V15.9201C10.2895 16.3401 9.95955 16.6701 9.53955 16.6701Z" fill="currentColor" />
        <path d="M14 16.6698C12.48 16.6698 11.25 15.4398 11.25 13.9198V12.5698C11.25 11.0498 12.48 9.81982 14 9.81982C15.52 9.81982 16.75 11.0498 16.75 12.5698V13.9198C16.75 15.4398 15.52 16.6698 14 16.6698ZM14 11.3298C13.31 11.3298 12.75 11.8898 12.75 12.5798V13.9298C12.75 14.6198 13.31 15.1798 14 15.1798C14.69 15.1798 15.25 14.6198 15.25 13.9298V12.5798C15.25 11.8898 14.69 11.3298 14 11.3298Z" fill="currentColor" />
    </svg>
);

export const PlayIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M18.7 8.97989L4.14 17.7099C4.05 17.3799 4 17.0299 4 16.6699V7.32989C4 4.24989 7.33 2.32989 10 3.86989L14.04 6.19989L18.09 8.53989C18.31 8.66989 18.52 8.80989 18.7 8.97989Z" fill="currentColor" />
        <path opacity="0.4" d="M18.0907 15.4598L14.0407 17.7998L10.0007 20.1298C8.0907 21.2298 5.8407 20.5698 4.7207 18.9598L5.1407 18.7098L19.5807 10.0498C20.5807 11.8498 20.0907 14.3098 18.0907 15.4598Z" fill="currentColor" />
    </svg>
);

export const PauseIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M10.65 19.11V4.89C10.65 3.54 10.08 3 8.64 3H5.01C3.57 3 3 3.54 3 4.89V19.11C3 20.46 3.57 21 5.01 21H8.64C10.08 21 10.65 20.46 10.65 19.11Z" fill="currentColor" />
        <path opacity="0.4" d="M20.9996 19.11V4.89C20.9996 3.54 20.4296 3 18.9896 3H15.3596C13.9296 3 13.3496 3.54 13.3496 4.89V19.11C13.3496 20.46 13.9196 21 15.3596 21H18.9896C20.4296 21 20.9996 20.46 20.9996 19.11Z" fill="currentColor" />
    </svg>
);

export const FullScreenIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path opacity="0.4" d="M16.48 2H8.52C5.07 2 3 4.06 3 7.52V15.47C3 18.94 5.07 21 8.52 21H16.47C19.93 21 21.99 18.94 21.99 15.48V7.52C22 4.06 19.93 2 16.48 2Z" fill="currentColor" />
        <path d="M11 16.15V18.85C11 21.1 10.1 22 7.85 22H5.15C2.9 22 2 21.1 2 18.85V16.15C2 13.9 2.9 13 5.15 13H7.85C10.1 13 11 13.9 11 16.15Z" fill="currentColor" />
        <path d="M18.7495 5.95C18.7495 5.86 18.7295 5.78 18.6995 5.69C18.6995 5.68 18.6895 5.67 18.6895 5.67C18.6595 5.6 18.6095 5.53 18.5595 5.48C18.5395 5.46 18.5195 5.44 18.4995 5.43C18.4495 5.39 18.3995 5.35 18.3495 5.33C18.3295 5.32 18.2995 5.31 18.2795 5.3C18.1995 5.27 18.1095 5.25 18.0195 5.25H13.9995C13.5895 5.25 13.2495 5.59 13.2495 6C13.2495 6.41 13.5795 6.72 13.9995 6.72H16.2095L12.4695 10.47C12.1795 10.76 12.1795 11.24 12.4695 11.53C12.6195 11.68 12.8095 11.75 12.9995 11.75C13.1895 11.75 13.3795 11.68 13.5295 11.53L17.2595 7.79V9.99C17.2595 10.4 17.5995 10.74 18.0095 10.74C18.4195 10.74 18.7595 10.4 18.7595 9.99V5.97C18.7595 5.96 18.7595 5.96 18.7495 5.95Z" fill="currentColor" />
    </svg>
);

export const TreeClosedIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path opacity="0.4" d="M16.1898 21.9998H7.81976C4.17976 21.9998 2.00977 19.8298 2.00977 16.1898V7.81976C2.00977 4.17976 4.17976 2.00977 7.81976 2.00977H16.1898C19.8298 2.00977 21.9998 4.17976 21.9998 7.81976V16.1898C21.9998 19.8298 19.8298 21.9998 16.1898 21.9998Z" fill="currentColor" />
        <path d="M16.41 9.83002C15.8 11.57 14.16 12.74 12.32 12.74C12.31 12.74 12.31 12.74 12.3 12.74L10.24 12.73C10.24 12.73 10.24 12.73 10.23 12.73C9.46998 12.73 8.80999 13.24 8.60999 13.97C9.49999 14.25 10.15 15.08 10.15 16.06C10.15 17.27 9.16001 18.26 7.95001 18.26C6.74001 18.26 5.75 17.27 5.75 16.06C5.75 15.17 6.28999 14.4 7.04999 14.06V9.72003C6.28999 9.42003 5.75 8.68004 5.75 7.82004C5.75 6.69004 6.66999 5.77002 7.79999 5.77002C8.92999 5.77002 9.84998 6.69004 9.84998 7.82004C9.84998 8.69004 9.30999 9.42003 8.54999 9.72003V11.74C9.03999 11.43 9.61998 11.25 10.23 11.25H10.24L12.3 11.26C13.48 11.3 14.53 10.54 14.95 9.44003C14.46 9.06003 14.14 8.48004 14.14 7.82004C14.14 6.69004 15.06 5.77002 16.19 5.77002C17.32 5.77002 18.24 6.69004 18.24 7.82004C18.25 8.86004 17.44 9.72002 16.41 9.83002Z" fill="currentColor" />
    </svg>
);

export const TreeOpenedIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path opacity="0.4" d="M16.1898 2H7.81976C4.17976 2 2.00977 4.17 2.00977 7.81V16.18C2.00977 19.82 4.17976 21.99 7.81976 21.99H16.1898C19.8298 21.99 21.9998 19.82 21.9998 16.18V7.81C21.9998 4.17 19.8298 2 16.1898 2Z" fill="currentColor" />
        <path d="M16.41 14.1703C15.8 12.4303 14.16 11.2603 12.32 11.2603C12.31 11.2603 12.31 11.2603 12.3 11.2603L10.24 11.2702C10.24 11.2702 10.24 11.2702 10.23 11.2702C9.46998 11.2702 8.80999 10.7602 8.60999 10.0302C9.49999 9.75024 10.15 8.92025 10.15 7.94025C10.15 6.73025 9.16001 5.74023 7.95001 5.74023C6.74001 5.74023 5.75 6.73025 5.75 7.94025C5.75 8.83025 6.28999 9.60025 7.04999 9.94025V14.2802C6.28999 14.5802 5.75 15.3202 5.75 16.1802C5.75 17.3102 6.66999 18.2303 7.79999 18.2303C8.92999 18.2303 9.84998 17.3102 9.84998 16.1802C9.84998 15.3102 9.30999 14.5802 8.54999 14.2802V12.2603C9.03999 12.5703 9.61998 12.7502 10.23 12.7502H10.24L12.3 12.7402C13.48 12.7002 14.53 13.4602 14.95 14.5602C14.46 14.9402 14.14 15.5202 14.14 16.1802C14.14 17.3102 15.06 18.2303 16.19 18.2303C17.32 18.2303 18.24 17.3102 18.24 16.1802C18.25 15.1402 17.44 14.2803 16.41 14.1703Z" fill="currentColor" />
    </svg>
);

export const CloseIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12Z" fill="currentColor" fillOpacity="0.5" />
        <path d="M8.96934 8.97015C9.10997 8.8297 9.30059 8.75081 9.49934 8.75081C9.69809 8.75081 9.88871 8.8297 10.0293 8.97015L11.9993 10.9402L13.9693 8.97015C14.038 8.89647 14.1208 8.83736 14.2128 8.79637C14.3048 8.75538 14.4041 8.73334 14.5048 8.73156C14.6055 8.72979 14.7055 8.74831 14.7989 8.78603C14.8923 8.82375 14.9772 8.8799 15.0484 8.95112C15.1196 9.02233 15.1757 9.10717 15.2135 9.20056C15.2512 9.29394 15.2697 9.39397 15.2679 9.49468C15.2662 9.59538 15.2441 9.69469 15.2031 9.78669C15.1621 9.87869 15.103 9.96149 15.0293 10.0302L13.0593 12.0002L15.0293 13.9702C15.1618 14.1123 15.2339 14.3004 15.2305 14.4947C15.2271 14.689 15.1484 14.8744 15.011 15.0118C14.8735 15.1492 14.6882 15.2279 14.4939 15.2313C14.2996 15.2348 14.1115 15.1626 13.9693 15.0302L11.9993 13.0602L10.0293 15.0302C9.88716 15.1626 9.69912 15.2348 9.50482 15.2313C9.31052 15.2279 9.12513 15.1492 8.98772 15.0118C8.85031 14.8744 8.77159 14.689 8.76816 14.4947C8.76474 14.3004 8.83686 14.1123 8.96934 13.9702L10.9393 12.0002L8.96934 10.0302C8.82889 9.88953 8.75 9.6989 8.75 9.50015C8.75 9.3014 8.82889 9.11078 8.96934 8.97015Z" fill="currentColor" />
    </svg>
);

export const AllUsersIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path opacity="0.4" d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z" fill="currentColor" />
        <path d="M14.0809 14.1499C11.2909 12.2899 6.74094 12.2899 3.93094 14.1499C2.66094 14.9999 1.96094 16.1499 1.96094 17.3799C1.96094 18.6099 2.66094 19.7499 3.92094 20.5899C5.32094 21.5299 7.16094 21.9999 9.00094 21.9999C10.8409 21.9999 12.6809 21.5299 14.0809 20.5899C15.3409 19.7399 16.0409 18.5999 16.0409 17.3599C16.0309 16.1299 15.3409 14.9899 14.0809 14.1499Z" fill="currentColor" />
        <path opacity="0.4" d="M19.9894 7.3401C20.1494 9.2801 18.7694 10.9801 16.8594 11.2101C16.8494 11.2101 16.8494 11.2101 16.8394 11.2101H16.8094C16.7494 11.2101 16.6894 11.2101 16.6394 11.2301C15.6694 11.2801 14.7794 10.9701 14.1094 10.4001C15.1394 9.4801 15.7294 8.1001 15.6094 6.6001C15.5394 5.7901 15.2594 5.0501 14.8394 4.4201C15.2194 4.2301 15.6594 4.1101 16.1094 4.0701C18.0694 3.9001 19.8194 5.3601 19.9894 7.3401Z" fill="currentColor" />
        <path d="M21.9902 16.5899C21.9102 17.5599 21.2902 18.3999 20.2502 18.9699C19.2502 19.5199 17.9902 19.7799 16.7402 19.7499C17.4602 19.0999 17.8802 18.2899 17.9602 17.4299C18.0602 16.1899 17.4702 14.9999 16.2902 14.0499C15.6202 13.5199 14.8402 13.0999 13.9902 12.7899C16.2002 12.1499 18.9802 12.5799 20.6902 13.9599C21.6102 14.6999 22.0802 15.6299 21.9902 16.5899Z" fill="currentColor" />
    </svg>
);

export const ZoomInIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path opacity="0.4" d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" fill="currentColor" />
        <path d="M21.3005 22.0001C21.1205 22.0001 20.9405 21.9301 20.8105 21.8001L18.9505 19.9401C18.6805 19.6701 18.6805 19.2301 18.9505 18.9501C19.2205 18.6801 19.6605 18.6801 19.9405 18.9501L21.8005 20.8101C22.0705 21.0801 22.0705 21.5201 21.8005 21.8001C21.6605 21.9301 21.4805 22.0001 21.3005 22.0001Z" fill="currentColor" />
        <path d="M14.1992 10.95H12.4492V9.19995C12.4492 8.78995 12.1092 8.44995 11.6992 8.44995C11.2892 8.44995 10.9492 8.78995 10.9492 9.19995V10.95H9.19922C8.78922 10.95 8.44922 11.29 8.44922 11.7C8.44922 12.11 8.78922 12.45 9.19922 12.45H10.9492V14.2C10.9492 14.61 11.2892 14.95 11.6992 14.95C12.1092 14.95 12.4492 14.61 12.4492 14.2V12.45H14.1992C14.6092 12.45 14.9492 12.11 14.9492 11.7C14.9492 11.29 14.6092 10.95 14.1992 10.95Z" fill="currentColor" />
    </svg>
);

export const DataSchemaIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M3 9C4.10457 9 5 8.10457 5 7C5 5.89543 4.10457 5 3 5C1.89543 5 1 5.89543 1 7C1 8.10457 1.89543 9 3 9Z" fill="currentColor" />
        <path d="M21 9C22.1046 9 23 8.10457 23 7C23 5.89543 22.1046 5 21 5C19.8954 5 19 5.89543 19 7C19 8.10457 19.8954 9 21 9Z" fill="currentColor" />
        <path d="M7.5 16.5V18.5C7.5 19.11 7.13 19.64 6.61 19.86C6.42 19.95 6.22 20 6 20H4C3.17 20 2.5 19.33 2.5 18.5V16.5C2.5 15.67 3.17 15 4 15H6C6.83 15 7.5 15.67 7.5 16.5Z" fill="currentColor" />
        <path d="M21.5 16.5V18.5C21.5 19.33 20.83 20 20 20H18C17.78 20 17.58 19.95 17.39 19.86C16.87 19.64 16.5 19.11 16.5 18.5V16.5C16.5 15.67 17.17 15 18 15H20C20.83 15 21.5 15.67 21.5 16.5Z" fill="currentColor" />
        <path d="M15 5.5V8.5C15 9.32 14.32 10 13.5 10H10.5C9.68 10 9 9.32 9 8.5V5.5C9 4.68 9.68 4 10.5 4H13.5C14.32 4 15 4.68 15 5.5Z" fill="currentColor" />
        <g opacity="0.4">
            <path opacity="0.4" d="M9 6.25H5C4.59 6.25 4.25 6.59 4.25 7C4.25 7.41 4.59 7.75 5 7.75H7.57C5.52 9.27 4.25 11.79 4.25 14.5C4.25 14.7 4.26 14.89 4.29 15.09C4.33 15.47 4.66 15.75 5.03 15.75C5.06 15.75 5.09 15.75 5.12 15.75C5.53 15.7 5.83 15.33 5.78 14.92C5.76 14.78 5.76 14.65 5.76 14.51C5.76 11.91 7.17 9.50997 9.35 8.40997C9.72 8.21997 9.87 7.77002 9.68 7.40002C9.67 7.39002 9.66 7.38 9.66 7.37C9.72 7.26 9.77 7.14001 9.77 7.01001C9.75 6.59001 9.41 6.25 9 6.25Z" fill="currentColor" />
            <path opacity="0.4" d="M16.43 7.75H19C19.41 7.75 19.75 7.41 19.75 7C19.75 6.59 19.41 6.25 19 6.25H15C14.59 6.25 14.25 6.59 14.25 7C14.25 7.13 14.29 7.24999 14.36 7.35999C14.35 7.36999 14.34 7.38001 14.34 7.39001C14.15 7.76001 14.3 8.21002 14.67 8.40002C16.85 9.50002 18.26 11.9 18.26 14.5C18.26 14.64 18.25 14.77 18.24 14.91C18.19 15.32 18.49 15.69 18.9 15.74C18.93 15.74 18.96 15.74 18.99 15.74C19.37 15.74 19.69 15.46 19.73 15.08C19.75 14.88 19.77 14.69 19.77 14.49C19.75 11.79 18.48 9.27 16.43 7.75Z" fill="currentColor" />
        </g>
    </svg>
);

export const TrashIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M21.0697 5.23C19.4597 5.07 17.8497 4.95 16.2297 4.86V4.85L16.0097 3.55C15.8597 2.63 15.6397 1.25 13.2997 1.25H10.6797C8.34967 1.25 8.12967 2.57 7.96967 3.54L7.75967 4.82C6.82967 4.88 5.89967 4.94 4.96967 5.03L2.92967 5.23C2.50967 5.27 2.20967 5.64 2.24967 6.05C2.28967 6.46 2.64967 6.76 3.06967 6.72L5.10967 6.52C10.3497 6 15.6297 6.2 20.9297 6.73C20.9597 6.73 20.9797 6.73 21.0097 6.73C21.3897 6.73 21.7197 6.44 21.7597 6.05C21.7897 5.64 21.4897 5.27 21.0697 5.23Z" fill="currentColor" />
        <path opacity="0.3991" d="M19.2297 8.14C18.9897 7.89 18.6597 7.75 18.3197 7.75H5.67975C5.33975 7.75 4.99975 7.89 4.76975 8.14C4.53975 8.39 4.40975 8.73 4.42975 9.08L5.04975 19.34C5.15975 20.86 5.29975 22.76 8.78975 22.76H15.2097C18.6997 22.76 18.8398 20.87 18.9497 19.34L19.5697 9.09C19.5897 8.73 19.4597 8.39 19.2297 8.14Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M9.58008 17C9.58008 16.5858 9.91586 16.25 10.3301 16.25H13.6601C14.0743 16.25 14.4101 16.5858 14.4101 17C14.4101 17.4142 14.0743 17.75 13.6601 17.75H10.3301C9.91586 17.75 9.58008 17.4142 9.58008 17Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.75 13C8.75 12.5858 9.08579 12.25 9.5 12.25H14.5C14.9142 12.25 15.25 12.5858 15.25 13C15.25 13.4142 14.9142 13.75 14.5 13.75H9.5C9.08579 13.75 8.75 13.4142 8.75 13Z" fill="currentColor" />
    </svg>
);

export const VideoIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path opacity="0.4" d="M13 3.25H7C3.58 3.25 2.25 4.58 2.25 8V16C2.25 18.3 3.5 20.75 7 20.75H13C16.42 20.75 17.75 19.42 17.75 16V8C17.75 4.58 16.42 3.25 13 3.25Z" fill="currentColor" />
        <path d="M11.4991 11.3801C12.5374 11.3801 13.3791 10.5384 13.3791 9.50012C13.3791 8.46182 12.5374 7.62012 11.4991 7.62012C10.4608 7.62012 9.61914 8.46182 9.61914 9.50012C9.61914 10.5384 10.4608 11.3801 11.4991 11.3801Z" fill="currentColor" />
        <path d="M21.6505 6.17011C21.2405 5.96011 20.3805 5.72011 19.2105 6.54011L17.7305 7.58011C17.7405 7.72011 17.7505 7.85011 17.7505 8.00011V16.0001C17.7505 16.1501 17.7305 16.2801 17.7305 16.4201L19.2105 17.4601C19.8305 17.9001 20.3705 18.0401 20.8005 18.0401C21.1705 18.0401 21.4605 17.9401 21.6505 17.8401C22.0605 17.6301 22.7505 17.0601 22.7505 15.6301V8.38011C22.7505 6.95011 22.0605 6.38011 21.6505 6.17011Z" fill="currentColor" />
    </svg>
);

export const ZoomOutIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path opacity="0.4" d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" fill="currentColor" />
        <path d="M21.3005 22.0001C21.1205 22.0001 20.9405 21.9301 20.8105 21.8001L18.9505 19.9401C18.6805 19.6701 18.6805 19.2301 18.9505 18.9501C19.2205 18.6801 19.6605 18.6801 19.9405 18.9501L21.8005 20.8101C22.0705 21.0801 22.0705 21.5201 21.8005 21.8001C21.6605 21.9301 21.4805 22.0001 21.3005 22.0001Z" fill="currentColor" />
        <path d="M14 12.45H9C8.59 12.45 8.25 12.11 8.25 11.7C8.25 11.29 8.59 10.95 9 10.95H14C14.41 10.95 14.75 11.29 14.75 11.7C14.75 12.11 14.41 12.45 14 12.45Z" fill="currentColor" />
    </svg>
);

export const MailIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M14.2 3H9.8C5.652 3 3.577 3 2.289 4.318C1 5.636 1 7.758 1 12C1 16.243 1 18.364 2.289 19.682C3.577 21 5.652 21 9.8 21H14.2C18.348 21 20.423 21 21.711 19.682C23 18.364 23 16.242 23 12C23 7.757 23 5.636 21.711 4.318C20.423 3 18.348 3 14.2 3Z" fill="currentColor" fillOpacity="0.5" />
        <path d="M19.1273 8.0328C19.2954 7.89277 19.4011 7.69167 19.4209 7.47376C19.4408 7.25584 19.3733 7.03895 19.2333 6.8708C19.0933 6.70265 18.8922 6.59702 18.6742 6.57714C18.4563 6.55727 18.2394 6.62477 18.0713 6.7648L15.6963 8.7448C14.6703 9.5998 13.9583 10.1918 13.3563 10.5778C12.7743 10.9528 12.3793 11.0778 11.9993 11.0778C11.6193 11.0778 11.2253 10.9528 10.6423 10.5778C10.0413 10.1918 9.32829 9.5998 8.30229 8.7438L5.92729 6.7648C5.75914 6.62477 5.54225 6.55727 5.32434 6.57714C5.10642 6.59702 4.90532 6.70265 4.76529 6.8708C4.62526 7.03895 4.55775 7.25584 4.57763 7.47376C4.59751 7.69167 4.70314 7.89277 4.87129 8.0328L7.28729 10.0468C8.26229 10.8588 9.05229 11.5168 9.75029 11.9668C10.4763 12.4328 11.1843 12.7288 12.0003 12.7288C12.8143 12.7288 13.5223 12.4328 14.2493 11.9658C14.9463 11.5178 15.7363 10.8588 16.7113 10.0458L19.1273 8.0328Z" fill="currentColor" />
    </svg>
);

export const UpDownArrowsIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M10.9795 6.19L7.2695 2.47998C7.1995 2.40998 7.10949 2.35 7.00949 2.31C6.99949 2.31 6.98946 2.30999 6.97946 2.29999C6.89946 2.26999 6.80945 2.25 6.71945 2.25C6.51945 2.25 6.32948 2.32997 6.18948 2.46997L2.46945 6.19C2.17945 6.48 2.17945 6.96 2.46945 7.25C2.75945 7.54 3.23951 7.54 3.52951 7.25L5.97946 4.79999V21C5.97946 21.41 6.31946 21.75 6.72946 21.75C7.13946 21.75 7.47946 21.41 7.47946 21V4.81L9.91947 7.25C10.0695 7.4 10.2595 7.46997 10.4495 7.46997C10.6395 7.46997 10.8295 7.4 10.9795 7.25C11.2695 6.96 11.2695 6.49 10.9795 6.19Z" fill="currentColor" />
        <path opacity="0.4" d="M21.5302 16.75C21.2402 16.46 20.7602 16.46 20.4702 16.75L18.0202 19.2V3C18.0202 2.59 17.6802 2.25 17.2702 2.25C16.8602 2.25 16.5202 2.59 16.5202 3V19.19L14.0802 16.75C13.7902 16.46 13.3102 16.46 13.0202 16.75C12.7302 17.04 12.7302 17.52 13.0202 17.81L16.7302 21.52C16.8002 21.59 16.8902 21.65 16.9902 21.69C17.0002 21.69 17.0102 21.69 17.0202 21.7C17.1002 21.73 17.1902 21.75 17.2802 21.75C17.4802 21.75 17.6702 21.67 17.8102 21.53L21.5302 17.81C21.8202 17.51 21.8202 17.04 21.5302 16.75Z" fill="currentColor" />
    </svg>
);

export const DoneIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path opacity="0.4" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="currentColor" />
        <path d="M10.5795 15.5801C10.3795 15.5801 10.1895 15.5001 10.0495 15.3601L7.21945 12.5301C6.92945 12.2401 6.92945 11.7601 7.21945 11.4701C7.50945 11.1801 7.98945 11.1801 8.27945 11.4701L10.5795 13.7701L15.7195 8.6301C16.0095 8.3401 16.4895 8.3401 16.7795 8.6301C17.0695 8.9201 17.0695 9.4001 16.7795 9.6901L11.1095 15.3601C10.9695 15.5001 10.7795 15.5801 10.5795 15.5801Z" fill="currentColor" />
    </svg>

);

export const SettingsIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path opacity="0.4" d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z" fill="currentColor" />
        <path d="M12 15.25C13.7949 15.25 15.25 13.7949 15.25 12C15.25 10.2051 13.7949 8.75 12 8.75C10.2051 8.75 8.75 10.2051 8.75 12C8.75 13.7949 10.2051 15.25 12 15.25Z" fill="currentColor" />
    </svg>
);

export const EditIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path opacity="0.4" d="M21 22H3C2.59 22 2.25 21.66 2.25 21.25C2.25 20.84 2.59 20.5 3 20.5H21C21.41 20.5 21.75 20.84 21.75 21.25C21.75 21.66 21.41 22 21 22Z" fill="currentColor" />
        <path opacity="0.4" d="M19.0206 3.48016C17.0806 1.54016 15.1806 1.49016 13.1906 3.48016L11.9806 4.69016C11.8806 4.79016 11.8406 4.95016 11.8806 5.09016C12.6406 7.74016 14.7606 9.86016 17.4106 10.6202C17.4506 10.6302 17.4906 10.6402 17.5306 10.6402C17.6406 10.6402 17.7406 10.6002 17.8206 10.5202L19.0206 9.31016C20.0106 8.33016 20.4906 7.38016 20.4906 6.42016C20.5006 5.43016 20.0206 4.47016 19.0206 3.48016Z" fill="currentColor" />
        <path d="M15.6103 11.5298C15.3203 11.3898 15.0403 11.2498 14.7703 11.0898C14.5503 10.9598 14.3403 10.8198 14.1303 10.6698C13.9603 10.5598 13.7603 10.3998 13.5703 10.2398C13.5503 10.2298 13.4803 10.1698 13.4003 10.0898C13.0703 9.8098 12.7003 9.4498 12.3703 9.0498C12.3403 9.0298 12.2903 8.9598 12.2203 8.8698C12.1203 8.7498 11.9503 8.5498 11.8003 8.3198C11.6803 8.1698 11.5403 7.9498 11.4103 7.7298C11.2503 7.4598 11.1103 7.1898 10.9703 6.9098C10.8303 6.6098 10.7203 6.3198 10.6203 6.0498L4.34032 12.3298C4.21032 12.4598 4.09032 12.7098 4.06032 12.8798L3.52032 16.7098C3.42032 17.3898 3.61032 18.0298 4.03032 18.4598C4.39032 18.8098 4.89032 18.9998 5.43032 18.9998C5.55032 18.9998 5.67032 18.9898 5.79032 18.9698L9.63032 18.4298C9.81032 18.3998 10.0603 18.2798 10.1803 18.1498L16.4603 11.8698C16.1803 11.7698 15.9103 11.6598 15.6103 11.5298Z" fill="currentColor" />
    </svg>

);

export const PaperClipIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path opacity="0.4" d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z" fill="currentColor" />
        <path d="M12.2003 17.6598C11.5003 17.6598 10.7903 17.3898 10.2603 16.8598C9.19031 15.7898 9.19031 14.0398 10.2603 12.9698L11.6703 11.5598C11.9603 11.2698 12.4403 11.2698 12.7303 11.5598C13.0203 11.8498 13.0203 12.3298 12.7303 12.6198L11.3203 14.0298C10.8303 14.5198 10.8303 15.3098 11.3203 15.7998C11.8103 16.2898 12.6003 16.2898 13.0903 15.7998L15.3103 13.5798C15.9203 12.9698 16.2603 12.1498 16.2603 11.2798C16.2603 10.4098 15.9203 9.59978 15.3103 8.97978C14.0803 7.74978 11.9403 7.74978 10.7103 8.97978L8.29031 11.3998C7.25031 12.4398 7.25031 14.1398 8.29031 15.1898C8.58031 15.4798 8.58031 15.9598 8.29031 16.2498C8.00031 16.5398 7.52031 16.5398 7.23031 16.2498C5.60031 14.6198 5.60031 11.9698 7.23031 10.3398L9.65031 7.91979C10.5503 7.01979 11.7403 6.52979 13.0103 6.52979C14.2803 6.52979 15.4703 7.01979 16.3703 7.91979C17.2703 8.81979 17.7603 10.0098 17.7603 11.2798C17.7603 12.5498 17.2703 13.7398 16.3703 14.6398L14.1503 16.8598C13.6103 17.3898 12.9103 17.6598 12.2003 17.6598Z" fill="currentColor" />
    </svg>

);

export const CloudFolderIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path opacity="0.4" d="M21.9902 10.84C21.9702 10.36 21.8902 9.88999 21.7402 9.43999C21.0502 7.27999 19.0303 5.71999 16.6503 5.71999H13.8602C13.2802 5.71999 13.2402 5.65998 12.9302 5.24998L11.5303 3.38998C10.8803 2.51998 10.3702 1.98999 8.74023 1.98999H6.41022C3.97022 1.98999 1.99023 3.96999 1.99023 6.40999V9.42998V16.64C1.99023 19.59 4.39027 21.99 7.34027 21.99H16.6403C19.5903 21.99 21.9902 19.59 21.9902 16.64V11.06C22.0002 11 22.0002 10.91 21.9902 10.84Z" fill="currentColor" />
        <path d="M15.5805 19.7C13.4705 19.85 13.4705 22.91 15.5805 23.06H20.5906C21.2006 23.06 21.7805 22.84 22.2305 22.43C23.7105 21.14 22.9205 18.54 20.9705 18.3C20.2705 14.08 14.1605 15.68 15.6105 19.7" fill="currentColor" />
    </svg>

);

export const UserIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path opacity="0.4" d="M22 7.81V16.19C22 19 20.71 20.93 18.44 21.66C17.78 21.89 17.02 22 16.19 22H7.81C6.98 22 6.22 21.89 5.56 21.66C3.29 20.93 2 19 2 16.19V7.81C2 4.17 4.17 2 7.81 2H16.19C19.83 2 22 4.17 22 7.81Z" fill="currentColor" />
        <path d="M18.4406 21.66C17.7806 21.89 17.0206 22 16.1906 22H7.81055C6.98055 22 6.22055 21.89 5.56055 21.66C5.91055 19.02 8.67055 16.97 12.0005 16.97C15.3305 16.97 18.0906 19.02 18.4406 21.66Z" fill="currentColor" />
        <path d="M15.5799 11.58C15.5799 13.56 13.9799 15.17 11.9999 15.17C10.0199 15.17 8.41992 13.56 8.41992 11.58C8.41992 9.60002 10.0199 8 11.9999 8C13.9799 8 15.5799 9.60002 15.5799 11.58Z" fill="currentColor" />
    </svg>

);

export const EyeClosedIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path opacity="0.4" d="M21.2496 9.15004C20.7596 8.37004 20.1996 7.67004 19.6196 7.04004L15.8496 10.81C15.9696 11.18 16.0396 11.58 16.0396 12C16.0396 14.24 14.2296 16.04 11.9996 16.04C11.5796 16.04 11.1796 15.97 10.8096 15.85L7.34961 19.31C8.80961 20.13 10.3896 20.56 11.9996 20.56C13.7796 20.56 15.5096 20.04 17.0896 19.07C18.6696 18.09 20.0896 16.66 21.2496 14.84C22.2496 13.28 22.2496 10.72 21.2496 9.15004Z" fill="currentColor" />
        <path d="M14.0206 9.97989L9.98062 14.0199C9.47062 13.4999 9.14062 12.7799 9.14062 11.9999C9.14062 10.4299 10.4206 9.13989 12.0006 9.13989C12.7806 9.13989 13.5006 9.46989 14.0206 9.97989Z" fill="currentColor" />
        <path opacity="0.4" d="M18.25 5.74993L14.86 9.13993C14.13 8.39993 13.12 7.95993 12 7.95993C9.76 7.95993 7.96 9.76993 7.96 11.9999C7.96 13.1199 8.41 14.1299 9.14 14.8599L5.76 18.2499H5.75C4.64 17.3499 3.62 16.1999 2.75 14.8399C1.75 13.2699 1.75 10.7199 2.75 9.14993C3.91 7.32993 5.33 5.89993 6.91 4.91993C8.49 3.95993 10.22 3.42993 12 3.42993C14.23 3.42993 16.39 4.24993 18.25 5.74993Z" fill="currentColor" />
        <path d="M14.8601 12.0001C14.8601 13.5701 13.5801 14.8601 12.0001 14.8601C11.9401 14.8601 11.8901 14.8601 11.8301 14.8401L14.8401 11.8301C14.8601 11.8901 14.8601 11.9401 14.8601 12.0001Z" fill="currentColor" />
        <path d="M21.7709 2.22988C21.4709 1.92988 20.9809 1.92988 20.6809 2.22988L2.23086 20.6899C1.93086 20.9899 1.93086 21.4799 2.23086 21.7799C2.38086 21.9199 2.57086 21.9999 2.77086 21.9999C2.97086 21.9999 3.16086 21.9199 3.31086 21.7699L21.7709 3.30988C22.0809 3.00988 22.0809 2.52988 21.7709 2.22988Z" fill="currentColor" />
    </svg>

);

export const EyeOpenedIcon = ({
    size = 24,
    width,
    height,
    ...props
}: IconSvgProps) => (
    <svg
        fill="none"
        height={size || height}
        viewBox="0 0 24 24"
        width={size || width}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2 12C2 13.64 2.425 14.191 3.275 15.296C4.972 17.5 7.818 20 12 20C16.182 20 19.028 17.5 20.725 15.296C21.575 14.192 22 13.639 22 12C22 10.36 21.575 9.809 20.725 8.704C19.028 6.5 16.182 4 12 4C7.818 4 4.972 6.5 3.275 8.704C2.425 9.81 2 10.361 2 12Z"
            fill="currentColor"
            fillOpacity="0.5"
        />
        <path
            clipRule="evenodd"
            d="M8.25 12C8.25 11.0054 8.64509 10.0516 9.34835 9.34835C10.0516 8.64509 11.0054 8.25 12 8.25C12.9946 8.25 13.9484 8.64509 14.6517 9.34835C15.3549 10.0516 15.75 11.0054 15.75 12C15.75 12.9946 15.3549 13.9484 14.6517 14.6517C13.9484 15.3549 12.9946 15.75 12 15.75C11.0054 15.75 10.0516 15.3549 9.34835 14.6517C8.64509 13.9484 8.25 12.9946 8.25 12ZM9.75 12C9.75 11.4033 9.98705 10.831 10.409 10.409C10.831 9.98705 11.4033 9.75 12 9.75C12.5967 9.75 13.169 9.98705 13.591 10.409C14.0129 10.831 14.25 11.4033 14.25 12C14.25 12.5967 14.0129 13.169 13.591 13.591C13.169 14.0129 12.5967 14.25 12 14.25C11.4033 14.25 10.831 14.0129 10.409 13.591C9.98705 13.169 9.75 12.5967 9.75 12Z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
);

export const HospitalIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M22 21.25H2C1.59 21.25 1.25 21.59 1.25 22C1.25 22.41 1.59 22.75 2 22.75H22C22.41 22.75 22.75 22.41 22.75 22C22.75 21.59 22.41 21.25 22 21.25Z" fill="currentColor" />
        <path opacity="0.4" d="M17 2H7C4 2 3 3.79 3 6V22H21V6C21 3.79 20 2 17 2Z" fill="currentColor" />
        <path d="M14.0602 15H9.93022C9.42022 15 8.99023 15.42 8.99023 15.94V22H14.9902V15.94C15.0002 15.42 14.5802 15 14.0602 15Z" fill="currentColor" />
        <path d="M14.5 7.75H12.75V6C12.75 5.59 12.41 5.25 12 5.25C11.59 5.25 11.25 5.59 11.25 6V7.75H9.5C9.09 7.75 8.75 8.09 8.75 8.5C8.75 8.91 9.09 9.25 9.5 9.25H11.25V11C11.25 11.41 11.59 11.75 12 11.75C12.41 11.75 12.75 11.41 12.75 11V9.25H14.5C14.91 9.25 15.25 8.91 15.25 8.5C15.25 8.09 14.91 7.75 14.5 7.75Z" fill="currentColor" />
    </svg>

);

export const VacationBoxIcon: React.FC<IconSvgProps> = ({
    size = 24,
    width,
    height,
    ...props
}) => (

    <svg
        height={size || height}
        width={size || width}
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M11.1307 15.8799C10.8807 15.8799 10.6307 15.7499 10.4907 15.5199C10.2807 15.1699 10.3907 14.6999 10.7507 14.4899L11.6407 13.9599V12.8799C11.6407 12.4699 11.9807 12.1299 12.3907 12.1299C12.8007 12.1299 13.1407 12.4699 13.1407 12.8799V14.3799C13.1407 14.6399 13.0007 14.8899 12.7807 15.0199L11.5307 15.7699C11.3907 15.8399 11.2607 15.8799 11.1307 15.8799Z" fill="currentColor" />
        <path opacity="0.4" d="M21.8504 11.4099L21.2304 18.1899C21.0204 20.1899 20.2004 22.2299 15.8004 22.2299H8.18039C3.78039 22.2299 2.96039 20.1899 2.76039 18.1999L2.15039 11.6899C2.16039 11.6999 2.17039 11.7099 2.19039 11.7199C2.53039 11.9399 2.86039 12.1599 3.22039 12.3599C3.36039 12.4499 3.51039 12.5299 3.66039 12.6099C4.79039 13.2299 6.00039 13.7199 7.25039 14.0599C7.75039 14.2099 8.26039 14.3199 8.78039 14.4099C8.98039 16.0099 10.3504 17.2499 12.0004 17.2499C13.6704 17.2499 15.0504 15.9799 15.2304 14.3499V14.3399C15.7404 14.2399 16.2504 14.1099 16.7504 13.9599C18.0004 13.5699 19.2104 13.0499 20.3404 12.3899C20.4004 12.3599 20.4504 12.3299 20.4904 12.2999C20.9504 12.0499 21.3904 11.7599 21.8104 11.4599C21.8304 11.4499 21.8404 11.4299 21.8504 11.4099Z" fill="currentColor" />
        <path d="M21.0891 6.98002C20.2391 6.04002 18.8291 5.57002 16.7591 5.57002H16.5191V5.53002C16.5191 3.85002 16.5191 1.77002 12.7591 1.77002H11.2391C7.47906 1.77002 7.47906 3.85002 7.47906 5.53002V5.57002H7.23906C5.16906 5.57002 3.74906 6.04002 2.90906 6.98002C1.91906 8.09002 1.94906 9.56002 2.04906 10.57L2.05906 10.64L2.14906 11.69C2.15906 11.7 2.17906 11.71 2.19906 11.72C2.53906 11.94 2.86906 12.16 3.22906 12.36C3.36906 12.45 3.51906 12.53 3.66906 12.61C4.79906 13.23 6.00906 13.72 7.24906 14.06C7.27906 16.65 9.39906 18.75 11.9991 18.75C14.6191 18.75 16.7491 16.62 16.7491 14V13.96C18.0091 13.58 19.2191 13.05 20.3491 12.39C20.4091 12.36 20.4491 12.33 20.4991 12.3C20.9591 12.05 21.3991 11.76 21.8191 11.46C21.8291 11.45 21.8491 11.43 21.8591 11.41L21.8991 11.05L21.9491 10.58C21.9591 10.52 21.9591 10.47 21.9691 10.4C22.0491 9.40002 22.0291 8.02002 21.0891 6.98002ZM8.90906 5.53002C8.90906 3.83002 8.90906 3.19002 11.2391 3.19002H12.7591C15.0891 3.19002 15.0891 3.83002 15.0891 5.53002V5.57002H8.90906V5.53002ZM11.9991 17.25C10.3491 17.25 8.97906 16.01 8.77906 14.41C8.75906 14.28 8.74906 14.14 8.74906 14C8.74906 12.21 10.2091 10.75 11.9991 10.75C13.7891 10.75 15.2491 12.21 15.2491 14C15.2491 14.12 15.2391 14.23 15.2291 14.34V14.35C15.0491 15.98 13.6691 17.25 11.9991 17.25Z" fill="currentColor" />
    </svg>

);
