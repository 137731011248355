/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import {
    ChangeEvent,
    useCallback, useEffect, useState,
} from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Modal } from '../../../../../components/ui/Modal/Modal';
import { HStack, VStack } from '../../../../../components/ui/Stack';
import { TabItem, Tabs, ThemeTab } from '../../../../../components/ui/Tabs/Tabs';
import { Text, TextSize } from '../../../../../components/ui/Text/Text';
import { classNames } from '../../../../../utils/classNames/classNames';
import styles from './FileModal.module.scss';

import { DropFileInput } from '../../../../../components/ui/DropFileInput/DropFileInput';
import { Button, ThemeButton } from '../../../../../components/ui/Button/Button';
import { SchemaData } from '../../types/orgschema';
import { AppLink, AppLinkTheme } from '../../../../../components/ui/AppLink/AppLink';
import { OrgSChemaService } from '../../services/OrgSchema.service';
import { useFormStore } from '../../store/files';
import { Input } from '../../../../../components/ui/Input/Input';

interface FileModalProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  node: SchemaData;
}

export interface IFileEditInput {
    cloudLink?: string;
    mailLink?: string;
    audioUrl?: string;
    videoUrl?: string;
    fileUrl?: string;
}

const tabsProfileModal: TabItem[] = [
    { value: 'Ccылки', content: 'Ccылки' },
    { value: 'Файлы', content: 'Файлы' },
    { value: 'Аудио', content: 'Аудио' },
    { value: 'Видео', content: 'Видео' },
];

export const FileModal = (props : FileModalProps) => {
    const {
        className, isOpen, onClose, node,
    } = props;

    const formData = useFormStore((state) => state.formData);

    const setFormData = useFormStore((state) => state.setFormData);
    const clearFormData = useFormStore((state) => state.clearFormData);

    const [activeTab, setActiveTab] = useState('Ccылки');

    const queryClient = useQueryClient();

    const handleInputChange = (field: keyof IFileEditInput) => (value: string) => {
        setFormData({ [field]: value });
    };

    const { mutate } = useMutation({
        mutationKey: ['update files orgSchema'],
        mutationFn: (id: string) => OrgSChemaService.updateFiles(id, node.type, formData),
        onSuccess: (response) => {
            toast.success('Ccылки успешно добавлены');

            queryClient.invalidateQueries({
                queryKey: ['orgSchema', response.data.id],
            });
        },
        onError(error: any) {
            if (
                error.response
        && error.response.status
        && error.response.data.message
            ) {
                toast.error(error.response.data.message);
            } else {
                toast.error('Ошибка при обновлении схемы');
            }
        },
    });

    const isValidURL = (url?: string) => {
        if (url === undefined) {
            return false;
        }
        const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
            + '((([a-z0-9]{1,})([\\-\\.]{1}[a-z0-9]{1,})*)\\.[a-z]{2,}|localhost|' // domain name
            + '((\\d{1,3}\\.){3}\\d{1,3}))' // IP address
            + '(\\:\\d+)?(\\/[-a-z0-9%_.~+]*)*' // port and path
            + '(\\?[;&a-z0-9%_.~+=-]*)?' // query string
            + '(\\#[-a-z0-9_]*)?$', 'i'); // fragment locator
        return !!pattern.test(url);
    };

    const handleChangeTab = (value: TabItem) => {
        setActiveTab(value.value);

        setFormData({});
    };

    const closeModal = () => {
        clearFormData();
        onClose();
    };

    const handleSubmitForm = () => {
        mutate(node.id);

        setTimeout(() => {
            closeModal();
        }, 1000);
    };

    const renderModalContent = useCallback((tab: string) => {
        switch (tab) {
        case 'Ccылки':
            return (
                <VStack align="start" max gap="16">

                    <VStack align="start" max gap="8">
                        <Input
                            onChange={handleInputChange('mailLink')}
                            value={formData.mailLink}
                            placeholder="Укажите ссылку корреспонденции"
                            label="Входящая корреспонденция"
                        />

                        {
                            formData.mailLink !== '' ? isValidURL(formData.mailLink) ? (<AppLink target="_blank" theme={AppLinkTheme.DEFAULT} to={node.mailLink}>Перейти по ссылке</AppLink>) : (<Text text="Некорректная ссылка" gap="0" />) : null
                        }

                    </VStack>

                    <VStack align="start" max gap="8">
                        <Input
                            onChange={handleInputChange('cloudLink')}
                            value={formData.cloudLink}
                            placeholder="Укажите ссылку папки"
                            label="Личная папка"
                        />

                        {
                            formData.cloudLink !== '' ? isValidURL(formData.cloudLink) ? (<AppLink target="_blank" theme={AppLinkTheme.DEFAULT} to={node.cloudLink}>Перейти по ссылке</AppLink>) : (<Text text="Некорректная ссылка" gap="0" />) : null
                        }

                    </VStack>

                </VStack>

            );

        case 'Файлы':
            return (

                <VStack align="start" max gap="16">

                    <DropFileInput
                        onChange={(url: string) => setFormData({ fileUrl: url })}
                        file={formData.fileUrl}
                        id="file"
                        folder={`${node.id}__files`}
                        name="file"
                        text="файлы"
                        animationType="image"
                    />

                </VStack>
            );

        case 'Аудио':
            return (
                <VStack max>

                    <DropFileInput
                        onChange={(url: string) => setFormData({ audioUrl: url })}
                        file={formData.audioUrl}
                        id="audio"
                        folder={`${node.id}__audio`}
                        name="audio"
                        animationType="audio"
                        text="аудио"
                    />

                </VStack>

            );

        case 'Видео':
            return (
                <VStack max>

                    <DropFileInput
                        onChange={(url: string) => setFormData({ videoUrl: url })}
                        file={formData.videoUrl}
                        id="video"
                        name="video"
                        folder={`${node.id}__video`}
                        animationType="video"
                        text="видео"
                    />

                </VStack>

            );

        default:
            return (
                <VStack align="start" max gap="16">

                    <VStack align="start" max gap="8">
                        <Input
                            onChange={handleInputChange('mailLink')}
                            value={formData.mailLink}
                            placeholder="Укажите ссылку корреспонденции"
                            label="Входящая корреспонденция"
                        />

                        {
                            formData.mailLink !== '' ? isValidURL(formData.mailLink) ? (<AppLink target="_blank" theme={AppLinkTheme.DEFAULT} to={node.mailLink}>Перейти по ссылке</AppLink>) : (<Text text="Некорректная ссылка" gap="0" />) : null
                        }

                    </VStack>

                    <VStack align="start" max gap="8">
                        <Input
                            onChange={handleInputChange('cloudLink')}
                            value={formData.cloudLink}
                            placeholder="Укажите ссылку папки"
                            label="Личная папка"
                        />

                        {
                            formData.cloudLink !== '' ? isValidURL(formData.cloudLink) ? (<AppLink target="_blank" theme={AppLinkTheme.DEFAULT} to={node.cloudLink}>Перейти по ссылке</AppLink>) : (<Text text="Некорректная ссылка" gap="0" />) : null
                        }

                    </VStack>

                </VStack>

            );
        }
    }, [formData.audioUrl, formData.cloudLink, formData.fileUrl, formData.mailLink, formData.videoUrl, handleInputChange, node.cloudLink, node.id, node.mailLink, setFormData]);

    return (
        <Modal lazy className={classNames(styles.FileModal, {}, [className])} isOpen={isOpen}>
            <VStack max align="center" gap="16" className={styles.wrapper}>
                <Text gap="0" size={TextSize.XL} title={node.title} />
                <Tabs
                    tabs={tabsProfileModal}
                    theme={ThemeTab.MAIN}
                    value={activeTab}
                    onTabClick={handleChangeTab}
                />

                <VStack max className={styles.content}>
                    <SwitchTransition mode="out-in">
                        <CSSTransition
                            key={activeTab}
                            timeout={300}
                            classNames="fade"
                            unmountOnExit
                        >
                            {renderModalContent(activeTab)}
                        </CSSTransition>
                    </SwitchTransition>
                </VStack>

                <HStack max gap="16" justify="end">
                    <Button onClick={closeModal} theme={ThemeButton.OUTLINE} className={styles.btn}>
                        Назад
                    </Button>
                    <Button onClick={handleSubmitForm} theme={ThemeButton.DEFAULT} className={styles.btn}>
                        Сохранить
                    </Button>

                </HStack>
            </VStack>
        </Modal>
    );
};
