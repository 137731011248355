/* eslint-disable class-methods-use-this */
import axios from 'axios';

export interface IFile {
  url: string;
  name: string;
}

class FileService {
    async uploadFile(file: FormData, folder?: string) {
        return axios.post<IFile[]>('http://localhost:4200/api/files', file, {
            params: {
                folder,
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
}

export const fileService = new FileService();
