import { ITask } from '../../../assets/interfaces/interfaces';
import HoursSvg from '../../../assets/svgs/HoursSvg';
import PlusSolidSvg from '../../../assets/svgs/PlusSolidSvg';

interface IPropsTaskEdit {
    task: ITask,
}
const TaskLogs = ({ task }: IPropsTaskEdit) => {
    require('../../../assets/styles/user/components/edit/task_logs.scss');
    console.log('TaskEditLogs');
    const formatLog = (test_value: string) => test_value.replaceAll('\\n', '\n');
    return (
        <div className="log_tab">
            <div className="logs">
                {task.history.map((log) => (
                    <div className="log">
                        <span className="log_icon">
                            <PlusSolidSvg />
                        </span>
                        <div className="info">
                            <div className="title">
                                <span className="user">
                                    {log.user_id}
                                    {' '}
                                </span>
                                <span className="action">{formatLog(log.action)}</span>
                            </div>
                            <div className="date">
                                <div className="icon">
                                    <HoursSvg />
                                </div>
                                <div className="hours">{log.created_at.split('в')[1]}</div>
                                <div className="day">{log.created_at.split('в')[0]}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TaskLogs;
