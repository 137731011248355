import { useEffect, useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { CSSTransition } from 'react-transition-group';
import { CloseIcon, HospitalIcon, VacationBoxIcon } from '../../../../assets/svgs/orgschema/icons';
import { Avatar } from '../../../../components/ui/Avatar/Avatar';
import { Button, ThemeButton } from '../../../../components/ui/Button/Button';
import styles from './UserCard.module.scss';
import { Text, TextBold, TextSize } from '../../../../components/ui/Text/Text';
import { HStack, VStack } from '../../../../components/ui/Stack';
import { classNames } from '../../../../utils/classNames/classNames';
import { useUsersStore } from '../store/users';
import { UserService } from '../services/Users.service';
import { useSchemasStore } from '../store/schemas';
import { UserStatus } from '../types/orgschema';

interface UserCardProps {
    className?: string;
    onClose: () => void;
}

const UserCard = (props: UserCardProps) => {
    const { className, onClose } = props;

    const currentUser = useUsersStore((state) => state.currentUser);
    const setCurrentUser = useUsersStore((state) => state.setCurrentUser);
    const activeSchemaId = useSchemasStore((state) => state.activeSchemaId);
    const queryClient = useQueryClient();
    const cardRef = useRef<HTMLDivElement>(null);

    const { mutate } = useMutation({
        mutationKey: ['update user status'],
        mutationFn: ({ id, status }: {id: string, status: UserStatus}) => UserService.changeUserStatus(id, status),
        onSuccess: (response) => {
            toast.success('Статус пользователя успешно изменен');
            setCurrentUser(response);

            queryClient.invalidateQueries({ queryKey: ['orgSchema', activeSchemaId] });
        },
        onError(error: any) {
            if (
                error.response
          && error.response.status
          && error.response.data.message
            ) {
                toast.error(error.response.data.message);
            } else {
                toast.error('Ошибка при создании схемы');
            }
        },
    });

    const handleUpdateVacation = () => {
        mutate({ id: currentUser.id, status: currentUser.status === UserStatus.VACATION ? UserStatus.FREE : UserStatus.VACATION });
    };

    const handleUpdateHospital = () => {
        mutate({ id: currentUser.id, status: currentUser.status === UserStatus.HOSPITAL ? UserStatus.FREE : UserStatus.HOSPITAL });
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <div ref={cardRef} className={classNames(styles.UserCard, {}, [className])}>

            <VStack gap="4" align="center" className={styles.avatarWrapper}>

                <Avatar size={60} className={styles.avatar} src={currentUser.avatarPath} />

                <HStack gap="4" align="center" justify="center" max>
                    <Button onClick={handleUpdateVacation} className={classNames(styles.btn, { [styles.vacation]: currentUser.status === UserStatus.VACATION }, [])} theme={ThemeButton.SVG_CLEAN}>
                        <VacationBoxIcon size={20} />
                    </Button>
                    <Button onClick={handleUpdateHospital} className={classNames(styles.btn, { [styles.hospital]: currentUser.status === UserStatus.HOSPITAL }, [])} theme={ThemeButton.SVG_CLEAN}>
                        <HospitalIcon size={20} />
                    </Button>
                </HStack>

            </VStack>

            <VStack gap="16" align="start" className={styles.content}>

                <Text gap="0" size={TextSize.M} isHospital={currentUser.status === UserStatus.HOSPITAL} isVacation={currentUser.status === UserStatus.VACATION} isActive={currentUser.status === UserStatus.FREE} bold={TextBold.BOLD} text={currentUser.fullName} />

                <ul className={styles.list}>
                    <li className={styles.item}>
                        <HStack gap="4" max align="center">
                            <Text gap="0" size={TextSize.XS} textPrimary bold={TextBold.BOLD} text="Должность:" />
                            <Text gap="0" size={TextSize.XS} text={currentUser.position} className={styles.position} />
                        </HStack>
                    </li>
                    <li className={styles.item}>
                        <HStack gap="4" max align="center">
                            <Text gap="0" size={TextSize.XS} textPrimary bold={TextBold.BOLD} text="Почта:" />
                            <Text gap="0" size={TextSize.XS} text={currentUser.email} />
                        </HStack>
                    </li>
                    <li className={styles.item}>
                        <HStack gap="4" max align="center">
                            <Text gap="0" size={TextSize.XS} textPrimary bold={TextBold.BOLD} text="Раб. телефон:" />
                            <Text gap="0" size={TextSize.XS} text={currentUser.workPhone} />
                        </HStack>
                    </li>
                    <li className={styles.item}>
                        <HStack gap="4" max align="center">
                            <Text gap="0" size={TextSize.XS} textPrimary bold={TextBold.BOLD} text="Телефон:" />
                            <Text gap="0" size={TextSize.XS} text={currentUser.phone} />
                        </HStack>
                    </li>
                    <li className={styles.item}>
                        <HStack gap="4" max align="center">
                            <Text gap="0" size={TextSize.XS} textPrimary bold={TextBold.BOLD} text="Дата рождения:" />
                            <Text gap="0" size={TextSize.XS} text={currentUser.birthday} />
                        </HStack>
                    </li>
                    <li className={styles.item}>
                        <HStack gap="4" max align="center">
                            <Text gap="0" size={TextSize.XS} textPrimary bold={TextBold.BOLD} text="Ссылка на комнату ВКС:" />
                            <Text gap="0" size={TextSize.XS} text={currentUser.vksLink} />
                        </HStack>
                    </li>

                </ul>

            </VStack>

            <Button className={styles.closeBtn} onClick={onClose} theme={ThemeButton.SVG_CLEAN}>
                <CloseIcon />
            </Button>
        </div>

    );
};

export default UserCard;
