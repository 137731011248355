/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from 'react';
import AdminTableLayout from './AdminTableLayout';
import { IUser } from '../../../assets/interfaces/interfaces';
import { getUsers } from '../../../http/userAPI';
import AdminUserTableTr from './components/AdminUserTableTr';

interface IProps {
    onEdit: Function
}
const AdminUserTable = (props: IProps) => {
    require('../../../assets/styles/admin/admin_user_table.scss');
    const [users, setUsers] = useState<IUser[]>([
        {
            id: 56,
            name: 'Антон',
            email: '910@transmashpribor.ru',
            email_verified_at: null,
            created_at: '2024-06-14T04:22:55.000000Z',
            updated_at: '2024-06-14T04:22:55.000000Z',
            status: '1',
            login: 'fragmatic',
            avatar: null,
            lastname: 'Семенов',
            middlename: 'Юрьевив',
            position_id: '1',
            birthday: null,
            phones: null,
            job_phones: null,
            gender_id: '1',
            last_visit: null,
            last_ip: null,
            telegram: null,
            vks_link: null,
            vacation: false,
            disease: false,
        },
        {
            id: 55,
            name: 'Татьяна',
            email: '802@transmashpribor.ru',
            email_verified_at: null,
            created_at: '2024-04-12T04:22:55.000000Z',
            updated_at: '2024-06-14T04:22:55.000000Z',
            status: '0',
            login: 'tania',
            avatar: null,
            lastname: 'Тунина',
            middlename: 'Владимировна',
            position_id: '1',
            birthday: '1950-06-20',
            phones: null,
            job_phones: null,
            gender_id: '1',
            last_visit: null,
            last_ip: null,
            telegram: null,
            vks_link: null,
            vacation: false,
            disease: false,
        },
    ]);
    const [showActive, setShowActive] = useState<boolean>(true);
    const getTdText = (rows:HTMLElement, index:number) => rows.getElementsByTagName('td')[index].outerText.toLowerCase();
    const filterColumn = (value: string) => {
        const filter = value.toLowerCase();
        const rows = document.querySelector('#userTable tbody')?.getElementsByTagName('tr');

        if (rows) {
            for (let i = 0; i < rows.length; i++) {
                const idCol = getTdText(rows[i], 0);
                const FIOCol = getTdText(rows[i], 1);
                const loginCol = getTdText(rows[i], 2);
                const emailCol = getTdText(rows[i], 3);
                const groupCol = getTdText(rows[i], 4);
                const positionCol = getTdText(rows[i], 5);
                const birthdayCol = getTdText(rows[i], 6);
                const regCol = getTdText(rows[i], 7);
                const visitCol = getTdText(rows[i], 8);
                const ipCol = getTdText(rows[i], 9);
                const statusCol = getTdText(rows[i], 10);
                if (
                    idCol.search(filter) > -1
                    || FIOCol.search(filter) > -1
                    || loginCol.search(filter) > -1
                    || emailCol.search(filter) > -1
                    || groupCol.search(filter) > -1
                    || positionCol.search(filter) > -1
                    || birthdayCol.search(filter) > -1
                    || regCol.search(filter) > -1
                    || visitCol.search(filter) > -1
                    || ipCol.search(filter) > -1
                    || statusCol.search(filter) > -1) {
                    rows[i].style.display = '';
                } else {
                    rows[i].style.display = 'none';
                }
            }
        }
    };
    useEffect(() => {
        getUsers().then((resp) => {
            console.log(resp);
        });
    }, []);
    return (
        <>
            <div className="filterContainer">
                <input
                    type="text"
                    className="filterInput"
                    onChange={(e) => filterColumn(e.target.value)}
                    placeholder="Фильтр по колонкам"
                />
            </div>

            <AdminTableLayout id="userTable" className="user">
                <tr>
                    <th>#</th>
                    <th>ФИО</th>
                    <th>Логин</th>
                    <th>E-mail адрес</th>
                    <th>Группа</th>
                    <th>Должность</th>
                    <th>Дата рождения</th>
                    <th>Дата регистрации</th>
                    <th>Последний визит</th>
                    <th>Последний ip</th>
                    <th>
                        <div className="status_th">
                            <div>Статус</div>
                            <div className="blue" onClick={() => setShowActive((prev: boolean) => !prev)}>
                                {showActive ? (
                                    <>
                                        <div>Не</div>
                                        <div>активные</div>
                                    </>

                                ) : (
                                    <div>Активные</div>
                                )}
                            </div>
                        </div>
                    </th>
                    <th>Функции</th>
                </tr>
                <tbody>
                    {users.map((user: IUser, index: number) => (
                        <>
                            {user.status === '1' && showActive ? (
                                <AdminUserTableTr
                                    user={user}
                                    index={index}
                                    isActive
                                    onEdit={props.onEdit}
                                />
                            ) : user.status === '0' && !showActive && (
                                <AdminUserTableTr
                                    user={user}
                                    index={index}
                                    isActive={false}
                                    onEdit={props.onEdit}
                                />
                            )}

                        </>

                    ))}

                </tbody>
            </AdminTableLayout>
        </>

    );
};

export default AdminUserTable;
