/* eslint-disable no-nested-ternary */
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { ru } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { addDays } from 'date-fns';
import { IDateRange, ITextActive } from '../../../../../assets/interfaces/interfaces';
import '../../../../../assets/styles/modules/components/module_date_range_element.scss';

interface ITabsProps {
    onApplyChangeDateRange: Function,
    onCancelChangeDateRange: Function,
    onSelectRange: Function,
    selectedRange: IDateRange[];
    daysForSelect: ITextActive[];
    screenWidth?: number;
}

const DateRangePicker = ({
    screenWidth, onApplyChangeDateRange, onCancelChangeDateRange, onSelectRange, selectedRange, daysForSelect,
}: ITabsProps) => {
    const [range, setRange] = useState<IDateRange[]>([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection',
        },
    ]);

    const onChangeRange = (selection: IDateRange) => {
        console.log('selection', selection);
        onSelectRange(6, true);
        setRange([selection]);
    };

    useEffect(() => {
        setRange(selectedRange);
    }, [selectedRange]);
    return (
        <div className="date_range_container">

            <div className={`datepicker ${screenWidth && screenWidth <= 570 ? 'column' : ''}`}>
                <div className="top">
                    <div className="left">
                        <div className="days">
                            {daysForSelect.map((day, index) => (
                                <div
                                    onClick={() => onSelectRange(index, true)}
                                    className={`day ${day.active ? 'active' : ''}`}
                                >
                                    <span className="text">{day.text}</span>
                                </div>

                            ))}
                        </div>
                    </div>
                    <DateRange
                        onChange={(item: any) => onChangeRange(item.selection)}
                        months={2}
                        ranges={range}
                        direction={`${screenWidth ? (screenWidth <= 900 ? 'vertical' : 'horizontal') : 'horizontal'}`}
                        editableDateInputs
                        moveRangeOnFirstSelection={false}
                        preventSnapRefocus
                        calendarFocus="backwards"
                        locale={ru} // Set the locale here
                    />
                </div>

                <div className="bottom">
                    <span
                        className="btn btn_cancel"
                        onClick={() => onCancelChangeDateRange()}
                    >
                        Отменить
                    </span>
                    <span
                        className="btn btn_accept"
                        onClick={() => onApplyChangeDateRange(range)}
                    >
                        Применить
                    </span>
                </div>
            </div>
        </div>

    );
};
export default DateRangePicker;
