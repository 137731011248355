/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import { memo } from 'react';
import styles from './UsersSelectList.module.scss';
import { classNames } from '../../../../../../utils/classNames/classNames';
import { VStack } from '../../../../../../components/ui/Stack';
import { IUser, SchemaData } from '../../../types/orgschema';
import { UsersSelectItem } from './UsersSelectItem/UsersSelectItem';
import { NoUsersContainer } from './UsersSelectItem/NoUsersContainer';
import { SkeletonUsersSelectItem } from './UsersSelectItem/SkeletonUsersSelectItem';
import { useUsersStore } from '../../../store/users';

interface UsersSelectListProps {
  className?: string;
  users?: IUser[];
  node: SchemaData;
  isLoading: boolean;
}

const getUserSkeletons = () => new Array(5).fill(0).map((item, index) => <SkeletonUsersSelectItem key={index} />);

export const UsersSelectList = memo((props: UsersSelectListProps) => {
    const {
        className, users, isLoading, node,
    } = props;

    const addUser = useUsersStore((state) => state.addUser);
    const nodeUsersIds = new Set(node.employees.map((user) => user.id));

    if (isLoading) {
        return (
            <VStack gap="16" align="start" max className={classNames(styles.UsersSelectList, {}, [className])}>
                {getUserSkeletons()}
            </VStack>
        );
    }

    if (!users || users.length === 0) {
        return (
            <NoUsersContainer />
        );
    }

    return (
        <VStack gap="16" align="start" max className={classNames(styles.UsersSelectList, {}, [className])}>
            {users.map((user) => {
                if (nodeUsersIds.has(user.id)) {
                    addUser(user);
                }

                return (
                    <UsersSelectItem key={user.id} user={user} />
                );
            })}
        </VStack>
    );
});
