/* eslint-disable import/no-dynamic-require */
import { IUser } from '../../../../assets/interfaces/interfaces';
import CloseCircleSvg from '../../../../assets/svgs/CloseCircleSvg';
import PencilSvg from '../../../../assets/svgs/PencilSvg';
import UserAvatarDefault from '../../../../assets/svgs/UserAvatarDefault';

interface IProps {
    user: IUser,
    index: number,
    isActive: boolean,
    onEdit: Function
}
const AdminUserTableTr = ({
    user, index, isActive, onEdit,
}: IProps) => {
    const getDate = (created_at: string) => {
        const dateObj = new Date(created_at);
        const getDay = String(dateObj.getUTCDate());
        const day = getDay.length === 1 ? `0${getDay}` : getDay;
        const getMonth = String(dateObj.getUTCMonth() + 1);
        const month = getMonth.length === 1 ? `0${getMonth}` : getMonth;
        const year = String(dateObj.getUTCFullYear()).slice(2, 4);
        const getHours = String(dateObj.getHours());
        const hours = getHours.length === 1 ? `0${getHours}` : getHours;
        const minutes = dateObj.getMinutes();
        const date = `${day}.${month}.${year}г в ${hours}:${minutes}`;
        return date;
    };
    return (
        <tr key={index}>
            <td>{++index}</td>
            <td>
                <div className="FIO">
                    {user.avatar ? (
                        <div
                            className="avatar"
                            style={{
                                backgroundImage: `url(${require(user.avatar)})`,
                            }}
                        />
                    ) : (
                        <div className="avatar">
                            <UserAvatarDefault />
                        </div>
                    )}

                    <div className="name">
                        {user.lastname}
                        {' '}
                        {user.name}
                        {' '}
                        {user.middlename}
                    </div>
                </div>
            </td>
            <td>{user.login}</td>
            <td>{user.email}</td>
            <td>{user.status === '0' ? 'Разработчик' : 'Не указано'}</td>
            <td>{user.position_id === 1 ? 'Руководитель IT отделения' : 'Не указано'}</td>
            <td>{user.birthday ? user.birthday : 'Не указано'}</td>
            <td>{user.created_at ? getDate(user.created_at) : 'Не указано'}</td>
            <td>{user.last_visit ? getDate(user.last_visit) : 'Не указано'}</td>
            <td>{user.last_ip ? user.last_ip : 'Не указано'}</td>
            <td>
                <div className={`active ${isActive ? '' : 'no'}`}>{isActive ? 'Актив' : 'Не актив'}</div>
            </td>
            <td>
                <div className="icons">
                    <div className="btn edit" onClick={() => onEdit(user)}>
                        <PencilSvg />
                    </div>
                    <div className="btn delete">
                        <CloseCircleSvg />
                    </div>
                </div>

            </td>
        </tr>

    );
};

export default AdminUserTableTr;
