import '../../../../../assets/styles/modules/components/doc/input/doc_cross_circle.scss';

interface IProps {
    onClick: Function;
}
const InputClose = ({ onClick }: IProps) => (
    <div className="close_circle_btn" onClick={() => onClick()}>&#10006;</div>
);

export default InputClose;
