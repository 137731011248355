import { $task } from '..';
import { getCookie } from '../../utils/cookies';
import { sendRepeatRequest } from '../userAPI';

const taskHeader = {
    headers: {
        Authorization: `Bearer ${getCookie('access_token')}`,
    },
};

export const addTaskAPI = async (name: string, user_id: string, repeat?: boolean) => {
    const response = await $task.post('tasks', { name, user_id }, taskHeader).catch((e) => e);
    return sendRepeatRequest(response, { name, user_id });
};
export const getTasksAPI = async () => {
    const response = await $task.get('tasks', taskHeader).catch((e) => e);
    return sendRepeatRequest(response);
};
export const getArchivedTasksAPI = async () => {
    const response = await $task.get('tasks/archived', taskHeader).catch((e) => e);
    return sendRepeatRequest(response);
};
export const getTaskAPI = async (id: number) => {
    const response = await $task.get(`tasks/${id}`, taskHeader).catch((e) => e);
    return sendRepeatRequest(response, id);
};
export const editTaskAPI = async (editedData: FormData) => {
    editedData?.append('_method', 'PATCH');
    const response = await $task.post(`tasks/${editedData.get('id')}`, editedData, taskHeader).catch((e) => e);
    return response;
};
export const deleteTaskAPI = async (task_id: number) => {
    const response = await $task.delete(`tasks/${task_id}`, taskHeader).catch((e) => e);
    return sendRepeatRequest(response);
};
export const addCheckboxesChecklistAPI = async (task_id:number, checklist_id:number, name:string) => {
    const response = await $task.post(`tasks/${task_id}/checklists/${checklist_id}/checkboxes`, { checklist_id, name }, taskHeader).catch((e) => e);
    return sendRepeatRequest(response);
};
export const updateCheckboxChecklistAPI = async (task_id:number, checklist_id: number, checkbox_id:number, complete:number, name: string) => {
    const response = await $task.post(`tasks/${task_id}/checklists/${checklist_id}/checkboxes/${checkbox_id}`, { _method: 'PATCH', complete, name }, taskHeader).catch((e) => e);
    return sendRepeatRequest(response);
};
export const deleteCheckboxAPI = async (task_id:number, checklist_id: number, checkbox_id:number) => {
    const response = await $task.delete(`tasks/${task_id}/checklists/${checklist_id}/checkboxes/${checkbox_id} `, taskHeader).catch((e) => e);
    return sendRepeatRequest(response);
};
export const addChecklistAPI = async (name: string, task_id:number) => {
    const response = await $task.post(`tasks/${task_id}/checklists`, { name, task_id }, taskHeader).catch((e) => e);
    return sendRepeatRequest(response);
};
export const updateChecklistAPI = async (task_id:number, checklist_id: number, name: string) => {
    const response = await $task.post(`tasks/${task_id}/checklists/${checklist_id}`, { _method: 'PATCH', name }, taskHeader).catch((e) => e);
    return sendRepeatRequest(response);
};
export const deleteChecklistAPI = async (task_id:number, checklist_id: number) => {
    const response = await $task.delete(`tasks/${task_id}/checklists/${checklist_id}`, taskHeader).catch((e) => e);
    return sendRepeatRequest(response);
};
export const deleteFileTaskAPI = async (task_id: number, file_id: number, repeat?: boolean) => {
    const response = await $task.delete(`tasks/${task_id}/files/${file_id}`, taskHeader).catch((e) => e);
    return sendRepeatRequest(response, { task_id, file_id });
};
export const addCommentTaskAPI = async (task_id:number, editedData: FormData) => {
    const response = await $task.post(`tasks/${task_id}/comments`, editedData, taskHeader).catch((e) => e);
    return sendRepeatRequest(response, editedData);
};
export const getCommentsTaskAPI = async (task_id:number) => {
    const response = await $task.get(`tasks/${task_id}/comments`, taskHeader).catch((e) => e);
    return sendRepeatRequest(response);
};
export const updateCommentTaskAPI = async (editedData: FormData, task_id:number, comment_id:number) => {
    editedData?.append('_method', 'PATCH');
    const response = await $task.post(`tasks/${task_id}/comments/${comment_id}`, editedData, taskHeader).catch((e) => e);
    return sendRepeatRequest(response, editedData);
};
export const deleteCommentTaskAPI = async (task_id:number, comment_id:number) => {
    const response = await $task.delete(`tasks/${task_id}/comments/${comment_id}`, taskHeader).catch((e) => e);
    return sendRepeatRequest(response);
};
