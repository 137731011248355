import TabelPagination from './TabelPagination';
import { IDocTableHeader } from '../../../../../assets/interfaces/modules/doc_interface';
import '../../../../../assets/styles/modules/components/doc/tabel/module_table_doc.scss';

interface IProps {
    headers: IDocTableHeader[]
    onChangePage: Function;
    currentPage: number;
    pageNumbers: Array<number>;
    children: any;
}
const TabelDocModule = ({
    headers, pageNumbers, onChangePage, currentPage, children,
}: IProps) => {
    const onClickPagination = (page: number) => {
        if (page < (pageNumbers.length + 1) && page > 0) {
            onChangePage(page);
        }
    };

    return (
        <div className="module_table_doc">
            <div className="table_responsive">
                <table className="table">
                    <thead className="thead">
                        <tr>
                            {headers.map((th, index) => (
                                <th className={`th ${th.class}`}>{th.text}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="tbody">
                        {children}
                    </tbody>
                </table>
            </div>
            <div
                className="table_pagination"
            >
                <TabelPagination
                    currentPage={currentPage}
                    pageNumbers={pageNumbers}
                    onClick={onClickPagination}
                />
            </div>

        </div>
    );
};
export default TabelDocModule;
