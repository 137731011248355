/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
interface ICustomErrors {
    valid: boolean, error: string

}
interface IProps {
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    onBlur: React.FocusEventHandler<HTMLInputElement>,
    type: string
    htmlFor: string
    errors: any
    label: string
    value: string,
    isValid?: boolean,
    className?: string,
    customErrors?: ICustomErrors[],
}
const InputLabelField = (props: IProps) => {
    require('../../assets/styles/auth/input_label_field.scss');
    return (
        <>
            <label className="auth_label" htmlFor={props.htmlFor}>{props.label}</label>
            <input
                type={props.type}
                className="auth_input"
                id={props.htmlFor}
                name={props.htmlFor}
                onBlur={props.onBlur}
                onChange={props.onChange}
                value={props.value}
                placeholder={props.label}
            />
            {props.errors && (props.errors[props.htmlFor] && (props.value.length === 0)) && (
                <div className="auth_error">
                    Поле
                    {props.label}
                    {' '}
                    обязательно для заполнения.
                </div>
            )}
            {props.errors && (props.errors[props.htmlFor] && (props.value.length > 0)) && (
                <div className="auth_error">{props.errors[props.htmlFor]}</div>
            )}
            {props.isValid && (
                <div className="error">
                    Поле
                    {props.label}
                    {' '}
                    не верно указано
                </div>
            )}
            {props.customErrors?.map((error: ICustomErrors) => (
                <>
                    {
                        error.valid && (
                            <div className="error">{error.error}</div>

                        )
                    }
                </>

            ))}
        </>

    );
};

export default InputLabelField;
