import { Button, ThemeButton } from '../../../../../components/ui/Button/Button';
import { Modal } from '../../../../../components/ui/Modal/Modal';
import { HStack, VStack } from '../../../../../components/ui/Stack';
import {
    Text, TextAlign, TextBold, TextSize,
} from '../../../../../components/ui/Text/Text';
import { classNames } from '../../../../../utils/classNames/classNames';
import styles from './SubmitActionModal.module.scss';

interface SubmitActionModalProps {
  className?: string;
  isOpen: boolean;
  title: string;
  description?: string;
  onAction: () => void;
  onClose: () => void;
}

export const SubmitActionModal = ({
    className, isOpen, onClose, onAction, title, description,
} : SubmitActionModalProps) => (
    <Modal lazy className={classNames(styles.SubmitActionModal, {}, [className])} isOpen={isOpen} onClose={onClose}>
        <VStack max align="center" gap="32">
            <Text gap="16" size={TextSize.XL} bold={TextBold.BOLD} textPrimary title={title} />
            {description && <Text gap="16" size={TextSize.L} align={TextAlign.CENTER} text={description} />}

            <HStack max gap="32">
                <Button className={styles.btn} theme={ThemeButton.DEFAULT} onClick={onAction}>Подтвердить</Button>
                <Button className={styles.btn} theme={ThemeButton.OUTLINE} onClick={onClose}>Отмена</Button>
            </HStack>

        </VStack>
    </Modal>
);
