import { memo, useCallback, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import InputColor, { Color, InputColorProps } from 'react-input-color';
import styles from './UpdateSchemaForm.module.scss';
import { classNames } from '../../../../../../utils/classNames/classNames';
import { HStack, VStack } from '../../../../../../components/ui/Stack';
import { CreateSchemaData, SchemaData } from '../../../types/orgschema';
import { Input } from '../../../../../../components/ui/Input/Input';
import { Button, ThemeButton } from '../../../../../../components/ui/Button/Button';
import { OrgSChemaService } from '../../../services/OrgSchema.service';
import { useSchemasStore } from '../../../store/schemas';
import { Text, TextBold, TextSize } from '../../../../../../components/ui/Text/Text';
import { Checkbox } from '../../../../../../components/ui/Checkbox/Checkbox';

interface UpdateSchemaFormProps {
  className?: string;
  onSuccess: () => void;
  node: SchemaData;
}

export const UpdateSchemaForm = memo((props: UpdateSchemaFormProps) => {
    const { className, onSuccess, node } = props;

    const queryClient = useQueryClient();

    const [title, setTitle] = useState(node.title);
    const [description, setDescription] = useState(node.description);
    const [tskp, setTskp] = useState(node.tskp);
    const [checked, setChecked] = useState(true);

    const [color, setColor] = useState<Color>();

    const activeSchemaId = useSchemasStore((state) => state.activeSchemaId);

    const onChangeTitle = (value: string) => {
        setTitle(value);
    };

    const handleChangeCheckBox = useCallback(() => {
        setChecked(!checked);
    }, [checked]);

    const onChangeDescription = (value: string) => {
        setDescription(value);
    };

    const onChangeTskp = (value: string) => {
        setTskp(value);
    };

    const { mutate } = useMutation({
        mutationKey: ['update orgSchema'],
        mutationFn: ({ id, data, type }: {id: string, data: CreateSchemaData, type: 'schemas' | 'blocks'}) => OrgSChemaService.updateSchema(id, data, type),
        onSuccess: () => {
            toast.success('Схема успешно обновлена');

            queryClient.invalidateQueries({
                queryKey: ['allOrgSchemaTree'],
            });

            queryClient.invalidateQueries({
                queryKey: ['orgSchema', activeSchemaId],
            });
        },
        onError(error: any) {
            if (
                error.response
        && error.response.status
        && error.response.data.message
            ) {
                toast.error(error.response.data.message);
            } else {
                toast.error('Ошибка при обновлении схемы');
            }
        },
    });

    const handleUpdateSchema = () => {
        const data = {
            id: node.id,
            data: {
                title,
                description,
                tskp,
                color: color && !checked ? color.hex : '',
            },
            type: node.type,
        };

        mutate(data);
        onSuccess();
    };

    return (
        <VStack max gap="32" align="start" className={classNames(styles.UpdateSchemaForm, {}, [className])}>

            <HStack max gap="16">
                <Text gap="0" bold={TextBold.BOLD} size={TextSize.L} textPrimary text="Выберите цвет для блока: " />
                <InputColor
                    initialValue="#5e72e4"
                    onChange={setColor}
                    placement="right"
                />
            </HStack>

            <Input onChange={onChangeTitle} value={title} className={styles.input} placeholder="Введите название схемы" label="Название" />

            <Input onChange={onChangeDescription} value={description} className={styles.input} placeholder="Введите описание схемы" label="Описание" />

            <Input onChange={onChangeTskp} value={tskp} className={styles.input} placeholder="Введите цкп" label="Цкп" />

            <HStack max gap="32" justify="between" align="center">
                <Checkbox onToggle={handleChangeCheckBox} checked={checked} id="update-color-checkbox" label="Оставить цвет пустым?" />

                <HStack justify="end" gap="16" align="center">
                    <Button onClick={onSuccess} theme={ThemeButton.OUTLINE}>Закрыть</Button>
                    <Button onClick={handleUpdateSchema} theme={ThemeButton.DEFAULT}>Сохранить</Button>
                </HStack>
            </HStack>

        </VStack>
    );
});
