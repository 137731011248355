/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react';

import { SelectArrowSvg } from '../../../../../assets/svgs/SelectArrowSvg';
import '../../../../../assets/styles/modules/components/doc/input/doc_input_select.scss';
import { IDocInputSections } from '../../../../../assets/interfaces/modules/doc_interface';

interface IProps {
    title: string;
    inputSections: IDocInputSections[];
    onChangeInputSection: Function;
    onClickSelect: Function;
    otherSelectClicked: boolean;
}
const InputSelect = ({
    inputSections, otherSelectClicked, title, onClickSelect, onChangeInputSection,
}: IProps) => {
    const [inputShowSection, setInputShowSection] = useState<boolean>(false);

    const getCurrentSection = () => {
        let currentSection = '';
        inputSections.map((val) => {
            if (val.active) {
                currentSection = val.text;
            }
        });
        return currentSection;
    };
    const onOpenSelect = () => {
        onClickSelect();
        setTimeout(() => {
            setInputShowSection(!inputShowSection);
        }, 1);
    };

    useEffect(() => {
        setInputShowSection(false);
    }, [otherSelectClicked]);
    return (
        <div
            className="doc_input_select"
        // onClick={() => setInputShowSection(!inputShowSection)}
        >
            <div
                className={`input_group ${inputShowSection ? 'active' : ''}`}
                // onMouseLeave={() => setInputShowSection(false)}
                // onMouseEnter={() => setInputShowSection(true)}
                onClick={() => onOpenSelect()}
            >
                <div className="left">
                    <div className="input_group__title">{title}</div>
                    <div className="input_group__group">{getCurrentSection()}</div>
                </div>
                <div className="right">
                    <span className="icon">
                        <SelectArrowSvg />
                    </span>
                </div>

            </div>
            <div
                className={`sections ${inputShowSection ? 'active' : ''}`}
            >
                {inputSections.map((section, index) => (
                    <div
                        onClick={() => onChangeInputSection(index)}
                        className={`section ${section.active ? 'active' : ''}`}
                    >
                        <span className="text">{section.text}</span>
                        <span className="text">{section.count}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default InputSelect;
