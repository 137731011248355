import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
    HELLO_ROUTE, LOGIN_ROUTE, REGISTER_ROUTE, STARTER_ROUTE,
} from '../utils/consts';
import LogoSvg from '../assets/svgs/LogoSvg';
import { getCookie } from '../utils/cookies';
import Header from '../components/Header';

const Hello = () => {
    require('../assets/styles/hello.scss');
    const navigate = useNavigate();
    useEffect(() => {
        const path = window.location.pathname;
        const token = getCookie('access_token');
        if (path === '/' && token) {
            setTimeout(() => {
                navigate(STARTER_ROUTE);
            }, 10);
        } else {
            navigate(HELLO_ROUTE);
        }
    }, []);
    return (
        <div className="hello_container">
            <Header />
            <div className="main">
                <div className="main__left_block">
                    <div className="main__content">
                        <div className="main__title">Документация</div>
                        <div className="main__desc">Документация к приложению представляет собой набор инструкций и рекомендаций, которые помогут пользователям понять, как использовать приложение наиболее эффективно. В документации описаны основные функции приложения, способы его установки и настройки, а также ответы на часто задаваемые вопросы.</div>
                    </div>

                    <div className="main__img" />
                </div>
                <div className="main__right_top_block">
                    <div className="main__title">Новости</div>
                    <div className="main__desc">Раздел &quot;новости компании&quot; предназначен для публикации последних событий, обновлений и достижений бизнеса. Здесь вы найдете информацию о новых продуктах и услугах, успешных проектах, участи в выставках и конференциях, а также другие интересные события, связанные с деятельностью компании.</div>
                </div>
                <div className="main__right_bottom_block">
                    <div className="main__title">Тех.Поддержка</div>
                    <div className="main__desc">Предоставляет информацию и помощь пользователям в решении возникших проблем с программным и аппаратным обеспечением, а также с другими техническими вопросами. Здесь вы можете найти советы по устранению ошибок, инструкции по настройке устройств и программ, а также контактные данные специалистов, готовых помочь в случае необходимости.</div>
                </div>
            </div>
        </div>

    );
};

export default Hello;
