/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import styles from './UserItem.module.scss';
import { Avatar } from '../../../../../components/ui/Avatar/Avatar';
import { Text, TextBold, TextSize } from '../../../../../components/ui/Text/Text';
import { UserInfoModal } from '../../components/UserInfoModal/UserInfoModal';
import { IUser, UserStatus } from '../../types/orgschema';
import { currentUser } from '../../data/data';
import { classNames, Mods } from '../../../../../utils/classNames/classNames';

export interface UserItemProps {
  user: IUser;
  openUserInfo: (user: IUser) => void;
}

const UserItem = (props: UserItemProps) => {
    const {
        user, openUserInfo,
    } = props;

    const mods: Mods = {
        [styles.vacation]: user.status === UserStatus.VACATION,
        [styles.hospital]: user.status === UserStatus.HOSPITAL,
    };

    return (
        <li
            onClick={() => openUserInfo(user)}
            className={classNames(styles.UserItem, mods, [])}
        >
            <Avatar className={styles.avatar} size={30} src={user.avatarPath} />
            <Text className={styles.text} gap="16" size={TextSize.S} bold={TextBold.BOLD} isHospital={user.status === UserStatus.HOSPITAL} isVacation={user.status === UserStatus.VACATION} isActive={user.status === UserStatus.FREE} text={user.fullName} />
            {
                user.fullName === currentUser && (
                    <Text className={styles.you} textPrimary size={TextSize.XS} gap="0" bold={TextBold.BOLD} text="Это вы" />
                )
            }
        </li>
    );
};

export default UserItem;
