/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { IVideoElement } from './video.interface';

export const useVideo = () => {
    const videoRef = useRef<IVideoElement>(null);

    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [videoTime, setVideoTime] = useState(0);
    const [progress, setProgress] = useState(0);
    const [volume, setVolume] = useState(1); // Уровень громкости (от 0 до 1)
    const [isMuted, setIsMuted] = useState(false); // Состояние без звука

    useEffect(() => {
        if (videoRef.current?.duration) setVideoTime(videoRef.current.duration);
    }, [videoRef.current?.duration]);

    const toggleVideo = useCallback(
        () => {
            if (!isPlaying) {
                videoRef.current?.play();
                setIsPlaying(true);
            } else {
                videoRef.current?.pause();
                setIsPlaying(false);
            }
        },
        [isPlaying],
    );

    const fastForward = (value: number) => {
        if (videoRef.current) videoRef.current.currentTime += value;
    };

    const revert = (value: number) => {
        if (videoRef.current) videoRef.current.currentTime -= value;
    };

    const fullScreen = () => {
        const video = videoRef.current;

        if (!video) return;

        if (video.requestFullscreen) {
            video.requestFullscreen();
        } else if (video.msRequestFullScreen) {
            video.msRequestFullScreen();
        } else if (video.mozRequestFullScreen) {
            video.mozRequestFullScreen();
        } else if (video.webkitRequestFullScreen) {
            video.webkitRequestFullScreen();
        }
    };

    const updateVolume = (newVolume: number) => {
        if (videoRef.current) {
            videoRef.current.volume = newVolume;
            setVolume(newVolume);
        }
    };

    const toggleMute = () => {
        if (videoRef.current) {
            videoRef.current.muted = !isMuted;
            setIsMuted((prev) => !prev);

            if (isMuted) {
                updateVolume(volume);
            } else {
                updateVolume(0);
            }
        }
    };

    useEffect(() => {
        const video = videoRef.current;

        if (!video) return;

        const updateProgress = () => {
            setCurrentTime(video.currentTime);
            setProgress((video.currentTime / videoTime) * 100);
        };

        video.addEventListener('timeupdate', updateProgress);

        return () => {
            video.removeEventListener('timeupdate', updateProgress);
        };
    }, [videoTime]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            switch (event.key) {
            case 'ArrowRight':
                fastForward(5);
                break;

            case 'ArrowLeft':
                revert(5);
                break;

            case 'p':
                event.preventDefault();
                toggleVideo();
                break;

            case 'f':
                fullScreen();
                break;

            case 'm':
                toggleMute();
                break;

            default:
                break;
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [toggleMute, toggleVideo]);

    const value = useMemo(() => ({
        videoRef,
        actions: {
            fullScreen,
            revert,
            fastForward,
            toggleVideo,
            updateVolume,
            toggleMute,
        },
        video: {
            isPlaying,
            currentTime,
            progress,
            videoTime,
            volume,
            isMuted,
        },
    }), [currentTime, isMuted, isPlaying, progress, toggleMute, toggleVideo, videoTime, volume]);

    return value;
};
