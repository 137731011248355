export interface TmOrgBoard {
  id: number;
  idOrg: number;
  title: string;
  post: string;
  tskp: string;
  parent: number;
  colorBlock: string;
  colorBackground: string;
  sort: string;
  isTogether: boolean;
  file: string;
  mailLink: string;
  cloudLink: string;
}

export interface TestData {
  id: number;
  title: string;
  description: string;
  parent: {
    id: number
  } | null;
  children: TestData[];
}

export interface CreateSchemaData {
  title: string;
  description: string;
  tskp: string;
  color?: string;
}

export enum UserStatus {
  FREE = 'FREE',
  HOSPITAL = 'HOSPITAL',
  VACATION = 'VACATION',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
  DIRECTOR = 'DIRECTOR',
}

export interface IUser {
  id: string;
  fullName: string;
  email: string;
  position: string;
  workPhone: string;
  phone: string;
  birthday: string;
  vksLink: string;
  avatarPath: string;
  status: UserStatus;
  updatedAt: string;
  role: UserRole;
}

export interface SchemaData {
  id: string;
  title: string;
  description: string;
  tskp: string;
  type: 'schemas' | 'blocks';
  employees: IUser[];
  children: SchemaData[];
  color: string;
  isChildrenTogether: boolean;
  isSchemaHidden: boolean;
  audioUrl: string;
  videoUrl: string;
  fileUrl: string;
  mailLink: string;
  cloudLink: string;
}
