/* eslint-disable react/jsx-no-bind */
import { useEffect, useState } from 'react';

import { onShowFileSlider } from '../../../../../utils/func';
import TabelDocModule from '../tabel/TabelDocModule';
import DocHeader from '../elements/DocHeader';
import DocModals from '../modals/DocModals';
import DocIncomingTabelTr from '../tabel/DocIncomingTabelTr';
import '../../../../../assets/styles/modules/components/doc/doc_layout_tab.scss';
import {
    IDocSendData, IDocTable, IDocTableData, IDocTableHeader,
} from '../../../../../assets/interfaces/modules/doc_interface';
import {
    IActions, IMultySelectSearch, IOpenFile, ITaskFile, ITextActive,
} from '../../../../../assets/interfaces/interfaces';

interface IDoneDocData {
    situation: string;
    data: string;
    solution: string;
}

interface IAddModalResponse {
    theme: string;
    dropDownFiles: File[];
    actions: IActions[];
    doneDocData: IDoneDocData;
    sendDocData: IDocSendData;
}
interface ISaveDetails {
    td: IDocTableData;
    files: File[];
    actions: IActions[];
}
interface IProps {
    tabelHeaders: IDocTableHeader[];
    tableData: IDocTable;
    title: string;
    onChangeTablePage: Function;
    onChangeStatus?: Function;
    onCloseTab: Function;
    isArchive?: boolean;
}

const DocLayoutTab = ({
    tabelHeaders, onChangeStatus, onChangeTablePage, tableData, isArchive, title, onCloseTab,
}: IProps) => {
    const [data, setData] = useState<IDocTableData[]>([]);

    const [openFiles, setOpenFiles] = useState<IOpenFile[]>([]);
    const [sureDenyAgreementModal, setSureDenyAgreementModal] = useState<ITextActive>({ active: false, text: '' });
    const [sureDeleteModal, setSureDeleteModal] = useState<ITextActive>({ active: false, text: '' });
    const [sureArchiveModal, setSureArchiveModal] = useState<ITextActive>({ active: false, text: '' });
    const [selectUsersModal, setSelectUsersModal] = useState<boolean>(false);

    const [showAdd, setShowAdd] = useState<boolean>(false);

    const [detailsTabs, setDetailsTabs] = useState<ITextActive[]>([
        { text: 'Информация', active: true }, { text: 'Комментарии', active: false },
    ]);
    const [openDetails, setOpenDetails] = useState<IDocTableData | undefined>();

    const [pageNumbers, setPageNumbers] = useState<Array<number>>([]);
    const [inputTabelSearch, setInputTabelSearch] = useState<string>('');

    const onConfirmNoDelete = () => {
        setSureDeleteModal({ active: false, text: '' });
    };

    const onConfirmYesDelete = () => {
        onConfirmNoDelete();
    };

    const onCloseModal = () => {
        setOpenDetails(undefined);
    };
    const onSaveDetails = (response: ISaveDetails) => {
        const newData = [...data];
        const index = newData.indexOf(response.td);
        newData[index].details.status.success = response.actions[0].active;
        newData[index].details.status.cancel = response.actions[1].active;
        setData(newData);
        console.log('index', index);
        console.log('response', response);
    };
    const onOpenAddDocument = (open: boolean) => {
        setShowAdd(open);
    };
    const openTab = (index: number) => {
        const newTabs = [...detailsTabs];
        newTabs.forEach((val) => val.active = false);
        newTabs[index].active = true;
        setDetailsTabs(newTabs);
    };
    const onOpenDetails = (data: IDocTableData) => {
        console.log('data', data);
        setOpenDetails(data);
    };

    const onDeleteFileDetails = (file: ITaskFile, index: number) => {

    };

    const onConfirmNoArchive = () => {
        setSureArchiveModal({ active: false, text: '' });
    };
    const onConfirmYesArchive = () => {
        onConfirmNoArchive();
    };

    const onDeleteDoc = (td: IDocTableData) => {
        const text = `Действительно хотите удалить "${td.name}"?`;
        setSureDeleteModal({ active: true, text });
    };
    const onAddToArchive = (td: IDocTableData) => {
        const text = `Действительно хотите добавить "${td.name}" в архив?`;
        setSureArchiveModal({ active: true, text });
    };
    const onDenyAgreement = (td: IDocTableData) => {
        setSureDenyAgreementModal({ active: true, text: '' });
    };

    const onConfirmNoDenyAgreement = () => {
        setSureDenyAgreementModal({ active: false, text: '' });
    };
    const onConfirmYesDenyAgreement = () => {
        onConfirmNoDenyAgreement();
    };

    const onShowFileHandler = (files: ITaskFile[]) => {
        setOpenFiles([]);
        setTimeout(() => {
            setOpenFiles(onShowFileSlider(files[0].task_id, files, files[0], 0));
        }, 1);
    };

    const onShowDetail = (index: number) => {
        const newData = [...data];
        data[index].details.show = true;
        setData(newData);
    };
    const onHideDetail = (index: number) => {
        const newData = [...data];
        data[index].details.show = false;
        setData(newData);
    };
    const onShowIcons = (index: number, over: boolean) => {
        const newData = [...data];
        newData.forEach((val) => val.showDo = false);
        newData[index].showDo = over;
        setData(newData);
    };
    const onChangeOpenFiles = (value: IOpenFile[]) => {
        setOpenFiles(value);
    };

    const onCloseAddModal = () => {
        onOpenAddDocument(false);
    };
    function onSaveAddModal(response: IAddModalResponse) {
        console.log('response', response);
        onOpenAddDocument(false);
    }
    const onChangeComment = (index: number, comment_text: string) => {
        const newData = [...data];
        newData[index].details.comment = comment_text;
        setData(newData);
    };
    const onChangeinputSearch = (text: string) => {
        setInputTabelSearch(text);
    };
    const onOpenTask = () => {
        console.log('onOpenTask');
    };
    const onOpenTdSelectUser = (td: IDocTableData) => {
        setSelectUsersModal(true);
        console.log('td', td);
    };
    const onCloseSelectUserModal = () => {
        setSelectUsersModal(false);
    };
    const onSaveSelectUserModal = (users: IMultySelectSearch[]) => {
        onCloseSelectUserModal();
    };
    function onChangeDenyAgreement(text: string) {
        setSureDenyAgreementModal({ active: true, text });
    }
    function getFilteredTableElements() {
        // note that I've incorporated the searchedVal length check here
        return data.filter((row) => !inputTabelSearch.length || row.name
            .toString()
            .toLowerCase()
            .replace(/\s/g, '')
            .includes(inputTabelSearch.toString().toLowerCase().replace(/\s/g, '')));
    }
    useEffect(() => {
        const indexOfLastTodo = tableData.currentPage * tableData.todosPerPage;
        const indexOfFirstTodo = indexOfLastTodo - tableData.todosPerPage;
        setData(tableData.data.slice(indexOfFirstTodo, indexOfLastTodo));

        const pages = [];
        for (let i = 1; i <= Math.ceil(tableData.data.length / tableData.todosPerPage); i++) {
            pages.push(i);
        }
        setPageNumbers(pages);
    }, [tableData.currentPage]);

    return (
        <>
            <DocModals
                detailsTabs={detailsTabs}
                onConfirmNoArchive={onConfirmNoArchive}
                onConfirmNoDelete={onConfirmNoDelete}
                onConfirmNoDenyAgreement={onConfirmNoDenyAgreement}
                onConfirmYesArchive={onConfirmYesArchive}
                onConfirmYesDelete={onConfirmYesDelete}
                onConfirmYesDenyAgreement={onConfirmYesDenyAgreement}
                openDetails={openDetails}
                openFiles={openFiles}
                showAdd={showAdd}
                sureArchiveModal={sureArchiveModal}
                sureDeleteModal={sureDeleteModal}
                onChangeOpenFiles={onChangeOpenFiles}
                onCloseAddModal={onCloseAddModal}
                onSaveAddModal={onSaveAddModal}
                onCloseDetailModal={onCloseModal}
                onClickDetailsTabModal={openTab}
                onSaveDetails={onSaveDetails}
                onDeleteFileDetails={onDeleteFileDetails}
                selectUserModal={selectUsersModal}
                onCloseSelectUserModal={onCloseSelectUserModal}
                sureDenyAgreementModal={sureDenyAgreementModal}
                onChangeDenyAgreement={onChangeDenyAgreement}
                onSaveSelectUserModal={onSaveSelectUserModal}
            />

            <DocHeader
                title={title}
                inputSearch={inputTabelSearch}
                onShowAddModal={onOpenAddDocument}
                onInputSearchChange={onChangeinputSearch}
                onCloseTab={onCloseTab}
            />
            <div className="docmodule_body">
                <TabelDocModule
                    headers={tabelHeaders}
                    onChangePage={onChangeTablePage}
                    currentPage={tableData.currentPage}
                    pageNumbers={pageNumbers}
                >
                    {getFilteredTableElements().length
                        ? getFilteredTableElements().map((td, index) => (
                            <DocIncomingTabelTr
                                td={td}
                                index={index}
                                onAddToArchive={onAddToArchive}
                                onDeleteDoc={onDeleteDoc}
                                onDenyAgreement={onDenyAgreement}
                                onHideDetail={onHideDetail}
                                onOpenDetails={onOpenDetails}
                                onOpenTask={onOpenTask}
                                onShowDetail={onShowDetail}
                                onShowFileHandler={onShowFileHandler}
                                onShowIcons={onShowIcons}
                                isArchive={!!isArchive}
                                onChangeComment={onChangeComment}
                                onOpenSelectUser={onOpenTdSelectUser}
                                onChangeStatus={onChangeStatus}
                            />
                        )) : (
                            <div className="table_empty">Пусто</div>
                        )}
                </TabelDocModule>
            </div>
        </>
    );
};

export default DocLayoutTab;
