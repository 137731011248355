import { Modal } from '../../../../../components/ui/Modal/Modal';
import { VStack } from '../../../../../components/ui/Stack';
import { Text, TextAlign, TextSize } from '../../../../../components/ui/Text/Text';
import { classNames } from '../../../../../utils/classNames/classNames';
import { SchemaData } from '../../types/orgschema';
import styles from './SignUserModal.module.scss';
import { UsersForm } from './UsersForm';

interface SignUserModalProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  node: SchemaData;
}

export const SignUserModal = ({
    className, isOpen, onClose, node,
} : SignUserModalProps) => (
    <Modal lazy className={classNames(styles.SignUserModal, {}, [className])} isOpen={isOpen} onClose={onClose}>
        <VStack max align="center" gap="16">
            <Text gap="0" size={TextSize.XL} title={node.title} />
            <Text gap="0" align={TextAlign.CENTER} size={TextSize.M} text="Этот блок позволяет выбрать сотрудников для назначения их в текущий проект.  Выберите нужных сотрудников из списка, чтобы они были добавлены в блок." />

            <UsersForm node={node} onSuccess={onClose} />
        </VStack>
    </Modal>
);
