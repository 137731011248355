import { memo, useState } from 'react';
import styles from './UsersForm.module.scss';
import { classNames } from '../../../../../utils/classNames/classNames';
import { VStack } from '../../../../../components/ui/Stack';
import { UsersSelectList } from './UsersSelectList/UsersSelectList';
import { Button, ThemeButton } from '../../../../../components/ui/Button/Button';
import { useUsers } from '../useUsers';
import { useUsersStore } from '../../store/users';
import { Input } from '../../../../../components/ui/Input/Input';
import { SchemaData } from '../../types/orgschema';

interface UsersFormProps {
  className?: string;
  onSuccess: () => void;
  node: SchemaData;
}

export const UsersForm = memo((props: UsersFormProps) => {
    const {
        className, onSuccess, node,
    } = props;

    const { users, isLoading } = useUsers();

    const setSearchTerm = useUsersStore((state) => state.setSearchTerm);
    const searchTerm = useUsersStore((state) => state.searchTerm);

    const { mutate } = useUsers();

    const signUsers = useUsersStore((state) => state.signUsers);
    const clearSignUsers = useUsersStore((state) => state.clearUsers);

    const handleSignUsers = () => {
        const data = {
            users: signUsers.map((user) => user.id),
            id: node.id,
            type: node.type,
        };
        mutate(data);
        clearSignUsers(); // Очищаем список подписанных пользователей после успешной подписки
        onSuccess();
    };

    return (
        <VStack max gap="32" className={classNames(styles.UsersForm, {}, [className])}>

            <Input placeholder="Искать сотрудника..." onChange={setSearchTerm} value={searchTerm} />

            <UsersSelectList node={node} users={users} isLoading={isLoading} />

            <Button onClick={handleSignUsers} className={styles.submitBtn} theme={ThemeButton.DEFAULT}>Подвердить</Button>
        </VStack>
    );
});
