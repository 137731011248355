import { ChangeEvent, memo, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import styles from './CreateSchemaForm.module.scss';
import { classNames } from '../../../../../utils/classNames/classNames';
import { HStack, VStack } from '../../../../../components/ui/Stack';
import { Input } from '../../../../../components/ui/Input/Input';
import { Button, ThemeButton } from '../../../../../components/ui/Button/Button';
import { useSchemasStore } from '../../store/schemas';
import { CreateSchemaData } from '../../types/orgschema';
import { OrgSChemaService } from '../../services/OrgSchema.service';

interface CreateSchemaFormProps {
  className?: string;
  onSuccess: () => void;
}

export const CreateSchemaForm = memo((props: CreateSchemaFormProps) => {
    const { className, onSuccess } = props;

    const queryClient = useQueryClient();

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tskp, setTskp] = useState('');

    const setActiveSchema = useSchemasStore((state) => state.setActiveSchema);

    const onChangeTitle = (value: string) => {
        setTitle(value);
    };

    const onChangeDescription = (value: string) => {
        setDescription(value);
    };

    const onChangeTskp = (value: string) => {
        setTskp(value);
    };

    const { mutate } = useMutation({
        mutationKey: ['orgSchema'],
        mutationFn: (data: CreateSchemaData) => OrgSChemaService.createSchema(data),
        onSuccess: (response) => {
            toast.success('Схема успешно создана');
            setActiveSchema(response.id);

            return queryClient.invalidateQueries({
                queryKey: ['allOrgSchemaTree'],
            });
        },
        onError(error: any) {
            if (
                error.response
          && error.response.status
          && error.response.data.message
            ) {
                toast.error(error.response.data.message);
            } else {
                toast.error('Ошибка при создании схемы');
            }
        },
    });

    const handleCreateNewSchema = () => {
        const data: CreateSchemaData = {
            title,
            description,
            tskp,
        };
        mutate(data);
        onSuccess();
    };

    return (
        <VStack gap="32" className={classNames(styles.CreateSchemaForm, {}, [className])}>
            <Input onChange={onChangeTitle} value={title} className={styles.input} placeholder="Введите название схемы" label="Название" />

            <Input onChange={onChangeDescription} value={description} className={styles.input} placeholder="Введите описание схемы" label="Описание" />

            <Input onChange={onChangeTskp} value={tskp} className={styles.input} placeholder="Введите цкп" label="Цкп" />
            <HStack justify="end" max gap="16" align="center">
                <Button onClick={onSuccess} theme={ThemeButton.OUTLINE}>Закрыть</Button>
                <Button onClick={handleCreateNewSchema} theme={ThemeButton.DEFAULT}>Сохранить</Button>
            </HStack>
        </VStack>
    );
});
