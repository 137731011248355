import { memo } from 'react';
import styles from './SchemaHeader.module.scss';
import { classNames } from '../../../../../utils/classNames/classNames';
import { Button, ThemeButton } from '../../../../../components/ui/Button/Button';
import { HStack, VStack } from '../../../../../components/ui/Stack';
import { ZoomInIcon, ZoomOutIcon } from '../../../../../assets/svgs/orgschema/icons';
import { SchemasList } from '../SchemasList/SchemasList';
import { Text, TextBold, TextSize } from '../../../../../components/ui/Text/Text';
import { useSchemasStore } from '../../store/schemas';

interface SchemaHeaderProps {
  className?: string;
  onOpenCreateSchemaModal: () => void;
  isLoading: boolean;
}

export const SchemaHeader = memo((props: SchemaHeaderProps) => {
    const { className, onOpenCreateSchemaModal, isLoading } = props;

    const schemaCount = useSchemasStore((state) => state.totalCount);

    const setZoomCount = useSchemasStore((state) => state.setZoomCount);

    const roundToTwoDecimals = (num: number) => Math.round(num * 100) / 100;

    const zoomCount = useSchemasStore((state) => state.zoomCount);
    const handleZoomIn = () => {
        if (zoomCount >= 2) {
            return;
        }
        const newZoomCount = roundToTwoDecimals(zoomCount + 0.2);
        setZoomCount(Math.min(newZoomCount, 2));
    };

    const handleZoomOut = () => {
        if (zoomCount <= 0.5) {
            return;
        }
        const newZoomCount = roundToTwoDecimals(zoomCount - 0.1);
        setZoomCount(Math.max(newZoomCount, 0.5));
    };

    return (
        <HStack max justify="between" align="center" className={classNames(styles.SchemaHeader, {}, [className])}>

            <VStack gap="16" align="start">

                <HStack gap="32" align="center">

                    <Text gap="0" isActive bold={TextBold.BOLD} size={TextSize.XL} text={`Всего схем: ${schemaCount}`} />
                    <SchemasList isLoading={isLoading} />
                </HStack>
            </VStack>

            <HStack align="center" gap="16">
                <Button onClick={onOpenCreateSchemaModal} theme={ThemeButton.DEFAULT}>Создание новой схемы</Button>
                <Button disabled={zoomCount === 2} onClick={handleZoomIn} theme={ThemeButton.SVG_CIRCLE}>
                    <ZoomInIcon size={40} />
                </Button>
                <Text gap="0" className={styles.zoomCount} size={TextSize.L} isActive bold={TextBold.BOLD} text={`${zoomCount}x`} />
                <Button disabled={zoomCount === 0.5} onClick={handleZoomOut} theme={ThemeButton.SVG_CIRCLE}>
                    <ZoomOutIcon size={40} />
                </Button>
            </HStack>
        </HStack>
    );
});
