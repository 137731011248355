import Login from './pages/Login';
import Admin from './pages/Admin';
import Starter from './pages/Starter';
import {
    LOGIN_ROUTE, STARTER_ROUTE, ADMIN_ROUTE, USER_ROUTE, REGISTER_ROUTE, HELLO_ROUTE, SESSION_ROUTE, TASKS_ROUTE, DOC_ROUTE, ORG_SCHEMA,
} from './utils/consts';
import User from './pages/User';
import Register from './pages/Register';
import Hello from './pages/Hello';
import HelloMiddleware from './pages/HelloMiddleware';
import TasksModule from './pages/modules/TasksModule';
import DocModule from './pages/modules/DocModule';
import OrgSchemaModule from './pages/modules/OrgSchemaModule/OrgSchemaModule';

export const authRoutes = [
    {
        path: STARTER_ROUTE,
        component: <Starter />,
    },
    {
        path: ADMIN_ROUTE,
        component: <Admin />,
    },
    {
        path: USER_ROUTE,
        component: <User />,
    },
    {
        path: TASKS_ROUTE,
        component: <TasksModule />,
    },
];

export const publicRoutes = [
    // {
    //     path: SESSION_ROUTE,
    //     component: <ContinueSession />
    // },
    {
        path: DOC_ROUTE,
        component: <DocModule />,
    },
    {
        path: STARTER_ROUTE,
        component: <HelloMiddleware />,
    },
    {
        path: HELLO_ROUTE,
        component: <Hello />,
    },
    {
        path: LOGIN_ROUTE,
        component: <Login />,
    },
    {
        path: REGISTER_ROUTE,
        component: <Register />,
    },
    {
        path: ORG_SCHEMA,
        component: <OrgSchemaModule />,
    },
];
