/* eslint-disable react/destructuring-assignment */
import { useState } from 'react';
import EditSquareSvg from '../../../assets/svgs/EditSquareSvg';
import PlusLigthSvg from '../../../assets/svgs/PlusLigthSvg';
import TrashSvg from '../../../assets/svgs/TrashSvg';
import { IPosition } from '../../../assets/interfaces/interfaces';
import SureModal from '../../Modals/SureModal';

const AdminPositionTable = (props: any) => {
    require('../../../assets/styles/admin/admin_position_table.scss');
    const [positions, setPositions] = useState<IPosition[]>([
        {
            id: 1, status: '0', group: 'Конструктор', name: 'Семенова Алёна Сергеевна', created_at: '2024-05-23 17:11:56', updated_at: '2024-05-23 17:11:56',
        },
    ]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalText, setModalText] = useState<string>('');
    const onYes = () => {
        setShowModal(false);
    };
    const onNo = () => {
        setShowModal(false);
    };
    const onDelete = (name: string) => {
        setModalText(`Вы действительно хотите удалить должность ${name}?`);
        setShowModal(true);
    };
    return (
        <>
            {showModal && (
                <SureModal text={modalText} onYes={onYes} onNo={onNo} />
            )}
            <table className="admin_position_table " border={1} cellSpacing={0}>
                <thead>
                    <tr>
                        <th>Должность</th>
                        <th>Количество</th>
                        <th>Пользователь</th>
                        <th>Дата создания</th>
                        <th>
                            <div className="btn" onClick={props.onAdd}><PlusLigthSvg /></div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {positions.map((position) => (
                        <tr key={position.id}>
                            <td>{position.group}</td>
                            <td>
                                {position.status}
                            </td>
                            <td>
                                {position.name}
                            </td>
                            <td>
                                {position.created_at}
                            </td>
                            <td>
                                <div className="icons">
                                    <div className="btn edit" onClick={() => props.onEdit(position.name)}>
                                        <EditSquareSvg />
                                    </div>
                                    <div className="btn delete" onClick={() => onDelete(position.group)}>
                                        <TrashSvg />
                                    </div>
                                </div>

                            </td>
                        </tr>
                    ))}

                </tbody>
            </table>
        </>
    );
};

export default AdminPositionTable;
