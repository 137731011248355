import { memo, useState } from 'react';
import styles from './ShowFilesModal.module.scss';
import { classNames } from '../../../../../utils/classNames/classNames';
import { Modal } from '../../../../../components/ui/Modal/Modal';
import { VStack } from '../../../../../components/ui/Stack';
import { Text, TextBold, TextSize } from '../../../../../components/ui/Text/Text';
import { TabItem, Tabs, ThemeTab } from '../../../../../components/ui/Tabs/Tabs';
import { VideoPlayer } from './VideoPlayer/VideoPlayer';
import { SchemaData } from '../../types/orgschema';

interface ShowFilesModalProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  node: SchemaData;
}

const tabsFilesModal: TabItem[] = [
    { value: 'Файлы', content: 'Файлы' },
    { value: 'Аудио', content: 'Аудио' },
    { value: 'Видео', content: 'Видео' },
];

export const ShowFilesModal = ({
    className, isOpen, onClose, node,
} : ShowFilesModalProps) => {
    const [activeTab, setActiveTab] = useState('Файлы');

    const handleChangeTab = (value: TabItem) => {
        setActiveTab(value.value);
    };
    return (
        <Modal lazy className={classNames(styles.ShowFilesModal, {}, [className])} isOpen={isOpen} onClose={onClose}>
            <VStack max align="start" gap="16" className={styles.wrapper}>
                <Text gap="16" size={TextSize.XL} bold={TextBold.BOLD} textPrimary text="Просмотр файлов блока" />
                <Tabs
                    tabs={tabsFilesModal}
                    theme={ThemeTab.MAIN}
                    value={activeTab}
                    onTabClick={handleChangeTab}
                />

                <VideoPlayer videoSource={node.videoUrl} />
            </VStack>
        </Modal>
    );
};
