interface IProps {
    id: string,
    label: string,
    maxLength?: number,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    value: string,
    isDate?: boolean,
    className?: string,
    title?: string,
}
const InputLabelField = ({
    id, label, isDate, maxLength, onChange, value, className, title,
}: IProps) => {
    require('../../assets/styles/html/input_label_field.scss');
    return (
        <div className="input_label_field_wrapper">
            {title ? (
                <div className="title_wrapper">
                    <h5 className="title">{title}</h5>
                </div>
            ) : ''}

            <div className="input_label_field">
                <input
                    type={isDate ? 'date' : 'text'}
                    className={`input_label_field__input ${className}`}
                    value={value}
                    onChange={onChange}
                    id={id}
                    maxLength={maxLength}
                    placeholder=""
                />
                <label
                    className="input_label_field__label"
                    htmlFor={id}
                >
                    {label}
                    {' '}
                    {`${maxLength ? `(${value.length}/${maxLength})` : ''}`}
                </label>
            </div>
        </div>

    );
};

export default InputLabelField;
