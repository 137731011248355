import { useMutation } from '@tanstack/react-query';
import {
    ChangeEvent, useCallback, useMemo, useState,
} from 'react';
import toast from 'react-hot-toast';
import { fileService } from '../../../pages/modules/OrgSchemaModule/services/File.service';

type TypeUpload = (onChange: (url: string) => void, folder?: string) => {
  uploadFile: (event: ChangeEvent<HTMLInputElement>) => Promise<void>
  isLoading: boolean
}

export const useUpload: TypeUpload = (onChange, folder) => {
    const [isLoading, setIsLoading] = useState(false);

    const { mutateAsync } = useMutation({
        mutationKey: ['upload file'],
        mutationFn: (data: FormData) => fileService.uploadFile(data, folder),
        onSuccess: ({ data }) => {
            onChange(data[0].url);
        },
        onError: () => {
            toast.error('Ошибка при загрузке файла');
        },
    });

    const uploadFile = useCallback(
        async (event: ChangeEvent<HTMLInputElement>) => {
            setIsLoading(true);

            const { files } = event.target;

            if (files?.length) {
                const formData = new FormData();
                formData.append('file', files[0]);

                await mutateAsync(formData);

                setTimeout(() => {
                    setIsLoading(false);
                }, 2000);
            }
        },
        [mutateAsync],
    );

    return useMemo(() => ({
        uploadFile, isLoading,
    }), [uploadFile, isLoading]);
};
