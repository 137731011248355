import { ITaskFile } from '../../../assets/interfaces/interfaces';
import FileTaskDeleteSvg from '../../../assets/svgs/FileTaskDeleteSvg';
import FileTaskIconSvg from '../../../assets/svgs/FileTaskIconSvg';
import '../../../assets/styles/modules/components/files_for_modal.scss';

interface IProps {
    files: ITaskFile[];
    onDeleteFile: Function;
}
const FilesForModal = ({ files, onDeleteFile }: IProps) => {
    const getFileDate = (dateString: string) => {
        const options: any = {
            day: 'numeric',
            month: 'long',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
        };

        const date = new Date(dateString);
        const formattedDate = date.toLocaleString('ru-RU', options).replace(',', '');

        return formattedDate;
    };
    return (
        <div className="files_for_modal_wrapper">
            {files.length ? (
                <>
                    {files.map((file, index) => (
                        <div className="file">
                            <div className="icon">

                                <FileTaskIconSvg />
                            </div>
                            <div className="info">
                                <div className="title">{file.path}</div>
                                <div className="created">{getFileDate(file.created_at)}</div>
                                <div className="actions">
                                    <span className="delete" onClick={() => onDeleteFile(file, index)}>
                                        <FileTaskDeleteSvg />
                                    </span>
                                </div>
                            </div>

                        </div>
                    ))}
                </>
            ) : 'Не указан'}
        </div>
    );
};
export default FilesForModal;
