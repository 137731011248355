/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useState } from 'react';
import FileDropdown from '../../../../../components/html/FileDropdown';
import InputLabelField from '../../../../../components/html/InputLabelFiled';
import SelectLabelField from '../../../../../components/html/SelectLabelField';
import DraggableModal from '../../../../../components/Modals/DraggableModal';
import EditModal from '../../../../../components/Modals/EditModal';
import SureModal from '../../../../../components/Modals/SureModal';
import DetailsModal from './DetailsModal';
import DetailsModalContent from './DetailsModalContent';
import EditModalContent from './EditModalContent';
import '../../../../../assets/styles/modules/components/details_modal.scss';
import { IDocDoneData, IDocSendData, IDocTableData } from '../../../../../assets/interfaces/modules/doc_interface';
import {
    IActions, IMultySelectSearch, IOpenFile, ITextActive,
} from '../../../../../assets/interfaces/interfaces';
import ActionsButtons from '../../../../../components/html/ActionsButtons';

interface IProps {
    showAdd: boolean;
    openDetails: IDocTableData | undefined;
    sureDeleteModal: ITextActive;
    sureArchiveModal: ITextActive;
    openFiles: IOpenFile[];
    onConfirmYesDelete: Function;
    onConfirmNoDelete: Function;
    onConfirmYesArchive: Function;
    onConfirmNoArchive: Function;
    onConfirmNoDenyAgreement: Function;
    onConfirmYesDenyAgreement: Function;
    onChangeOpenFiles: Function;
    onCloseAddModal: Function;
    onSaveAddModal: Function;
    onCloseDetailModal: Function;
    onClickDetailsTabModal: Function;
    onSaveDetails: Function;
    detailsTabs: ITextActive[];
    onDeleteFileDetails: Function;
    selectUserModal: boolean;
    sureDenyAgreementModal: ITextActive;
    onCloseSelectUserModal: Function;
    onSaveSelectUserModal: Function;
    onChangeDenyAgreement: Function;
}
const DocModals = ({
    onDeleteFileDetails, onChangeDenyAgreement, sureDenyAgreementModal, selectUserModal, onCloseSelectUserModal, onSaveSelectUserModal, detailsTabs, onSaveDetails, onClickDetailsTabModal, onCloseAddModal, onCloseDetailModal, onSaveAddModal, onChangeOpenFiles, openDetails, showAdd, onConfirmNoDenyAgreement, onConfirmYesDenyAgreement, onConfirmYesArchive, onConfirmNoArchive, onConfirmYesDelete, onConfirmNoDelete, sureDeleteModal, sureArchiveModal, openFiles,
}: IProps) => {
    const [theme, setTheme] = useState('');
    const [actions, setActions] = useState<IActions[]>([
        {
            text: 'Важно', active: false, svg: 'ExclamationMarkSvg', active_bg: '#f59e0b',
        },
    ]);
    const [selectedUsers, setSelectedUsers] = useState<IMultySelectSearch[]>([
        {
            title: 'Ермилов Дмитрий Олегович ', subtitle: 'ermilov@gmail.com', show: true, selected: false,
        },
        {
            title: 'Балаев Валентин Романович ', subtitle: 'st1ll@odr.su', show: true, selected: false,
        },
    ]);
    const [incomingActionsStatus, setIncomingActionsStatus] = useState<IActions[]>([]);
    const [selectedDocs, setSelectedDocs] = useState<IMultySelectSearch[]>([]);
    const [dropDownFiles, setDropDownFiles] = useState<File[]>([]);
    const [spaceZoneClicked, setSpaceZoneClicked] = useState<boolean>(false);
    const [sendDocData, setSendDocData] = useState<IDocSendData>({
        number: '', city: '', firstDate: '', secondDate: '',
    });
    const [doneDocData, setDoneDocData] = useState<IDocDoneData>({ situation: '', data: '', solution: '' });

    function onActiveDo(index: number) {
        const newArr = [...actions];
        newArr[index].active = !newArr[index].active;
        setActions(newArr);
    }
    function onChangeTypeDoc(docs: IMultySelectSearch[]) {
        setSelectedDocs(docs);
    }
    function onChangeSelectUsers(users: IMultySelectSearch[]) {

    }
    function onSelectUsers(users: IMultySelectSearch[]) {
        setSelectedUsers(users);
    }

    function onChangeFilesAddModal(files: File[]) {
        setDropDownFiles(files);
    }
    function onChangeSpaceZoneClicked(value: boolean) {
        setSpaceZoneClicked(value);
    }

    function onChangeFilesDetailsModal(files: File[]) {
        setDropDownFiles(files);
    }
    function onChangeDoneDataAddModal(text: string, key: keyof IDocDoneData) {
        const data = { ...doneDocData };
        data[key] = text;
        setDoneDocData(data);
    }
    function onChangeSendDataAddModal(text: string, key: keyof IDocSendData) {
        const data = { ...sendDocData };
        data[key] = text;
        setSendDocData(data);
    }
    function onChangeIncomingActionsStatus(index: number) {
        const newActions = [...incomingActionsStatus];
        if (newActions[index].active === true) {
            newActions[index].active = false;
            setIncomingActionsStatus(newActions);
            return;
        }
        newActions.forEach((val) => val.active = false);
        newActions[index].active = true;
        setIncomingActionsStatus(newActions);
    }
    useEffect(() => {
        const status = openDetails && openDetails.details.status;
        if (status) {
            const success:boolean = status.success ? status.success : false;
            const cancel:boolean = status.cancel ? status.cancel : false;
            setIncomingActionsStatus([
                {
                    text: 'Принять', active: success, svg: 'SuccessSvg', active_bg: '#35cc6c',
                },
                {
                    text: 'Отклонить', active: cancel, svg: 'CloseCrossSvg', active_bg: '#cf0c0c',
                },
            ]);
        }
    }, [openDetails]);
    return (
        <>
            {sureDeleteModal.active && (
                <SureModal
                    text={sureDeleteModal.text}
                    textConfirm="Удалить"
                    onYes={onConfirmYesDelete}
                    onNo={onConfirmNoDelete}
                />
            )}
            {sureArchiveModal.active && (
                <SureModal
                    text={sureArchiveModal.text}
                    textConfirm="Добавить"
                    onYes={onConfirmYesArchive}
                    onNo={onConfirmNoArchive}
                />
            )}
            {openFiles.length ? (
                <DraggableModal
                    files={openFiles}
                    onClose={() => onChangeOpenFiles([])}
                />
            ) : ''}
            {sureDenyAgreementModal.active ? (
                <EditModal
                    onCloseModal={() => onConfirmNoDenyAgreement()}
                    onClickSave={() => onConfirmYesDenyAgreement()}
                    title="Отмена согласования"
                >
                    <>
                        <div className="row">
                            <InputLabelField
                                id="reasonDeny"
                                label="Причина отмены"
                                maxLength={250}
                                onChange={(e) => { onChangeDenyAgreement(e.target.value); }}
                                value={sureDenyAgreementModal.text}
                            />
                        </div>
                    </>
                </EditModal>
            ) : ''}
            {selectUserModal ? (
                <EditModal
                    onCloseModal={() => onCloseSelectUserModal()}
                    onClickSave={() => onSaveSelectUserModal(selectedUsers)}
                    confirmBtnText="Добавить задачу"
                >
                    <>
                        <div className="row">
                            <SelectLabelField
                                id="select_users"
                                title="Список пользователей"
                                placeholder="Поиск..."
                                link={{ text: 'Список пользователей', url: 'https://develop.transmashpribor.ru/adminka.html?module=users' }}
                                values={selectedUsers}
                                onChangeValues={(val: IMultySelectSearch[]) => onSelectUsers(val)}
                                spaceZoneClicked={spaceZoneClicked}
                                multiple
                            />
                        </div>
                    </>
                </EditModal>
            ) : ''}
            {showAdd ? (
                <EditModal
                    onClickSpaceZone={() => onChangeSpaceZoneClicked(!spaceZoneClicked)}
                    onCloseModal={() => onCloseAddModal()}
                    onClickSave={() => onSaveAddModal({
                        theme, dropDownFiles, actions, doneDocData, sendDocData,
                    })}
                >
                    <EditModalContent
                        actions={actions}
                        doneDocData={doneDocData}
                        onActiveDo={onActiveDo}
                        onChangeDoneData={onChangeDoneDataAddModal}
                        onChangeFiles={onChangeFilesAddModal}
                        onChangeSendData={onChangeSendDataAddModal}
                        sendDocData={sendDocData}
                        theme={theme}
                        setTheme={(text: string) => setTheme(text)}
                        spaceZoneClicked={spaceZoneClicked}
                        dropDownFiles={dropDownFiles}
                        onChangeTypeDoc={onChangeTypeDoc}
                        onChangeSelectUsers={onChangeSelectUsers}
                    />
                </EditModal>
            ) : ''}
            {openDetails ? (
                <DetailsModal
                    editAvalible
                    onClickTab={(index: number) => onClickDetailsTabModal(index)}
                    onCloseModal={() => onCloseDetailModal()}
                    onSave={() => onSaveDetails({ td: openDetails, files: dropDownFiles, actions: incomingActionsStatus })}
                    tabs={detailsTabs}
                    onClickSpaceZone={() => onChangeSpaceZoneClicked(!spaceZoneClicked)}
                >
                    <>
                        <>

                            <div className="row">
                                <FileDropdown
                                    title="Файлы"
                                    files={dropDownFiles}
                                    onChangeFiles={onChangeFilesDetailsModal}
                                />
                            </div>
                            {((openDetails.details.status.success === false)
                                || (openDetails.details.status.success === true))
                                ? (
                                    <div className="row">
                                        <ActionsButtons
                                            title="Статус документа"
                                            onActive={onChangeIncomingActionsStatus}
                                            actions={incomingActionsStatus}
                                        />
                                    </div>

                                ) : ''}
                        </>
                    </>
                    <DetailsModalContent
                        detailsTabs={detailsTabs}
                        onDeleteFile={onDeleteFileDetails}
                        openDetails={openDetails}
                    />
                </DetailsModal>
            ) : ''}
        </>
    );
};
export default DocModals;
