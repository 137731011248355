import FullLogoSvg from '../../assets/svgs/FullLogoSvg';

const UserHeader = () => {
    require('../../assets/styles/user/header.scss');
    return (
        <div className="user_header">
            <div className="user_header__overlay" />
            <div className="user_header__bg">
                <div className="user_header__logo">
                    <FullLogoSvg />
                </div>
            </div>
        </div>
    );
};

export default UserHeader;
