import { memo } from 'react';
import styles from './UserItem.module.scss';
import { Skeleton } from '../../../../../components/ui/Skeleton/Skeleton';

interface SkeletonUserItemProps {
  className?: string;
}

export const SkeletonUserItem = memo((props: SkeletonUserItemProps) => {
    const { className } = props;
    return (
        <li
            className={styles.UserItem}
        >
            <Skeleton height={30} width={30} border="50%" className={styles.skeletonAvatar} />

            <Skeleton height={15} width="100%" border="10px" />

        </li>
    );
});
