/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
import {
    ChangeEvent,
    FC, memo, useEffect, useState,
} from 'react';
import styles from './VideoPlayer.module.scss';
import { classNames } from '../../../../../../utils/classNames/classNames';
import { IVideoPlayer } from './video.interface';
import { useVideo } from './useVideo';
import { Button, ThemeButton } from '../../../../../../components/ui/Button/Button';
import {
    BackVideo10Icon, BackVideo5Icon, ForwardVideo10Icon, ForwardVideo5Icon, FullScreenIcon, LowSoundIcon, MaxSoundIcon, MutedIcon, NoSoundIcon, PauseIcon, PlayIcon,
} from '../../../../../../assets/svgs/orgschema/icons';
import { HStack, VStack } from '../../../../../../components/ui/Stack';
import { Text, TextBold, TextSize } from '../../../../../../components/ui/Text/Text';
import { Skeleton } from '../../../../../../components/ui/Skeleton/Skeleton';
import { RangeInput } from '../../../../../../components/ui/RangeInput/RangeInput';

export const VideoPlayer: FC<IVideoPlayer> = memo(({ videoSource }) => {
    const { actions, video, videoRef } = useVideo();

    return (
        <div className={classNames(styles.VideoPlayer, {}, [])}>

            <video ref={videoRef} className={styles.video} src={`http://localhost:4200${videoSource}#t=1`} preload="metadata" />

            <VStack max gap="4" className={styles.menu}>
                <div className={styles.progressBarContainer}>
                    <div
                        style={{
                            width: `${video.progress}%`,
                        }}
                        className={styles.progressBar}
                    />
                </div>

                <HStack max gap="16" justify="between" className={styles.controls}>
                    <HStack gap="10" align="center" className={styles.container}>
                        <HStack gap="0">
                            <Button className={styles.icon} onClick={() => actions.revert(10)} theme={ThemeButton.SVG_CLEAN}>
                                <BackVideo10Icon size={25} />
                            </Button>
                            <Button className={styles.icon} onClick={() => actions.revert(5)} theme={ThemeButton.SVG_CLEAN}>
                                <BackVideo5Icon size={25} />
                            </Button>
                            <Button className={styles.icon} onClick={actions.toggleVideo} theme={ThemeButton.SVG_CLEAN}>
                                {
                                    video.isPlaying ? (<PauseIcon size={25} />) : (<PlayIcon size={25} />)
                                }
                            </Button>
                            <Button className={styles.icon} onClick={() => actions.fastForward(5)} theme={ThemeButton.SVG_CLEAN}>
                                <ForwardVideo5Icon size={25} />
                            </Button>
                            <Button className={styles.icon} onClick={() => actions.fastForward(10)} theme={ThemeButton.SVG_CLEAN}>
                                <ForwardVideo10Icon size={25} />
                            </Button>
                        </HStack>

                        <HStack gap="4" className={styles.timeControls}>

                            <Text gap="0" bold={TextBold.BOLD} isActive size={TextSize.L} text={`${Math.floor(video.currentTime / 60)}:${(`0${Math.floor(video.currentTime % 60)}`).slice(-2)}`} />
                            <Text gap="0" bold={TextBold.BOLD} size={TextSize.L} text="/" />

                            <Text gap="0" bold={TextBold.BOLD} textPrimary size={TextSize.L} text={`${Math.floor(video.videoTime / 60)}:${(`0${Math.floor(video.videoTime % 60)}`).slice(-2)}`} />

                        </HStack>
                    </HStack>

                    <HStack gap="8" align="center" className={styles.container}>
                        {
                            video.isMuted ? (
                                <Button className={styles.icon} onClick={actions.toggleMute} theme={ThemeButton.SVG_CLEAN}>
                                    <NoSoundIcon size={25} />
                                </Button>
                            ) : (
                                <HStack gap="4" className={styles.test}>
                                    {
                                        video.volume === 0 ? (
                                            <Button onClick={actions.toggleMute} className={styles.icon} theme={ThemeButton.SVG_CLEAN}>
                                                <MutedIcon size={25} />
                                            </Button>
                                        ) : video.volume <= 0.5 ? (
                                            <Button onClick={actions.toggleMute} className={styles.icon} theme={ThemeButton.SVG_CLEAN}>
                                                <LowSoundIcon size={25} />
                                            </Button>
                                        ) : (
                                            <Button onClick={actions.toggleMute} className={styles.icon} theme={ThemeButton.SVG_CLEAN}>
                                                <MaxSoundIcon size={25} />
                                            </Button>
                                        )
                                    }

                                    <RangeInput max={1} min={0} step={0.01} value={video.volume} onChange={(e) => actions.updateVolume(Number(e.target.value))} />
                                </HStack>

                            )
                        }

                        <Button onClick={actions.fullScreen} theme={ThemeButton.SVG_CLEAN}>
                            <FullScreenIcon size={25} />
                        </Button>
                    </HStack>

                </HStack>
            </VStack>

        </div>
    );
});
