/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { memo } from 'react';
import styles from './UsersSelectItem.module.scss';
import { classNames } from '../../../../../../../utils/classNames/classNames';
import { HStack, VStack } from '../../../../../../../components/ui/Stack';
import { IUser, UserStatus } from '../../../../types/orgschema';
import { Avatar } from '../../../../../../../components/ui/Avatar/Avatar';
import { Text, TextBold, TextSize } from '../../../../../../../components/ui/Text/Text';
import { currentUser } from '../../../../data/data';
import { useUsersStore } from '../../../../store/users';
import { HospitalIcon, VacationBoxIcon } from '../../../../../../../assets/svgs/orgschema/icons';

interface UsersSelectItemProps {
  className?: string;
  user: IUser;
}

export const UsersSelectItem = memo((props: UsersSelectItemProps) => {
    const { className, user } = props;

    const addUser = useUsersStore((state) => state.addUser);

    const removeUser = useUsersStore((state) => state.removeUser);
    const signUsers = useUsersStore((state) => state.signUsers);

    const isChecked = signUsers.some((u) => u.id === user.id); // Проверяем, добавлен ли пользователь в список

    const handleCheckboxChange = () => {
        if (isChecked) {
            removeUser(user.id); // Удаляем пользователя, если он уже добавлен
        } else {
            addUser(user); // Добавляем пользователя, если он не в списке
        }
    };
    return (
        <label htmlFor={user.id} className={classNames(styles.UsersSelectItem, {}, [className])}>
            <input
                type="checkbox"
                id={user.id}
                className={styles.checkbox}
                checked={isChecked} // Устанавливаем состояние чекбокса
                onChange={handleCheckboxChange} // Обработчик изменения состояния
                {...props}
            />

            <div className={styles.label}>
                <Avatar size={50} src={user.avatarPath} />

                <VStack gap="4" align="start">
                    <HStack max gap="8" align="center">
                        <Text gap="0" bold={TextBold.BOLD} size={TextSize.L} isHospital={user.status === UserStatus.HOSPITAL} isVacation={user.status === UserStatus.VACATION} isActive={user.status === UserStatus.FREE} text={user.fullName} />

                        {
                            user.status === UserStatus.HOSPITAL && (
                                <HospitalIcon className={styles.hospital} />
                            )
                        }
                        {
                            user.status === UserStatus.VACATION && (
                                <VacationBoxIcon className={styles.vacation} />
                            )
                        }
                        {
                            user.fullName === currentUser && (
                                <HStack className={styles.you}>
                                    <Text gap="0" bold={TextBold.BOLD} text="Это вы" />
                                </HStack>
                            )
                        }

                    </HStack>

                    <Text gap="0" text={user.position} />
                </VStack>

            </div>

        </label>
    );
});
