const UserAddSvg = () => (
    <svg fill="#000000" height="200px" width="200px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xmlSpace="preserve">
        <g id="SVGRepo_bgCarrier" strokeWidth="0" />
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
        <g id="SVGRepo_iconCarrier">
            {' '}
            <g>
                {' '}
                <g>
                    {' '}
                    <path d="M390.731,303.366c-7.497,0-14.809,0.802-21.862,2.311c-17.693-28.045-42.322-50.98-71.365-66.597 c20.601-26.351,31.901-60.325,31.901-96.243c0-37.616-12.387-73.108-34.879-99.935C271.333,15.236,240.245,0,206.991,0 c-33.255,0-64.342,15.236-87.536,42.902c-22.492,26.828-34.879,62.319-34.879,99.935c0,35.917,11.3,69.89,31.9,96.241 c-53.946,28.929-91.216,82.148-99.219,143.727c-1.358,10.455,1.855,20.992,8.82,28.915c6.954,7.905,16.989,12.44,27.531,12.44 h234.111C295.642,473.878,338.815,512,390.731,512c57.52,0,104.317-46.797,104.317-104.318S448.251,303.366,390.731,303.366z M206.991,32.952c49.329,0,89.462,49.294,89.462,109.884c0,65.698-44.511,109.881-89.593,109.881 c-47.59-0.078-89.331-47.463-89.331-109.881C117.528,82.246,157.66,32.952,206.991,32.952z M287.718,391.208H53.609 c-1.073-0.002-2.089-0.458-2.786-1.25c-0.702-0.797-1.025-1.856-0.889-2.906c7.07-54.408,41.752-100.954,91.383-123.548 c19.305,14.355,41.835,22.125,65.493,22.167c0.06,0,0.12,0.003,0.18,0.003s0.12-0.003,0.18-0.003 c23.451-0.041,46.015-7.682,65.489-22.164c26.151,11.928,48.569,30.785,64.853,54.454 C311.52,333.417,292.673,360.112,287.718,391.208z M390.731,479.048c-39.351,0-71.365-32.014-71.365-71.365 c0-39.499,32.143-71.365,71.365-71.365c39.35,0,71.364,32.014,71.364,71.365S430.081,479.048,390.731,479.048z" />
                    {' '}
                </g>
                {' '}
            </g>
            {' '}
            <g>
                {' '}
                <g>
                    {' '}
                    <path d="M427.274,391.206h-20.067v-20.067c0-9.099-7.377-16.476-16.476-16.476c-9.099,0-16.476,7.377-16.476,16.476v20.067 h-20.068c-9.099,0-16.476,7.377-16.476,16.476s7.377,16.476,16.476,16.476h20.068v20.068c0,9.099,7.377,16.476,16.476,16.476 c9.099,0,16.476-7.377,16.476-16.476v-20.068h20.067c9.099,0,16.476-7.377,16.476-16.476S436.373,391.206,427.274,391.206z" />
                    {' '}
                </g>
                {' '}
            </g>
            {' '}
        </g>
    </svg>
);

export default UserAddSvg;
