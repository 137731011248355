import { useState } from 'react';
import { IDocTable } from '../../../../../assets/interfaces/modules/doc_interface';
import DocLayoutTab from './DocLayoutTab';

interface IProps {
    onCloseTab:Function;
}
const DocArchiveTab = ({ onCloseTab }:IProps) => {
    const [tableData, setTableData] = useState<IDocTable>({
        data: [
            {
                name: 'Документ 1',
                from: 'test test test',
                to: 'test test test',
                created_at: '2024-08-26 11:19:20',
                send_at: '2024-08-26 11:19:20',
                status: 0,
                performer: { status: 0 },
                showDo: false,
                details: {
                    show: false,
                    from: 'test test test',
                    city: 'Город',
                    number: 'Порядковый номер',
                    sign: 'test test test',
                    comment: 'Комментарий',
                    created_at: '2024-08-26 11:19:20',
                    send_at: '2024-08-26 11:19:20',
                    status: { status: 1 },
                    files: [
                        {
                            created_at: '2024-08-19T11:18:39.000000Z', id: 120, path: 'tasks_86_file_adobe_reader_reference.pdf.pdf', task_id: 86, updated_at: '2024-08-19T11:18:39.000000Z', user_id: 13,
                        },
                    ],
                    comments: [{
                        created_at: '2024-08-26 11:19:20', edit: false, files: [{ comment_id: '1', id: '1', path: 'tasks_86_file_adobe_reader_reference.pdf' }], id: 1, text: 'Comment', user_id: '13',
                    }],
                },
            },
            {
                name: 'Документ 2',
                from: 'test test test',
                to: 'test test test',
                created_at: '2024-08-26 11:19:20',
                send_at: '2024-08-26 11:19:20',
                status: 1,
                performer: { status: 1 },
                showDo: false,
                details: {
                    show: false,
                    from: 'test test test',
                    city: 'Город',
                    number: 'Порядковый номер',
                    sign: 'test test test',
                    comment: 'Комментарий',
                    created_at: '2024-08-26 11:19:20',
                    send_at: '2024-08-26 11:19:20',
                    status: { status: 0 },
                    files: [
                        {
                            created_at: '2024-08-19T11:18:39.000000Z', id: 120, path: 'tasks_86_file_adobe_reader_reference.pdf.pdf', task_id: 86, updated_at: '2024-08-19T11:18:39.000000Z', user_id: 13,
                        },
                    ],
                    comments: [{
                        created_at: '2024-08-26 11:19:20', edit: false, files: [{ comment_id: '1', id: '1', path: 'tasks_86_file_adobe_reader_reference.pdf' }], id: 1, text: 'Comment', user_id: '13',
                    }],
                },
            },
            {
                name: 'Документ 3',
                from: 'test test test',
                to: 'test test test',
                created_at: '2024-08-26 11:19:20',
                send_at: '2024-08-26 11:19:20',
                status: 2,
                performer: { status: 2 },
                showDo: false,
                details: {
                    show: false,
                    from: 'test test test',
                    city: 'Город',
                    number: 'Порядковый номер',
                    sign: 'test test test',
                    comment: 'Комментарий',
                    created_at: '2024-08-26 11:19:20',
                    send_at: '2024-08-26 11:19:20',
                    status: { status: 1 },
                    files: [
                        {
                            created_at: '2024-08-19T11:18:39.000000Z', id: 120, path: 'tasks_86_file_adobe_reader_reference.pdf.pdf', task_id: 86, updated_at: '2024-08-19T11:18:39.000000Z', user_id: 13,
                        },
                    ],
                    comments: [{
                        created_at: '2024-08-26 11:19:20', edit: false, files: [{ comment_id: '1', id: '1', path: 'tasks_86_file_adobe_reader_reference.pdf' }], id: 1, text: 'Comment', user_id: '13',
                    }],
                },
            },
            {
                name: 'Документ 4',
                from: 'test test test',
                to: 'test test test',
                created_at: '2024-08-26 11:19:20',
                send_at: '2024-08-26 11:19:20',
                status: 3,
                performer: { status: 0 },
                showDo: false,
                details: {
                    show: false,
                    from: 'test test test',
                    city: 'Город',
                    number: 'Порядковый номер',
                    sign: 'test test test',
                    comment: 'Комментарий',
                    created_at: '2024-08-26 11:19:20',
                    send_at: '2024-08-26 11:19:20',
                    status: { status: 0 },
                    files: [
                        {
                            created_at: '2024-08-19T11:18:39.000000Z', id: 120, path: 'tasks_86_file_adobe_reader_reference.pdf.pdf', task_id: 86, updated_at: '2024-08-19T11:18:39.000000Z', user_id: 13,
                        },
                    ],
                    comments: [{
                        created_at: '2024-08-26 11:19:20', edit: false, files: [{ comment_id: '1', id: '1', path: 'tasks_86_file_adobe_reader_reference.pdf' }], id: 1, text: 'Comment', user_id: '13',
                    }],
                },
            },
            {
                name: 'Документ 5',
                from: 'test test test',
                to: 'test test test',
                created_at: '2024-08-26 11:19:20',
                send_at: '2024-08-26 11:19:20',
                status: 4,
                performer: { status: 1 },
                showDo: false,
                details: {
                    show: false,
                    from: 'test test test',
                    city: 'Город',
                    number: 'Порядковый номер',
                    sign: 'test test test',
                    comment: 'Комментарий',
                    created_at: '2024-08-26 11:19:20',
                    send_at: '2024-08-26 11:19:20',
                    status: { status: 1 },
                    files: [
                        {
                            created_at: '2024-08-19T11:18:39.000000Z', id: 120, path: 'tasks_86_file_adobe_reader_reference.pdf.pdf', task_id: 86, updated_at: '2024-08-19T11:18:39.000000Z', user_id: 13,
                        },
                    ],
                    comments: [{
                        created_at: '2024-08-26 11:19:20', edit: false, files: [{ comment_id: '1', id: '1', path: 'tasks_86_file_adobe_reader_reference.pdf' }], id: 1, text: 'Comment', user_id: '13',
                    }],
                },
            },
            {
                name: 'Документ 6',
                from: 'test test test',
                to: 'test test test',
                created_at: '2024-08-26 11:19:20',
                send_at: '2024-08-26 11:19:20',
                status: 0,
                performer: { status: 2 },
                showDo: false,
                details: {
                    show: false,
                    from: 'test test test',
                    city: 'Город',
                    number: 'Порядковый номер',
                    sign: 'test test test',
                    comment: 'Комментарий',
                    created_at: '2024-08-26 11:19:20',
                    send_at: '2024-08-26 11:19:20',
                    status: { status: 0 },
                    files: [
                        {
                            created_at: '2024-08-19T11:18:39.000000Z', id: 120, path: 'tasks_86_file_adobe_reader_reference.pdf.pdf', task_id: 86, updated_at: '2024-08-19T11:18:39.000000Z', user_id: 13,
                        },
                    ],
                    comments: [{
                        created_at: '2024-08-26 11:19:20', edit: false, files: [{ comment_id: '1', id: '1', path: 'tasks_86_file_adobe_reader_reference.pdf' }], id: 1, text: 'Comment', user_id: '13',
                    }],
                },
            },
            {
                name: 'Документ 7',
                from: 'test test test',
                to: 'test test test',
                created_at: '2024-08-26 11:19:20',
                send_at: '2024-08-26 11:19:20',
                status: 1,
                performer: { status: 0 },
                showDo: false,
                details: {
                    show: false,
                    from: 'test test test',
                    city: 'Город',
                    number: 'Порядковый номер',
                    sign: 'test test test',
                    comment: 'Комментарий',
                    created_at: '2024-08-26 11:19:20',
                    send_at: '2024-08-26 11:19:20',
                    status: { status: 1 },
                    files: [
                        {
                            created_at: '2024-08-19T11:18:39.000000Z', id: 120, path: 'tasks_86_file_adobe_reader_reference.pdf.pdf', task_id: 86, updated_at: '2024-08-19T11:18:39.000000Z', user_id: 13,
                        },
                    ],
                    comments: [{
                        created_at: '2024-08-26 11:19:20', edit: false, files: [{ comment_id: '1', id: '1', path: 'tasks_86_file_adobe_reader_reference.pdf' }], id: 1, text: 'Comment', user_id: '13',
                    }],
                },
            },
            {
                name: 'Документ 8',
                from: 'test test test',
                to: 'test test test',
                created_at: '2024-08-26 11:19:20',
                send_at: '2024-08-26 11:19:20',
                status: 2,
                performer: { status: 1 },
                showDo: false,
                details: {
                    show: false,
                    from: 'test test test',
                    city: 'Город',
                    number: 'Порядковый номер',
                    sign: 'test test test',
                    comment: 'Комментарий',
                    created_at: '2024-08-26 11:19:20',
                    send_at: '2024-08-26 11:19:20',
                    status: { status: 0 },
                    files: [
                        {
                            created_at: '2024-08-19T11:18:39.000000Z', id: 120, path: 'tasks_86_file_adobe_reader_reference.pdf.pdf', task_id: 86, updated_at: '2024-08-19T11:18:39.000000Z', user_id: 13,
                        },
                    ],
                    comments: [{
                        created_at: '2024-08-26 11:19:20', edit: false, files: [{ comment_id: '1', id: '1', path: 'tasks_86_file_adobe_reader_reference.pdf' }], id: 1, text: 'Comment', user_id: '13',
                    }],
                },
            },
            {
                name: 'Документ 9',
                from: 'test test test',
                to: 'test test test',
                created_at: '2024-08-26 11:19:20',
                send_at: '2024-08-26 11:19:20',
                status: 3,
                performer: { status: 2 },
                showDo: false,
                details: {
                    show: false,
                    from: 'test test test',
                    city: 'Город',
                    number: 'Порядковый номер',
                    sign: 'test test test',
                    comment: 'Комментарий',
                    created_at: '2024-08-26 11:19:20',
                    send_at: '2024-08-26 11:19:20',
                    status: { status: 1 },
                    files: [
                        {
                            created_at: '2024-08-19T11:18:39.000000Z', id: 120, path: 'tasks_86_file_adobe_reader_reference.pdf.pdf', task_id: 86, updated_at: '2024-08-19T11:18:39.000000Z', user_id: 13,
                        },
                    ],
                    comments: [{
                        created_at: '2024-08-26 11:19:20', edit: false, files: [{ comment_id: '1', id: '1', path: 'tasks_86_file_adobe_reader_reference.pdf' }], id: 1, text: 'Comment', user_id: '13',
                    }],
                },
            },
            {
                name: 'Документ 10',
                from: 'test test test',
                to: 'test test test',
                created_at: '2024-08-26 11:19:20',
                send_at: '2024-08-26 11:19:20',
                status: 4,
                performer: { status: 0 },
                showDo: false,
                details: {
                    show: false,
                    from: 'test test test',
                    city: 'Город',
                    number: 'Порядковый номер',
                    sign: 'test test test',
                    comment: 'Комментарий',
                    created_at: '2024-08-26 11:19:20',
                    send_at: '2024-08-26 11:19:20',
                    status: { status: 0 },
                    files: [
                        {
                            created_at: '2024-08-19T11:18:39.000000Z', id: 120, path: 'tasks_86_file_adobe_reader_reference.pdf.pdf', task_id: 86, updated_at: '2024-08-19T11:18:39.000000Z', user_id: 13,
                        },
                    ],
                    comments: [{
                        created_at: '2024-08-26 11:19:20', edit: false, files: [{ comment_id: '1', id: '1', path: 'tasks_86_file_adobe_reader_reference.pdf' }], id: 1, text: 'Comment', user_id: '13',
                    }],
                },
            },
            {
                name: 'Документ 11',
                from: 'test test test',
                to: 'test test test',
                created_at: '2024-08-26 11:19:20',
                send_at: '2024-08-26 11:19:20',
                status: 0,
                performer: { status: 1 },
                showDo: false,
                details: {
                    show: false,
                    from: 'test test test',
                    city: 'Город',
                    number: 'Порядковый номер',
                    sign: 'test test test',
                    comment: 'Комментарий',
                    created_at: '2024-08-26 11:19:20',
                    send_at: '2024-08-26 11:19:20',
                    status: { status: 1 },
                    files: [
                        {
                            created_at: '2024-08-19T11:18:39.000000Z', id: 120, path: 'tasks_86_file_adobe_reader_reference.pdf.pdf', task_id: 86, updated_at: '2024-08-19T11:18:39.000000Z', user_id: 13,
                        },
                    ],
                    comments: [{
                        created_at: '2024-08-26 11:19:20', edit: false, files: [{ comment_id: '1', id: '1', path: 'tasks_86_file_adobe_reader_reference.pdf' }], id: 1, text: 'Comment', user_id: '13',
                    }],
                },
            },
            {
                name: 'Документ 12',
                from: 'test test test',
                to: 'test test test',
                created_at: '2024-08-26 11:19:20',
                send_at: '2024-08-26 11:19:20',
                status: 1,
                performer: { status: 2 },
                showDo: false,
                details: {
                    show: false,
                    from: 'test test test',
                    city: 'Город',
                    number: 'Порядковый номер',
                    sign: 'test test test',
                    comment: 'Комментарий',
                    created_at: '2024-08-26 11:19:20',
                    send_at: '2024-08-26 11:19:20',
                    status: { status: 0 },
                    files: [
                        {
                            created_at: '2024-08-19T11:18:39.000000Z', id: 120, path: 'tasks_86_file_adobe_reader_reference.pdf.pdf', task_id: 86, updated_at: '2024-08-19T11:18:39.000000Z', user_id: 13,
                        },
                    ],
                    comments: [{
                        created_at: '2024-08-26 11:19:20', edit: false, files: [{ comment_id: '1', id: '1', path: 'tasks_86_file_adobe_reader_reference.pdf' }], id: 1, text: 'Comment', user_id: '13',
                    }],
                },
            },
            {
                name: 'Документ 13',
                from: 'test test test',
                to: 'test test test',
                created_at: '2024-08-26 11:19:20',
                send_at: '2024-08-26 11:19:20',
                status: 2,
                performer: { status: 0 },
                showDo: false,
                details: {
                    show: false,
                    from: 'test test test',
                    city: 'Город',
                    number: 'Порядковый номер',
                    sign: 'test test test',
                    comment: 'Комментарий',
                    created_at: '2024-08-26 11:19:20',
                    send_at: '2024-08-26 11:19:20',
                    status: { status: 1 },
                    files: [
                        {
                            created_at: '2024-08-19T11:18:39.000000Z', id: 120, path: 'tasks_86_file_adobe_reader_reference.pdf.pdf', task_id: 86, updated_at: '2024-08-19T11:18:39.000000Z', user_id: 13,
                        },
                    ],
                    comments: [{
                        created_at: '2024-08-26 11:19:20', edit: false, files: [{ comment_id: '1', id: '1', path: 'tasks_86_file_adobe_reader_reference.pdf' }], id: 1, text: 'Comment', user_id: '13',
                    }],
                },
            },
            {
                name: 'Документ 14',
                from: 'test test test',
                to: 'test test test',
                created_at: '2024-08-26 11:19:20',
                send_at: '2024-08-26 11:19:20',
                status: 3,
                performer: { status: 1 },
                showDo: false,
                details: {
                    show: false,
                    from: 'test test test',
                    city: 'Город',
                    number: 'Порядковый номер',
                    sign: 'test test test',
                    comment: 'Комментарий',
                    created_at: '2024-08-26 11:19:20',
                    send_at: '2024-08-26 11:19:20',
                    status: { status: 0 },
                    files: [
                        {
                            created_at: '2024-08-19T11:18:39.000000Z', id: 120, path: 'tasks_86_file_adobe_reader_reference.pdf.pdf', task_id: 86, updated_at: '2024-08-19T11:18:39.000000Z', user_id: 13,
                        },
                    ],
                    comments: [{
                        created_at: '2024-08-26 11:19:20', edit: false, files: [{ comment_id: '1', id: '1', path: 'tasks_86_file_adobe_reader_reference.pdf' }], id: 1, text: 'Comment', user_id: '13',
                    }],
                },
            },
            {
                name: 'Документ 15',
                from: 'test test test',
                to: 'test test test',
                created_at: '2024-08-26 11:19:20',
                send_at: '2024-08-26 11:19:20',
                status: 4,
                performer: { status: 2 },
                showDo: false,
                details: {
                    show: false,
                    from: 'test test test',
                    city: 'Город',
                    number: 'Порядковый номер',
                    sign: 'test test test',
                    comment: 'Комментарий',
                    created_at: '2024-08-26 11:19:20',
                    send_at: '2024-08-26 11:19:20',
                    status: { status: 1 },
                    files: [
                        {
                            created_at: '2024-08-19T11:18:39.000000Z', id: 120, path: 'tasks_86_file_adobe_reader_reference.pdf.pdf', task_id: 86, updated_at: '2024-08-19T11:18:39.000000Z', user_id: 13,
                        },
                    ],
                    comments: [{
                        created_at: '2024-08-26 11:19:20', edit: false, files: [{ comment_id: '1', id: '1', path: 'tasks_86_file_adobe_reader_reference.pdf' }], id: 1, text: 'Comment', user_id: '13',
                    }],
                },
            },
            {
                name: 'Документ 16',
                from: 'test test test',
                to: 'test test test',
                created_at: '2024-08-26 11:19:20',
                send_at: '2024-08-26 11:19:20',
                status: 0,
                performer: { status: 0 },
                showDo: false,
                details: {
                    show: false,
                    from: 'test test test',
                    city: 'Город',
                    number: 'Порядковый номер',
                    sign: 'test test test',
                    comment: 'Комментарий',
                    created_at: '2024-08-26 11:19:20',
                    send_at: '2024-08-26 11:19:20',
                    status: { status: 0 },
                    files: [
                        {
                            created_at: '2024-08-19T11:18:39.000000Z', id: 120, path: 'tasks_86_file_adobe_reader_reference.pdf.pdf', task_id: 86, updated_at: '2024-08-19T11:18:39.000000Z', user_id: 13,
                        },
                    ],
                    comments: [{
                        created_at: '2024-08-26 11:19:20', edit: false, files: [{ comment_id: '1', id: '1', path: 'tasks_86_file_adobe_reader_reference.pdf' }], id: 1, text: 'Comment', user_id: '13',
                    }],
                },
            },
        ],
        currentPage: 1,
        todosPerPage: 10,
    });
    const onChangeTablePage = (page: number) => {
        const newData = { ...tableData };
        newData.currentPage = page;
        setTableData(newData);
    };

    return (
        <DocLayoutTab
            title="Архив документов"
            isArchive
            onChangeTablePage={onChangeTablePage}
            tableData={tableData}
            tabelHeaders={[
                { text: 'Документ', class: 'doc', showDo: false },
                { text: 'Кому', class: 'to', showDo: false },
                { text: 'Дата создание', class: 'created', showDo: false },
                { text: 'Дата отправки', class: 'send', showDo: false },
                { text: 'Статус', class: 'status', showDo: false },
                { text: 'Исполнитель задачи', class: 'performer', showDo: false },
                { text: '', class: '', showDo: false },
            ]}
            onCloseTab={onCloseTab}
        />
    );
};

export default DocArchiveTab;
