import { useState } from 'react';
import InputLabelField from '../../../../../components/html/InputLabelFiled';
import FileDropdown from '../../../../../components/html/FileDropdown';
import SelectLabelField from '../../../../../components/html/SelectLabelField';
import ActionsButtons from '../../../../../components/html/ActionsButtons';
import { IDocDoneData, IDocSendData } from '../../../../../assets/interfaces/modules/doc_interface';
import { IActions, IMultySelectSearch } from '../../../../../assets/interfaces/interfaces';

interface IProps {
    onChangeDoneData: Function;
    setTheme: Function;
    onChangeFiles: Function;
    onActiveDo: Function;
    onChangeSendData: Function;
    onChangeTypeDoc: Function;
    onChangeSelectUsers: Function;
    theme: string;
    spaceZoneClicked: boolean;
    sendDocData: IDocSendData;
    doneDocData: IDocDoneData;
    actions: IActions[];
    dropDownFiles: File[];
}
interface ISelectedDoc {
    send: boolean,
    done: boolean
}
const EditModalContent = ({
    dropDownFiles, onChangeSelectUsers, onChangeTypeDoc, onChangeDoneData, actions, setTheme, sendDocData, doneDocData, onActiveDo, onChangeSendData, onChangeFiles, theme, spaceZoneClicked,
}: IProps) => {
    const [selectedUsers, setSelectedUsers] = useState<IMultySelectSearch[]>([
        {
            title: 'Ермилов Дмитрий Олегович ', subtitle: 'ermilov@gmail.com', show: true, selected: false,
        },
        {
            title: 'Балаев Валентин Романович ', subtitle: 'st1ll@odr.su', show: true, selected: false,
        },
    ]);
    const [selectedDocs, setSelectedDocs] = useState<IMultySelectSearch[]>([
        {
            title: 'Передача функционала', subtitle: 'Передача функционала', show: true, selected: false,
        },
        {
            title: 'Законченная работа сотрудника', subtitle: 'ЗРС', show: true, selected: false,
        },
    ]);

    const [selectedDoc, setSelectedDoc] = useState<ISelectedDoc>({ send: false, done: false });
    const onSelectUsers = (vals: IMultySelectSearch[]) => {
        setSelectedUsers(vals);
        onChangeSelectUsers(vals);
    };
    const onSelectDoc = (vals: IMultySelectSearch[], index: number) => {
        setSelectedDocs(vals);
        onChangeTypeDoc(vals);
        const selectedDoc = selectedDocs[index];

        if (selectedDoc.title === 'Передача функционала') {
            setSelectedDoc({ send: true, done: false });
        } else if (selectedDoc.title === 'Законченная работа сотрудника') {
            setSelectedDoc({ send: false, done: true });
        }
    };
    return (
        <>
            <div className="row">
                <InputLabelField
                    id="theme"
                    label="Тема"
                    maxLength={250}
                    onChange={(e) => setTheme(e.target.value)}
                    value={theme}
                />
            </div>

            <div className="row">
                <FileDropdown
                    files={dropDownFiles}
                    onChangeFiles={onChangeFiles}
                    acceptTypes={['application/pdf']}
                />
            </div>
            <div className="row">
                <SelectLabelField
                    id="to_person"
                    title="Кому"
                    placeholder="Поиск..."
                    link={{ text: 'Список пользователей', url: 'https://develop.transmashpribor.ru/adminka.html?module=users' }}
                    values={selectedUsers}
                    onChangeValues={(val: IMultySelectSearch[]) => onSelectUsers(val)}
                    spaceZoneClicked={spaceZoneClicked}
                    multiple
                />
            </div>
            <div className="row">
                <SelectLabelField
                    id="type_doc"
                    title="Тип документа"
                    placeholder="Поиск..."
                    link={{ text: 'Справочник шаблонов', url: 'https://develop.transmashpribor.ru/agreement.html/types_doc' }}
                    values={selectedDocs}
                    onChangeValues={(val: IMultySelectSearch[], index: number) => onSelectDoc(val, index)}
                    spaceZoneClicked={spaceZoneClicked}
                    multiple={false}
                />
            </div>

            <ActionsButtons
                actions={actions}
                onActive={onActiveDo}
            />

            {selectedDoc.send ? (
                <>
                    <div className="row">
                        <InputLabelField
                            id="doc_number"
                            title="Опциональные данные"
                            label="Порядковый номер"
                            maxLength={250}
                            onChange={(e) => onChangeSendData(e.target.value, 'number')}
                            value={sendDocData.number}
                        />
                    </div>
                    <div className="row">
                        <InputLabelField
                            id="doc_city"
                            label="Город"
                            maxLength={250}
                            onChange={(e) => onChangeSendData(e.target.value, 'city')}
                            value={sendDocData.city}
                        />
                    </div>
                    <div className="row">
                        <InputLabelField
                            id="doc_first_date"
                            label="Первая дата"
                            isDate
                            onChange={(e) => onChangeSendData(e.target.value, 'firstDate')}
                            value={sendDocData.firstDate}
                        />
                    </div>
                    <div className="row">
                        <InputLabelField
                            id="doc_second_date"
                            label="Вторая дата"
                            isDate
                            onChange={(e) => onChangeSendData(e.target.value, 'secondDate')}
                            value={sendDocData.secondDate}
                        />
                    </div>
                </>
            ) : ''}
            {selectedDoc.done ? (
                <>
                    <div className="row">
                        <InputLabelField
                            id="doc_situation"
                            title="Опциональные данные"
                            label="Ситуация"
                            maxLength={250}
                            onChange={(e) => onChangeDoneData(e.target.value, 'situation')}
                            value={doneDocData.situation}
                        />
                    </div>
                    <div className="row">
                        <InputLabelField
                            id="doc_data"
                            label="Данные"
                            maxLength={250}
                            onChange={(e) => onChangeDoneData(e.target.value, 'data')}
                            value={doneDocData.data}
                        />
                    </div>
                    <div className="row">
                        <InputLabelField
                            id="doc_solution"
                            label="Решение"
                            maxLength={250}
                            onChange={(e) => onChangeDoneData(e.target.value, 'solution')}
                            value={doneDocData.solution}
                        />
                    </div>
                </>
            ) : ''}
        </>
    );
};
export default EditModalContent;
