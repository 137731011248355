/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
interface IProps {
    id: string,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    value: boolean,
}
const CheckboxSquare = (props: IProps) => {
    require('../../assets/styles/html/CheckboxSquare.scss');
    return (
        <label className="checkbox">
            <input
                type="checkbox"
                checked={props.value}
                className="checkbox__input"
                onChange={props.onChange}
                name={props.id}
                id={props.id}
            />
            <span className="checkbox__inner" />
        </label>
    );
};

export default CheckboxSquare;
