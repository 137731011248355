import { IOpenFile, ITaskCommentFile, ITaskFile } from '../assets/interfaces/interfaces';
import { HOME_TAB } from './tabs';

export const getFileLink = (task_id: number, file_path: string, comment_id?: number) => `https://develop1.transmashpribor.ru/storage/tasks/${task_id}${comment_id ? `/comments/${comment_id}` : ''}/files/${file_path}`;
export const hrefToFile = (task_id: number, file_path: string, comment_id?: number) => {
    window.open(getFileLink(task_id, file_path, comment_id), '_blank');
};
export const checkImage = (file_path: string) => {
    const format = file_path.split('.')[file_path.split('.').length - 1].toLocaleUpperCase();
    let isImg = false;
    // 'PDF',
    ['JPEG', 'JPG', 'GIF', 'PNG', 'WebP', 'HEIF', 'HEIC', 'TIFF', 'BMP', 'PSD', 'RAW', 'EPS', 'AI', 'INDD', 'DWG'].forEach((val) => {
        if (format === val) {
            isImg = true;
        }
    });
    return isImg;
};
export const onShowFileSlider = (task_id: number, files: ITaskFile[] | ITaskCommentFile[], file: ITaskFile | ITaskCommentFile, file_index: number, task_comment_id?: number): IOpenFile[] => {
    const newFiles: IOpenFile[] = [];
    files.forEach((file) => {
        // if (checkImage(file.path)) {
        const newFile: IOpenFile = {
            task_id, file_id: file.id, path: file.path, task_comment_id,
        };
        newFiles.push(newFile);
        // }
    });
    const firstFile = {
        task_id, file_id: newFiles[0].file_id, path: newFiles[0].path, task_comment_id,
    };
    newFiles[0] = {
        task_id, file_id: file.id, path: file.path, task_comment_id,
    };
    newFiles[file_index] = firstFile;
    console.log('newFiles[file_index]', newFiles[file_index]);
    console.log('file_index', file_index);
    return newFiles;
};
export const getRouteTabId = (get_route:string) => {
    const splittedRoute = get_route.split('/');
    if (splittedRoute) {
        const lastSplittedRoute = splittedRoute[splittedRoute.length - 1];
        if (lastSplittedRoute === ':id') {
            splittedRoute.shift();
            splittedRoute[splittedRoute.length - 1] = String(0);
            if (splittedRoute[1] === ':tab') {
                splittedRoute[1] = HOME_TAB;
            }
            const route = `/${splittedRoute.join('/')}`;
            return route;
        }
    }
    return (get_route);
};
