/* eslint-disable no-nested-ternary */
import { memo, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import {
    AddIcon, AllUsersIcon, CloudFolderIcon, EditIcon, MailIcon, PaperClipIcon, SettingsIcon,
    TrashIcon,
    UserIcon,
} from '../../../../assets/svgs/orgschema/icons';
import { Button, ThemeButton } from '../../../../components/ui/Button/Button';
import { HStack, VStack } from '../../../../components/ui/Stack';
import styles from './Node.module.scss';
import { Text, TextBold, TextSize } from '../../../../components/ui/Text/Text';
import UserItem from './UserItem/UserItem';
import { IUser, SchemaData } from '../types/orgschema';
import { classNames, Mods } from '../../../../utils/classNames/classNames';
import { SignUserModal } from '../components/SignUserModal/SignUserModal';
import UserCard from '../components/UserCard';
import { useUsersStore } from '../store/users';
import { currentUser } from '../data/data';
import { UpdateSchemaModal } from '../components/UpdateSchemaModal/UpdateSchemaModal';
import { FileModal } from '../components/FileModal/FileModal';
import { AddBlockModal } from '../components/AddBlockModal/AddBlockModal';
import { OrgSChemaService } from '../services/OrgSchema.service';
import { useSchemasStore } from '../store/schemas';
import { NodeSettings } from './NodeSettings/NodeSettings';
import { ShowFilesModal } from '../components/ShowFilesModal/ShowFilesModal';

interface NodeProps {
    node: SchemaData;
    isChildren: boolean;
}

const Node = memo((props: NodeProps) => {
    const {
        node, isChildren,
    } = props;

    const queryClient = useQueryClient();

    const [userInfo, setUserInfo] = useState(false);
    const [modal, setModal] = useState(false);
    const [fileModal, setFileModal] = useState(false);
    const [showFileModal, setShowFileModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const activeSchemaId = useSchemasStore((state) => state.activeSchemaId);
    const [addBlockModal, setAddBlockModal] = useState(false);

    const [settings, setSettings] = useState(false);

    const onCloseAddBlockModal = () => {
        setAddBlockModal(false);
    };

    const onOpenAddBlockModal = () => {
        setAddBlockModal(true);
    };

    const onOpenSettings = () => {
        setSettings(true);
    };

    const onCloseSettings = () => {
        setSettings(false);
    };

    const onOpenShowFiles = () => {
        setShowFileModal(true);
    };

    const onCloseShowFiles = () => {
        setShowFileModal(false);
    };

    function declineSubdivision(count: number): string {
        const lastDigit = count % 10;
        const lastTwoDigits = count % 100;

        if (lastDigit === 1 && lastTwoDigits !== 11) {
            return `Скрыто ${count} подразделение`;
        } if ((lastDigit >= 2 && lastDigit <= 4) && (lastTwoDigits < 12 || lastTwoDigits > 14)) {
            return `Скрыто ${count} подразделения`;
        }
        return `Скрыто  ${count} подразделений`;
    }

    const setCurrentUser = useUsersStore((state) => state.setCurrentUser);

    const { mutate: deleteBlock } = useMutation({
        mutationKey: ['delete block'],
        mutationFn: () => OrgSChemaService.deleteBlock(node.id),
        onSuccess() {
            toast.success('Блок успешно удален');

            queryClient.invalidateQueries({ queryKey: ['orgSchema', activeSchemaId] });
        },
        onError(error: any) {
            if (
                error.response
        && error.response.status
        && error.response.data.message
            ) {
                toast.error(error.response.data.message);
            } else {
                toast.error('Ошибка при удалении схемы');
            }
        },
    });

    const closeModal = () => {
        setModal(false);
    };

    const openModal = () => {
        setModal(true);
    };

    const onCloseFileModal = () => {
        setFileModal(false);
    };

    const onOpenFileModal = () => {
        setFileModal(true);
    };

    const onCloseUpdateModal = () => {
        setUpdateModal(false);
    };

    const onOpenUpdateModal = () => {
        setUpdateModal(true);
    };

    const handleUserInfo = () => {
        setUserInfo(!userInfo);
    };

    const openUserInfo = (user: IUser) => {
        setCurrentUser(user);
        setUserInfo(true);
    };

    const closeUserInfo = () => {
        setUserInfo(false);
    };

    const handleRedirect = (link: string) => {
        window.open(link, '_blank', 'noopener,noreferrer');
    };

    const childrenMode: Mods = {
        [styles.oneChild]: node.children.length === 1,
    };

    const childrenModeTogether: Mods = {
        [styles.oneChildrenTogether]: node.isChildrenTogether,
    };

    const parentMode: Mods = {
        [styles.noneChildren]: node.children.length === 0 || node.isSchemaHidden,
        [styles.isChildren]: isChildren,
    };

    return (
        <VStack gap="32" key={node.id} className={styles.TreeNode}>
            <VStack align="center" gap="16" className={classNames(styles.NodeWrapper, parentMode, [])}>
                <div
                    style={{
                        borderTop: node.color !== '' ? `10px solid ${node.color}` : 'none',
                    }}
                    className={styles.Node}
                >

                    <VStack align="start" gap="8">
                        <Text gap="0" title={node.title} bold={TextBold.BOLD} size={TextSize.S} />
                        <Text gap="16" bold={TextBold.BOLD} size={TextSize.S} text={node.description} className={styles.description} />

                        {
                            node.employees === undefined || node.employees.length === 0 ? (
                                <Button onClick={openModal} theme={ThemeButton.OUTLINE}>
                                    <UserIcon />
                                    Добавить сотрудников
                                </Button>
                            ) : (
                                <>
                                    <HStack max align="center" gap="4">
                                        <Text gap="16" size={TextSize.M} bold={TextBold.BOLD} textPrimary text={`Всего сотрудников: ${node.employees.length}`} />
                                        <Button onClick={openModal} theme={ThemeButton.SVG_CLEAN}>
                                            <AllUsersIcon size={25} />
                                        </Button>
                                    </HStack>

                                    <ul className={styles.list}>
                                        {
                                            node.employees
                                                .sort((a, b) => (a.fullName === currentUser ? -1 : b.fullName === currentUser ? 1 : 0))
                                                .map((employee) => (
                                                    <UserItem
                                                        key={employee.id}
                                                        openUserInfo={openUserInfo}
                                                        user={employee}
                                                    />
                                                ))
                                        }
                                    </ul>
                                </>

                            )
                        }

                        <SignUserModal isOpen={modal} node={node} onClose={closeModal} />

                    </VStack>

                    <VStack gap="4" align="start" justify="center" className={styles.actions}>
                        <Button onClick={onOpenUpdateModal} theme={ThemeButton.SVG_CLEAN}>
                            <EditIcon size={20} />
                        </Button>
                        <Button onClick={onOpenAddBlockModal} theme={ThemeButton.SVG_CLEAN}><AddIcon size={20} /></Button>
                        <Button onClick={onOpenFileModal} theme={ThemeButton.SVG_CLEAN}><PaperClipIcon size={20} /></Button>
                        <div className={styles.settings}>
                            <Button onClick={onOpenSettings} theme={ThemeButton.SVG_CLEAN}><SettingsIcon size={20} /></Button>

                            <CSSTransition
                                in={settings}
                                timeout={300}
                                unmountOnExit
                                classNames="slide-animation"
                            >
                                <NodeSettings node={node} onDeleteBlock={deleteBlock} onOpenShowFiles={onOpenShowFiles} onClose={onCloseSettings} className={styles.settingPopup} />
                            </CSSTransition>

                        </div>

                        {
                            node.mailLink !== '' && (<Button onClick={() => handleRedirect(node.mailLink)} theme={ThemeButton.SVG_CLEAN}><MailIcon size={20} /></Button>)
                        }
                        {
                            node.cloudLink !== '' && (<Button onClick={() => handleRedirect(node.cloudLink)} theme={ThemeButton.SVG_CLEAN}><CloudFolderIcon size={20} /></Button>)
                        }

                    </VStack>

                    <CSSTransition
                        in={userInfo}
                        timeout={300}
                        unmountOnExit
                        classNames="slide-animation"
                    >
                        <UserCard onClose={closeUserInfo} className={styles.userInfo} />
                    </CSSTransition>

                </div>
                <HStack align="start" gap="8" className={styles.tskp}>
                    <Text gap="0" bold={TextBold.BOLD} textPrimary text="ЦКП:" />
                    <Text gap="0" bold={TextBold.BOLD} text={`${node.tskp}`} className={styles.tskpText} />
                </HStack>
                <CSSTransition
                    in={node.isSchemaHidden}
                    timeout={300}
                    unmountOnExit
                    classNames="slide-animation"
                >
                    <HStack align="center" justify="center" gap="8" className={styles.hiddenBlocks}>
                        <Text gap="0" bold={TextBold.BOLD} textPrimary text={declineSubdivision(node.children.length)} />
                    </HStack>
                </CSSTransition>

            </VStack>

            <CSSTransition
                in={node.children.length > 0 && !node.isSchemaHidden && !node.isChildrenTogether}
                timeout={300}
                unmountOnExit
                classNames="slide-animation"
            >
                <HStack max align="start" gap="32" justify="center" className={classNames(styles.childrenContainer, childrenMode, [])}>
                    {node.children.map((item) => (
                        <Node node={item} isChildren />
                    ))}
                </HStack>
            </CSSTransition>

            <CSSTransition
                in={node.children.length > 0 && !node.isSchemaHidden && node.isChildrenTogether}
                timeout={300}
                unmountOnExit
                classNames="slide-animation"
            >
                <VStack max align="center" justify="center" className={classNames(styles.childrenContainer, childrenModeTogether, [])}>
                    {node.children.map((item) => (
                        <Node node={item} isChildren />
                    ))}
                </VStack>
            </CSSTransition>

            <FileModal node={node} isOpen={fileModal} onClose={onCloseFileModal} />
            <ShowFilesModal node={node} isOpen={showFileModal} onClose={onCloseShowFiles} />
            <UpdateSchemaModal node={node} isOpen={updateModal} onClose={onCloseUpdateModal} />
            <AddBlockModal node={node} isOpen={addBlockModal} onClose={onCloseAddBlockModal} />
        </VStack>
    );
});

export default Node;
