/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
interface IProps {
    id: string,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    checked: boolean,
}
const RadioComponent = (props: IProps) => {
    require('../../assets/styles/html/radio.scss');
    return (
        <label className="radio">
            <input
                type="radio"
                checked={props.checked}
                onChange={props.onChange}
                className="radio__input"
                name={props.id}
                id={props.id}
            />
            <span className="radio__inner" />
        </label>
    );
};

export default RadioComponent;
