/* eslint-disable no-use-before-define */
import { useEffect, useRef } from 'react';

const CurrentDate = () => {
    require('../../assets/styles/header/current_date.scss');
    const displayTime = useRef<any>(null);
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
    const startTime = () => {
        const time = new Date();
        displayTime.current.innerText = time.toLocaleTimeString('en-US', { hour12: false });
        timeoutRef.current = setTimeout(showTime, 1000);
    };
    function showTime() {
        if (timeoutRef.current) {
            if (displayTime.current) {
                startTime();
            } else {
                clearTimeout(timeoutRef.current);
            }
        } else {
            startTime();
        }
    }

    // Date
    const updateDate = () => {
        const today = new Date();

        // return number
        const dayName = today.getDay();
        const dayNum = String(today.getDate()).length === 1 ? `0${String(today.getDate())}` : String(today.getDate());
        const month = today.getMonth();
        const year = today.getFullYear();
        const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
        const dayWeek = [
            'Воскресенье',
            'Понедельник',
            'Вторник',
            'Среда',
            'Четверг',
            'Пятница',
            'Суббота',
        ];
        // value -> ID of the html element
        const IDCollection = ['day', 'daynum', 'month', 'year'];
        // return value array with number as a index
        const val = [dayWeek[dayName], dayNum, months[month], year];
        for (let i = 0; i < IDCollection.length; i++) {
            const elem = document.getElementById(IDCollection[i]);
            if (elem && elem.firstChild) {
                elem.firstChild.nodeValue = String(val[i]);
            }
        }
    };
    useEffect(() => {
        showTime();
        updateDate();
    }, []);
    return (
        <div className="nav__date">
            <span className="nav__today" />
            <span className="nav__day">
                <span id="day">1 </span>
                <span id="space"> </span>
                <span id="daynum">1</span>
                <span id="dot">.</span>
                <span id="month">1</span>
                <span id="dot">.</span>
                <span id="year">1</span>
                <span id="dot"> г. </span>
                <span className="time" ref={displayTime}> </span>
            </span>

        </div>
    );
};

export default CurrentDate;
