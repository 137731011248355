/* eslint-disable no-unused-expressions */
import CloseTaskSvg from '../../assets/svgs/CloseTaskSvg';
import '../../assets/styles/modals/add_modal.scss';

interface IProps {
    onCloseModal: Function;
    onClickSave: Function;
    onClickSpaceZone?: Function;
    title?: string;
    confirmBtnText?: string;
    children: any;
}

const EditModal = ({
    onCloseModal, onClickSpaceZone, onClickSave, title, confirmBtnText, children,
}: IProps) => (
    <div
        className="add_modal_wrapper"
        onClick={() => onCloseModal()}
    >
        <div className="modal-dialog" onClick={(e) => { e.stopPropagation(); }}>
            <div className="modal">
                <div className="edit_modal">
                    {title ? (
                        <div className="title">{title}</div>
                    ) : ''}
                    {children}

                    <div className="empty_space" onClick={() => { onClickSpaceZone && onClickSpaceZone(); }} />
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn_close" onClick={() => onCloseModal()}>
                        <CloseTaskSvg />
                        Закрыть
                    </button>
                    <button
                        type="button"
                        onClick={() => onClickSave()}
                        className="btn btn_edit"
                    >
                        {confirmBtnText || 'Добавить'}
                    </button>
                </div>

            </div>
        </div>

    </div>

);

export default EditModal;
