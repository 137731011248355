/* eslint-disable jsx-a11y/label-has-associated-control */
import { memo } from 'react';
import styles from './UsersSelectItem.module.scss';
import { classNames } from '../../../../../../../utils/classNames/classNames';
import { HStack, VStack } from '../../../../../../../components/ui/Stack';
import { Skeleton } from '../../../../../../../components/ui/Skeleton/Skeleton';

interface SkeletonUsersSelectItemProps {
  className?: string;
}

export const SkeletonUsersSelectItem = memo((props: SkeletonUsersSelectItemProps) => {
    const { className } = props;
    return (
        <HStack max className={classNames(styles.UsersSelectItem, {}, [className])}>
            <input
                type="checkbox"
                disabled
                className={styles.checkbox}
                {...props}
            />

            <label className={styles.label}>
                <Skeleton height={50} width={50} border="50%" />

                <VStack gap="4" align="start">
                    <HStack max gap="8" align="center">
                        <Skeleton height={24} width={380} border="10px" />
                    </HStack>

                    <Skeleton height={19} width={100} border="10px" />

                </VStack>

            </label>

        </HStack>
    );
});
