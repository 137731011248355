const LockSvg = () => (
    <svg fill="#000000" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" strokeWidth="0" />
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
        <g id="SVGRepo_iconCarrier">
            <path d="M28 13.25h-2.277c-0.285-8.228-5.166-12-9.723-12s-9.438 3.772-9.723 12h-2.277c-0.414 0-0.75 0.336-0.75 0.75v0 16c0 0.414 0.336 0.75 0.75 0.75h24c0.414-0 0.75-0.336 0.75-0.75v0-16c-0-0.414-0.336-0.75-0.75-0.75v0zM16 2.75c2.267 0.027 4.282 1.082 5.604 2.72l0.011 0.014c1.613 2.103 2.585 4.772 2.585 7.668 0 0.035-0 0.069-0 0.104l0-0.005h-16.397c0.281-7.18 4.361-10.5 8.197-10.5zM27.25 29.25h-22.5v-14.5h22.5zM16 18.25c-0.414 0-0.75 0.336-0.75 0.75v0 6c0 0.414 0.336 0.75 0.75 0.75s0.75-0.336 0.75-0.75v0-6c-0-0.414-0.336-0.75-0.75-0.75v0z" />
            {' '}
        </g>
    </svg>
);

export default LockSvg;
