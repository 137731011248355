/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';
import { SelectArrowSvg } from '../../assets/svgs/SelectArrowSvg';
import { IMultySelectSearch } from '../../assets/interfaces/interfaces';
import '../../assets/styles/html/select_label_field.scss';

interface ILink {
    text: string,
    url: string,
}
interface IProps {
    id: string,
    title: string,
    link: ILink,
    placeholder: string,
    onChangeValues: Function,
    values: IMultySelectSearch[],
    spaceZoneClicked: boolean,
    multiple: boolean,
}
const SelectLabelField = ({
    title, values, multiple, placeholder, onChangeValues, id, link, spaceZoneClicked,
}: IProps) => {
    const [showSelect, setShowSelect] = useState<boolean>(false);
    const [input, setInput] = useState<string>('');

    const handleChange = (text: string) => {
        const filter = text.toUpperCase();
        const ul = document.getElementById(id);
        let valuesEl;
        let i;
        let txtValue;

        setInput(text);
        if (ul) {
            valuesEl = ul.getElementsByClassName('form_select__value');
            const newValues = [...values];
            // Loop through all list items, and hide those who don't match the search query
            for (i = 0; i < valuesEl.length; i++) {
                const newIndex = i % 2;
                const span = valuesEl[i];
                txtValue = span.textContent || span.innerHTML;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    newValues[newIndex].show = true;
                } else {
                    newValues[newIndex].show = false;
                }
            }
        }
    };
    const onSelect = (index: number) => {
        const newValues = [...values];
        if (!multiple) {
            newValues.forEach((val) => val.selected = false);
        }
        newValues[index].selected = !newValues[index].selected;
        onChangeValues(newValues, index);
    };
    const getSelectedValues = () => {
        const selected = [''];
        values.map((val) => {
            if (val.selected) {
                selected.push(val.title);
            }
        });
        if (selected.length > 1) {
            let names = selected.join(', ');
            names = `Выбрано: ${names.slice(2, names.length)}`;
            return names;
        }
    };
    const onOpenLink = () => {
        window.location.href = link.url;
    };
    useEffect(() => {
        setShowSelect(false);
    }, [spaceZoneClicked]);

    return (
        <div className="select_label_field">
            <div className="title_wrapper" onClick={() => setShowSelect(false)}>
                <h5 className="title">{title}</h5>
                <h5 className="link" onClick={() => onOpenLink()}>{link.text}</h5>
            </div>
            <div
                className={`select_label_field__form_select ${showSelect ? 'active' : ''}`}
            >
                <input
                    onFocus={() => { setShowSelect(true); }}
                    value={input}
                    onChange={(e) => { handleChange(e.target.value); }}
                    className="selected_input"
                    placeholder={placeholder}
                />
                <div className="icon" onClick={() => { setShowSelect(!showSelect); }}>
                    <SelectArrowSvg />
                </div>
            </div>
            <div
                id={id}
                className={`form_select__values ${showSelect ? 'active' : ''}`}
            >
                {values.map((val, index) => (
                    <>
                        <div
                            onClick={() => onSelect(index)}
                            className={`form_select__value${val.selected ? ' selected' : ''} ${val.show ? '' : 'hide'}`}
                        >
                            <span className="form_select__title">{val.title}</span>
                            <span className="subtitle">{val.subtitle}</span>
                        </div>
                        <span className="search_field">
                            {val.title}
                            {' '}
                            {val.subtitle}
                        </span>
                    </>
                ))}
            </div>

            <div className="selected_values">{getSelectedValues()}</div>
        </div>
    );
};
export default SelectLabelField;
