/* eslint-disable react/no-array-index-key */
import { memo } from 'react';
import styles from './Node.module.scss';
import { HStack, VStack } from '../../../../components/ui/Stack';
import { classNames, Mods } from '../../../../utils/classNames/classNames';
import { Skeleton } from '../../../../components/ui/Skeleton/Skeleton';
import { SkeletonUserItem } from './UserItem/SkeletonUserItem';

export interface ISkeletonNode {
  id: number,
  children: ISkeletonNode[]
}

interface SkeletonNodeProps {
  className?: string;
  node: ISkeletonNode;
  isChildren: boolean;
}

const getUserSkeletons = () => new Array(3).fill(0).map((item, index) => <SkeletonUserItem key={index} />);

export const SkeletonNode = memo((props: SkeletonNodeProps) => {
    const { className, isChildren, node } = props;

    const parentMode: Mods = {
        [styles.noneChildren]: node.children.length === 0,
        [styles.isChildren]: isChildren,
    };

    const childrenMode: Mods = {
        [styles.oneChild]: node.children.length === 1,
    };

    return (
        <VStack gap="32" className={styles.TreeNode}>
            <VStack align="center" gap="16" className={classNames(styles.NodeWrapper, parentMode, [className])}>
                <HStack align="start" gap="16" justify="between" className={styles.Node}>
                    <VStack align="start" gap="16" max>
                        <Skeleton width="80%" height={20} border="20px" />

                        <VStack gap="4" align="start" max>
                            <Skeleton width="80%" height={5} border="20px" />
                            <Skeleton width="80%" height={5} border="20px" />
                            <Skeleton width="80%" height={5} border="20px" />
                        </VStack>

                        <Skeleton width="100px" height={10} border="20px" />

                        <ul className={styles.list}>
                            {
                                getUserSkeletons()
                            }
                        </ul>
                    </VStack>

                    <VStack gap="16" align="start" justify="center" className={styles.actions}>
                        <Skeleton height={20} width={20} border="50%" />
                        <Skeleton height={20} width={20} border="50%" />
                        <Skeleton height={20} width={20} border="50%" />
                        <Skeleton height={20} width={20} border="50%" />
                        <Skeleton height={20} width={20} border="50%" />
                    </VStack>
                </HStack>
            </VStack>

            {
                node.children.length > 0 && (
                    <HStack max align="start" gap="32" justify="center" className={classNames(styles.childrenContainer, childrenMode, [])}>
                        {node.children.map((item) => (
                            <SkeletonNode node={item} isChildren />
                        ))}
                    </HStack>
                )
            }
        </VStack>
    );
});
