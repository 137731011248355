const ExitSvg = () => (
    <svg fill="#000000" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" />
        <g id="SVGRepo_tracerCarrier" />
        <g id="SVGRepo_iconCarrier">
            {' '}
            <title>arrow-right-from-bracket</title>
            {' '}
            <path d="M10 29.25h-7.25v-26.5h7.25c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0h-8c-0.414 0-0.75 0.336-0.75 0.75v0 28c0 0.414 0.336 0.75 0.75 0.75h8c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0zM30.713 15.91c0-0.009 0.001-0.020 0.001-0.030 0-0.059-0.007-0.117-0.021-0.172l0.001 0.005c-0.026-0.063-0.084-0.099-0.124-0.151-0.021-0.028-0.013-0.067-0.038-0.093l-5-5c-0.136-0.137-0.325-0.222-0.533-0.222-0.415 0-0.751 0.336-0.751 0.751 0 0.208 0.085 0.396 0.221 0.532l3.72 3.72h-20.188c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h20.189l-3.721 3.719c-0.136 0.136-0.22 0.324-0.22 0.531 0 0.415 0.336 0.751 0.751 0.751 0.207 0 0.395-0.084 0.531-0.22l5-5.001c0.116-0.125 0.189-0.291 0.195-0.473l0-0.001c0-0.003 0-0.007 0-0.011 0-0.048-0.005-0.094-0.015-0.139l0.001 0.004z" />
            {' '}
        </g>
    </svg>
);

export default ExitSvg;
