/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import styles from './OrgSchemaModule.module.scss';
import Tree from './Tree/Tree';
import { SchemaHeader } from './components/SchemaHeader/SchemaHeader';
import { StartSchema } from './components/StartSchema/StartSchema';
import { CreateSchemaModal } from './components/CreateSchemaModal/CreateSchemaModal';
import { useOrgSchema } from './useOrgSchema';
import { SkeletonTree } from './Tree/SkeletonTree';
import { skeletonDataTree } from './data/data';
import { useSchemasStore } from './store/schemas';

const OrgSchemaModule = () => {
    const [createSchemaModal, setCreateSchemaModal] = useState(false);

    const setIsEmptySchema = useSchemasStore((state) => state.setIsEmptySchema);
    const isEmptySchema = useSchemasStore((state) => state.isEmptySchema);

    const {
        schemaTreeData,
        isLoadingSchemaTree,
        isSuccessSchemaTreeById,
        isLoadingAllSchemaTree,
    } = useOrgSchema();

    const onCloseCreateSchemaModal = () => {
        setCreateSchemaModal(false);
    };

    const onOpenCreateSchemaModal = () => {
        setCreateSchemaModal(true);
    };

    useEffect(() => {
        if (!schemaTreeData) {
            setIsEmptySchema(true);
        } else {
            setIsEmptySchema(false);
        }
    }, [isSuccessSchemaTreeById]);

    return (
        <Layout>
            <div className={styles.OrgSchema}>

                <SchemaHeader isLoading={isLoadingAllSchemaTree} onOpenCreateSchemaModal={onOpenCreateSchemaModal} />

                {
                    isLoadingSchemaTree ? (<SkeletonTree node={skeletonDataTree} />)
                        : !schemaTreeData || isEmptySchema ? (<StartSchema onOpenCreateSchemaModal={onOpenCreateSchemaModal} />)
                            : (<Tree treeNode={schemaTreeData} />)
                }

                <CreateSchemaModal
                    isOpen={createSchemaModal}
                    onClose={onCloseCreateSchemaModal}
                />
            </div>
        </Layout>
    );
};

export default OrgSchemaModule;
