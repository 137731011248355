import { useEffect, useState } from 'react';

interface INews {
    number: number,
    title: string,
    bg: string,
    content:string,
}
interface IProps {
    news: INews
}
const UserBlockNews = ({ news }:IProps) => {
    require('../../assets/styles/user/newsblock.scss');
    const [bg, setBg] = useState<string>('');
    useEffect(() => {
        setBg(news.bg);
    }, [news]);
    return (
        <div className="newsblock">
            <div className="newsblock__content">
                <div className="number">
                    #
                    {news.number}
                </div>
                <div className="title">{news.title}</div>
                <div className="desc">{news.content}</div>
            </div>
            <div className="newsblock__overlay" />
            <div
                className="newsblock__bg"
                style={{
                    backgroundImage: `url(${bg})`,
                }}
            />

        </div>
    );
};

export default UserBlockNews;
