const SuccessModal = () => {
    require('../../assets/styles/modals/success_modal.scss');
    return (
        <section>
            <span className="overlay" />
            <div className="modal-box ">
                <h2>Успешно</h2>
                <h3>Запрос на регистрацию отправлен</h3>

                {/* <div className="buttons">
                        <button className="close-btn">Хорошо</button>
                    </div> */}
            </div>
        </section>

    );
};

export default SuccessModal;
