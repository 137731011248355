const UserWeekend = () => {
    require('../../assets/styles/user/weekend.scss');
    return (
        <div className="weekend_container">
            <div className="title">Предстоящий отпуск</div>
            <div className="content">
                <div className="date">
                    <div className="date__contaiter">
                        <span>с:</span>
                        <span className="day">19.08.2024</span>
                    </div>
                    <div className="date__contaiter">
                        <span>по:</span>
                        <span className="day">01.09.2024</span>
                    </div>
                </div>

                <span className="weekend_container__after_weekend">14 дней</span>
            </div>
        </div>
    );
};

export default UserWeekend;
