/* eslint-disable react/destructuring-assignment */
interface IProps {
    children: any,
    id?: string,
    value: string,
    onSelect: React.ReactEventHandler<HTMLSelectElement>,
}
const SelectDropdown = (props: IProps) => {
    require('../../assets/styles/html/SelectDropdown.scss');
    return (
        <select
            className="dropdown"
            onSelect={(e) => props.onSelect(e)}
            value={props.value}
            name={props.id}
            id={props.id}
        >
            {props.children}
        </select>
    );
};

export default SelectDropdown;
