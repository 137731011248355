/* eslint-disable react/destructuring-assignment */
const AdminTableLayout = (props: any) => {
    require('../../../assets/styles/admin/admin_table_layout.scss');
    return (
        <table
            id={props.id}
            className={`admin_table_layout ${props.className}`}
            border={1}
            cellSpacing={0}
        >
            <thead>
                {props.children[0]}
            </thead>
            {props.children[1]}
        </table>
    );
};

export default AdminTableLayout;
