import { useState } from 'react';
import UserAvatarDefault from '../../../assets/svgs/UserAvatarDefault';
import TrashSvg from '../../../assets/svgs/TrashSvg';
import EditSquareSvg from '../../../assets/svgs/EditSquareSvg';
import { hrefToFile } from '../../../utils/func';
import '../../../assets/styles/modules/components/comment_tab_details_modal.scss';
import FileTaskDeleteSvg from '../../../assets/svgs/FileTaskDeleteSvg';
import FileTaskSvg from '../../../assets/svgs/FileTaskSvg';
import FileTaskIconSvg from '../../../assets/svgs/FileTaskIconSvg';

import { IDocComment } from '../../../assets/interfaces/modules/doc_interface';
import { IOpenFile } from '../../../assets/interfaces/interfaces';

interface ITaskEdit {
    comments: IDocComment[];
    onEditComment: Function;
    onDeleteComment: Function;
    onAddComment: Function;
    onChangeComment: Function;
    addCommentAvalible: boolean;
}
const CommentTab = ({
    onEditComment, addCommentAvalible, comments, onDeleteComment, onAddComment, onChangeComment,
}: ITaskEdit) => {
    const [commentText, setCommentText] = useState<string>('');
    const [newFiles, setNewFiles] = useState<FileList>();
    const [openFiles, setOpenFiles] = useState<IOpenFile[]>([]);

    const clearInputFile = () => {
        setNewFiles(undefined);
    };
    const addCommentHandler = (commentText: string) => {
        if (commentText.length === 0) {
            return;
        }
        const formData = new FormData();
        formData.append('text', commentText);
        if (newFiles) {
            for (let i = 0; i < newFiles.length; i++) {
                const file = newFiles[i];
                formData.append(String(i), file);
            }
        }
        clearInputFile();
        setCommentText('');
        onAddComment(formData, commentText);
    };
    const onClickAddFiles = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;
        input.click();

        input.addEventListener('change', (e) => {
            const target = e.target as HTMLInputElement;
            const { files } = target;

            if (files) {
                setNewFiles(files);
            }
        });
    };
    const editCommentHandler = (comment_index: number) => {
        if (comments[comment_index].edit === true) {
            const formData = new FormData();
            formData.append('text', comments[comment_index].text);
            onEditComment(comment_index, formData);
        } else {
            onEditComment(comment_index);
        }
    };

    return (
        <div className="comment_tab_details_wrapper">
            <div className="comment_container">
                {addCommentAvalible && (
                    <div className="create_comment">
                        <span className="avatar">
                            <UserAvatarDefault />
                        </span>
                        <div className="comment_form">
                            <textarea
                                placeholder="Ввести комментарий..."
                                className="comment_textarea"
                                rows={3}
                                contentEditable
                                value={commentText}
                                onChange={(e) => setCommentText(e.target.value)}
                            />
                            <div className="bottom">
                                <button
                                    type="button"
                                    onClick={() => { addCommentHandler(commentText); }}
                                    className="btn_add"
                                >
                                    Добавить
                                </button>
                                <div className="right">

                                    <div className="files">
                                        {newFiles?.length ? `Выбранно файлов: ${newFiles.length}` : ''}
                                    </div>
                                    <div className="icons">
                                        <span className="icon" onClick={(e) => onClickAddFiles()}>
                                            <FileTaskSvg />
                                        </span>

                                        {newFiles?.length ? (
                                            <span className="icon" onClick={(e) => clearInputFile()}>
                                                <FileTaskDeleteSvg />
                                            </span>
                                        ) : ''}
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                )}

                {comments.length ? (
                    <div className="comments">
                        {comments.map((comment, index) => (
                            <div className="comment_wrapper">
                                <span className="avatar">
                                    <UserAvatarDefault />
                                </span>
                                <div className="content">
                                    <div className="comment">
                                        {comment.edit ? (
                                            <textarea
                                                value={comment.text}
                                                onChange={(e) => onChangeComment(index, e.target.value)}
                                                className="comment_input"
                                            />
                                        ) : (
                                            <>
                                                <span className="author">{comment.user_id}</span>
                                                <span className="text">{comment.text}</span>
                                            </>
                                        )}

                                    </div>
                                    {comment.files && (
                                        <div className="files">
                                            {comment.files.map((file, index) => (
                                                <div className="file" onClick={() => hrefToFile(100, file.path, comment.id)}>
                                                    <div className="icon">
                                                        <FileTaskIconSvg />
                                                    </div>
                                                    <div className="info">
                                                        <div className="title">{file.path}</div>
                                                    </div>
                                                </div>

                                            ))}
                                        </div>
                                    )}

                                    <div className="actions">
                                        <span className="action" onClick={() => editCommentHandler(index)}>Редактировать</span>
                                        <span className="dot">•</span>
                                        <span className="action" onClick={() => onDeleteComment(index)}>Удалить</span>
                                        <span className="dot">•</span>
                                        <span className="date">{comment.created_at}</span>
                                    </div>
                                </div>
                            </div>
                        )).reverse()}

                    </div>
                ) : (
                    <span className="empty">Комметариев нет</span>
                )}

            </div>

        </div>
    );
};

export default CommentTab;
