import { create } from 'zustand';
import { IUser, UserRole, UserStatus } from '../types/orgschema';

export interface UsersStore {
  signUsers: IUser[];
  currentUser: IUser;
  searchTerm: string;

  addUser: (user: IUser) => void;
  setCurrentUser: (user: IUser) => void;
  removeUser: (userId: string) => void;
  clearUsers: () => void;
  setSearchTerm: (term: string) => void;
}

export const useUsersStore = create<UsersStore>((set) => ({
    signUsers: [],
    searchTerm: '',
    currentUser: {
        id: 'test',
        fullName: 'Пользователь не найден',
        email: '--',
        position: '-',
        workPhone: '-',
        phone: '-',
        birthday: '-',
        vksLink: '-',
        avatarPath: 'https://www.svgrepo.com/show/420315/avatar-cloud-crying.svg',
        status: UserStatus.FREE,
        updatedAt: '-',
        role: UserRole.USER,
    },

    addUser: (user: IUser) => set((state) => ({
        signUsers: [...state.signUsers, user],
    })),

    setSearchTerm: (term: string) => {
        set({ searchTerm: term }); // Сбросить на первую страницу при изменении поиска
    },
    removeUser: (userId: string) => set((state) => ({
        signUsers: state.signUsers.filter((user) => user.id !== userId),
    })),

    clearUsers: () => set(() => ({
        signUsers: [],
    })),
    setCurrentUser: (user) => set(() => ({
        currentUser: user,
    })),
}));
