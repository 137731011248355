/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
interface IProps {
    onAdd: React.MouseEventHandler<HTMLButtonElement>,
    data?: any,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
}
const AdminAddPosition = (props: IProps) => {
    require('../../../assets/styles/admin/admin_add_position.scss');
    return (
        <div className="admin_add_positon ">
            <div className="admin_add_positon__row">
                <div className="field">
                    <label htmlFor="nameposition">Название должности</label>
                    <input
                        type="text"
                        value={props.data}
                        onChange={props.onChange}
                        name="nameposition"
                        id="nameposition"
                        placeholder=""
                    />
                </div>
                <div className="admin_add_positon__send_btn">
                    <button type="button" onClick={props.onAdd}>Добавить</button>
                </div>
            </div>

        </div>

    );
};

export default AdminAddPosition;
