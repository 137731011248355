import {
    ChangeEventHandler, memo, useEffect, useRef,
} from 'react';
import styles from './RangeInput.module.scss';
import { HStack, VStack } from '../Stack';
import { classNames } from '../../../utils/classNames/classNames';
import { Text, TextBold, TextSize } from '../Text/Text';

interface RangeInputProps {
  className?: string;
  title?: string;
  max: number;
  min: number;
  step: number;
  value: number;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export const RangeInput = memo(({
    className, title, max, min, step, value, onChange,
}: RangeInputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const percentage = ((value - min) / (max - min)) * 100;

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.style.setProperty('--value', `${percentage}%`);
        }
    }, [percentage]);

    return (
        <VStack align="start" max className={classNames(styles.RangeInput, {}, [className])}>

            {
                title && (
                    <Text
                        gap="0"
                        size={TextSize.S}
                        textPrimary
                        bold={TextBold.MEDIUM}
                        title={title}
                    />
                )
            }

            <HStack max gap="50" className={styles.field}>
                <input
                    type="range"
                    className={styles.input}
                    value={value}
                    onChange={onChange}
                    max={max}
                    min={min}
                    step={step}
                    ref={inputRef}
                />
            </HStack>

        </VStack>
    );
});
