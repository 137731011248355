import { ISkeletonNode } from '../Node/SkeletonNode';
import { UserItemProps } from '../Node/UserItem/UserItem';
import { TestData } from '../types/orgschema';

export const currentUser = 'Шабанов Артём Александрович';

export const dataTreeArray: TestData = {
    id: 1,
    title: 'Main block',
    description: 'Main block description test',
    parent: null,
    children: [
        {
            id: 2,
            title: 'Child block',
            description: 'Child block description test',
            parent: { id: 1 },
            children: [
                {
                    id: 3,
                    title: 'Grandchild block',
                    description: 'Grandchild block description test',
                    parent: { id: 2 },
                    children: [],
                },
            ],
        },
        {
            id: 4,
            title: 'Child block',
            description: 'Child block description test',
            parent: { id: 1 },
            children: [
                {
                    id: 5,
                    title: 'Grandchild block',
                    description: 'Grandchild block description test',
                    parent: { id: 4 },
                    children: [],
                },
                {
                    id: 10,
                    title: 'Grandchild block',
                    description: 'Grandchild block description test',
                    parent: { id: 4 },
                    children: [
                        {
                            id: 112,
                            title: 'Grand Grandchild block',
                            description: 'Grandchild block description test',
                            parent: { id: 10 },
                            children: [],
                        },
                    ],
                },
                {
                    id: 11,
                    title: 'Grandchild block',
                    description: 'Grandchild block description test',
                    parent: { id: 4 },
                    children: [],
                },
            ],
        },
        {
            id: 6,
            title: 'Child block',
            description: 'Child block description test',
            parent: { id: 1 },
            children: [

            ],
        },
        {
            id: 7,
            title: 'Child block',
            description: 'Child block description test',
            parent: { id: 1 },
            children: [

            ],
        },
        {
            id: 8,
            title: 'Child block',
            description: 'Child block description test',
            parent: { id: 1 },
            children: [

            ],
        },
    ],
};

export const skeletonDataTree: ISkeletonNode = {
    id: 1,
    children: [
        {
            id: 2,
            children: [
                {
                    id: 3,
                    children: [],
                },
                {
                    id: 4,
                    children: [],
                },
            ],
        },
        {
            id: 5,
            children: [
                {
                    id: 6,
                    children: [],
                },
                {
                    id: 7,
                    children: [],
                },
            ],
        },
    ],
};
