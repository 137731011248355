const CommentSvg = () => (
    <svg fill="#000000" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" strokeWidth="0" />
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
        <g id="SVGRepo_iconCarrier">
            <title>message-lines</title>
            <path d="M30 1.25h-28c-0.414 0-0.75 0.336-0.75 0.75v0 22c0 0.414 0.336 0.75 0.75 0.75h7.25v5.25c0 0.292 0.167 0.545 0.411 0.668l0.004 0.002c0.096 0.050 0.21 0.080 0.331 0.080 0.001 0 0.003 0 0.004 0h-0c0.17-0 0.326-0.057 0.452-0.152l-0.002 0.001 7.8-5.85h11.75c0.414-0 0.75-0.336 0.75-0.75v0-22c-0-0.414-0.336-0.75-0.75-0.75v0zM29.25 23.25h-11.25c-0.17 0-0.326 0.057-0.452 0.152l0.002-0.001-6.8 5.1v-4.5c-0-0.414-0.336-0.75-0.75-0.75h-7.25v-20.5h26.5zM6 9.75h20c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0h-20c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0zM6 16.75h10c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0h-10c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0z" />
        </g>
    </svg>
);

export default CommentSvg;
