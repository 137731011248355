export function docGetTextStatus(status:number) {
    let txt_status = '';
    if (status === 0) {
        txt_status = 'Согласован';
    } else if (status === 1) {
        txt_status = 'Отмена';
    } else if (status === 2) {
        txt_status = 'В процессе';
    } else if (status === 3) {
        txt_status = 'На согласовании';
    } else if (status === 4) {
        txt_status = 'Сохранен';
    }
    return txt_status;
}
