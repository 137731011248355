import { create } from 'zustand';

interface FormData {
  mailLink?: string;
  cloudLink?: string;
  audioUrl?: string;
  videoUrl?: string;
  fileUrl?: string;
}

interface FormStore {
  formData: FormData;
  setFormData: (data: FormData) => void;
  clearFormData: () => void;
}

export const useFormStore = create<FormStore>((set) => ({
    formData: {},
    setFormData: (data) => set((state) => ({
        formData: { ...state.formData, ...data },
    })),
    clearFormData: () => set(() => ({
        formData: {},
    })),
}));
