import Layout from '../../components/Layout';
import UserTasksPage from '../../components/Task/UserTasksPage';

const TasksModule = () => {
    require('../../assets/styles/user/user.scss');

    return (
        <Layout>
            <div className="user_content">

                <div className="user_content__container">
                    <UserTasksPage />
                </div>

            </div>
        </Layout>
    );
};

export default TasksModule;
