import { Modal } from '../../../../../components/ui/Modal/Modal';
import { VStack } from '../../../../../components/ui/Stack';
import { Text, TextBold, TextSize } from '../../../../../components/ui/Text/Text';
import { classNames } from '../../../../../utils/classNames/classNames';
import { CreateSchemaForm } from './CreateSchemaForm';
import styles from './CreateSchemaModal.module.scss';

interface CreateSchemaModalProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
}

export const CreateSchemaModal = ({ className, isOpen, onClose } : CreateSchemaModalProps) => (
    <Modal lazy className={classNames(styles.CreateSchemaModal, {}, [className])} isOpen={isOpen} onClose={onClose}>
        <VStack max align="start" gap="16">
            <Text gap="16" size={TextSize.XL} bold={TextBold.BOLD} textPrimary text="Создать новую схему" />
            <CreateSchemaForm onSuccess={onClose} />
        </VStack>
    </Modal>
);
