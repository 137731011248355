import { useState } from 'react';
import '../../../../../assets/styles/modules/components/details_modal.scss';
import { ITextActive } from '../../../../../assets/interfaces/interfaces';
import CloseTaskSvg from '../../../../../assets/svgs/CloseTaskSvg';
import EditTaskSvg from '../../../../../assets/svgs/EditTaskSvg';

interface IProps {
    onCloseModal: Function;
    tabs: ITextActive[];
    onClickTab: Function;
    onSave: Function;
    onClickSpaceZone: Function;
    editAvalible: boolean;
    children: any;
}
const DetailsModal = ({
    onCloseModal, tabs, onClickTab, onSave, onClickSpaceZone, editAvalible, children,
}: IProps) => {
    const [edit, setEdit] = useState<boolean>(false);
    const onClickSave = () => {
        if (edit) {
            onSave();
        }
        setEdit(!edit);
    };
    return (
        <div
            className="details_modal_wrapper"
            onClick={() => onCloseModal()}
        >
            <div className="modal-dialog" onClick={(e) => { e.stopPropagation(); }}>
                <div className="modal">
                    {edit ? (
                        <div className="edit_modal">
                            {children[0]}
                            <div className="empty_space" onClick={() => onClickSpaceZone()} />
                        </div>
                    ) : (
                        <div className="modal_content">
                            <div className="modal_header_wrapper">
                                <div className="modal_header" />
                            </div>
                            {tabs.length > 0 ? (
                                <div className="modal_tabs">
                                    {tabs.map((tab, index) => (
                                        <div
                                            onClick={() => onClickTab(index)}
                                            className={`tab ${tab.active && 'active'}`}
                                        >
                                            {tab.text}
                                        </div>
                                    ))}
                                </div>
                            ) : ''}

                            <div className="modal_content">
                                <div className="modal_info">
                                    {children[1]}

                                </div>
                            </div>

                        </div>
                    )}

                    <div className="modal-footer">
                        <button type="button" className="btn btn_close" onClick={() => onCloseModal()}>
                            <CloseTaskSvg />
                            Закрыть
                        </button>
                        {editAvalible && (
                            <button
                                type="button"
                                onClick={() => { onClickSave(); }}
                                className="btn btn_edit"
                            >
                                {edit ? 'Сохранить' : (
                                    <>
                                        Редактировать
                                        <EditTaskSvg />
                                    </>
                                )}

                            </button>
                        )}
                    </div>

                </div>
            </div>

        </div>
    );
};
export default DetailsModal;
