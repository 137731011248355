import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Toaster } from 'react-hot-toast';
import AppRouter from './components/AppRouter';

const queryClient = new QueryClient();

const App = observer(() => (
    <BrowserRouter basename="/">
        <QueryClientProvider client={queryClient}>

            <Toaster
                position="top-center"
                toastOptions={{
                    duration: 5000,
                }}
            />
            <AppRouter />

            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    </BrowserRouter>
));

export default App;
