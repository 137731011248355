import { useState } from 'react';
import MagnifierSvg from '../../../../../assets/svgs/MagnifierSvg';
import '../../../../../assets/styles/modules/components/doc/input/doc_input_search.scss';

interface IProps {
    onSearch: Function;
}
const InputSearch = ({ onSearch }: IProps) => {
    const [input, setInput] = useState<string>('');

    return (
        <div className="input_search">
            <input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                className="form_control"
                name="table_search"
                placeholder="Поиск..."
            />
            <div className="input_search_append">
                <button
                    type="submit"
                    className="btn_search"
                    onClick={() => onSearch(input)}
                >
                    <MagnifierSvg />
                </button>
            </div>
        </div>
    );
};

export default InputSearch;
