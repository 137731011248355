import '../../assets/styles/header/toggler_button.scss';

const TogglerButton = () => (
    <button type="button" className="navbar-toggler">
        <span />
        <span />
        <span />
    </button>
);

export default TogglerButton;
