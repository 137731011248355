export const STARTER_ROUTE = '/';
export const SESSION_ROUTE = '/auth/sso';
export const HELLO_ROUTE = '/hello';
export const LOGIN_ROUTE = '/auth/login';
export const REGISTER_ROUTE = '/users/register';
export const HOME_ROUTE = '/home';
export const ADMIN_ROUTE = '/admin';
export const USER_ROUTE = '/users/:id/:module';
export const TASKS_ROUTE = '/tasks/:id';
export const ORG_SCHEMA = '/orgschema';
export const DOC_ROUTE = '/docs/:tab/:id';
