import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { SchemaData } from './types/orgschema';
import { OrgSChemaService } from './services/OrgSchema.service';
import { useSchemasStore } from './store/schemas';

export const useOrgSchema = () => {
    const activeSchemaId = useSchemasStore((state) => state.activeSchemaId);

    const setActiveSchema = useSchemasStore((state) => state.setActiveSchema);
    const setSchemas = useSchemasStore((state) => state.setSchemas);
    const {
        data: schemaTreeData,
        isLoading: isLoadingSchemaTree,
        isError: isErrorSchemaTree,
        error: schemaTreeError,
        isSuccess: isSuccessSchemaTreeById,
    } = useQuery<SchemaData>({
        queryKey: ['orgSchema', activeSchemaId],
        queryFn: () => OrgSChemaService.getSchemaTreeById(activeSchemaId),
    });

    const {
        data: allSchemaTreeData,
        isLoading: isLoadingAllSchemaTree,
        isError: isErrorAllSchemaTree,
        error: allSchemaTreeError,
        isSuccess: isSuccessAllSchemaTree,
    } = useQuery<SchemaData[]>({
        queryKey: ['allOrgSchemaTree'],
        queryFn: () => OrgSChemaService.getAllSchemaTree(),
        select: (data) => {
            setSchemas(data);

            // Убедитесь, что активная схема устанавливается только один раз
            if (data && data.length > 0 && !activeSchemaId) {
                setActiveSchema(data[0].id);
            }

            return data;
        },
    });

    return useMemo(() => ({
        schemaTreeData,
        allSchemaTreeData,
        isLoadingSchemaTree,
        isLoadingAllSchemaTree,
        isErrorSchemaTree,
        isErrorAllSchemaTree,
        schemaTreeError,
        allSchemaTreeError,
        isSuccessAllSchemaTree,
        isSuccessSchemaTreeById,
    }), [
        schemaTreeData,
        allSchemaTreeData,
        isSuccessSchemaTreeById,
        isLoadingSchemaTree,
        isLoadingAllSchemaTree,
        isErrorSchemaTree,
        isErrorAllSchemaTree,
        schemaTreeError,
        allSchemaTreeError,
        isSuccessAllSchemaTree,
    ]);
};
