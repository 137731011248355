/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
    ADMIN_ROUTE, DOC_ROUTE, HELLO_ROUTE, LOGIN_ROUTE, ORG_SCHEMA, REGISTER_ROUTE, STARTER_ROUTE, TASKS_ROUTE, USER_ROUTE,
} from '../utils/consts';
import { getCookie } from '../utils/cookies';
import { getUser, logout } from '../http/userAPI';
import { MAIN_MODULE, TASKS_MODULE } from '../utils/modules';
import ExitSvg from '../assets/svgs/ExitSvg';
import { SelectArrowSvg } from '../assets/svgs/SelectArrowSvg';

const Header = observer(() => {
    require('../assets/styles/header/header.scss');

    interface IModule {
        text: string, route: string, active: boolean,

    }

    const navigate = useNavigate();

    const [isStarterPage, setIsStarterPage] = useState(false);
    const [showModules, setShowModules] = useState(false);
    const [showNavbar, setShowNavbar] = useState(false);
    const [currentPage, setCurrentPage] = useState({ main: false, modules: false, admin: false });
    const [modules, setModules] = useState<IModule[]>([
        { text: 'Задачи', route: TASKS_ROUTE, active: false },
        { text: 'Документооборот', route: DOC_ROUTE, active: false },
        { text: 'Оргсхема', route: ORG_SCHEMA, active: false },
    ]);

    const [currentModule, setCurrentModule] = useState({ tasks: false });
    const toPage = (page: string) => {
        navigate(page);
    };
    const onExit = async () => {
        logout().then((resp: any) => {
            navigate(HELLO_ROUTE);
        });
    };

    const getModule = (name: string): string => {
        const routeSpit = USER_ROUTE.split('/');
        routeSpit.shift();
        const userId = getCookie('userId');
        let route = '/';
        if (userId) {
            routeSpit[1] = userId;
            routeSpit[2] = name;
            route = `/${routeSpit.join('/')}`;
        }
        return route;
    };
    const isModuleOpen = () => {
        let isOpen = false;
        modules.forEach((module) => { if (module.active) { isOpen = true; } });
        return isOpen;
    };
    const openPage = (index: number) => {
        const newModules = [...modules];
        newModules.forEach((module) => module.active = false);
        const module = newModules[index];
        module.active = true;
        setModules(newModules);
        console.log('module', module.route.split('/'));
        console.log();
        const splittedRoute = module.route.split('/');
        if (splittedRoute) {
            console.log('splittedRoute[splittedRoute.length - 1]', splittedRoute[splittedRoute.length - 1]);
            if (splittedRoute[splittedRoute.length - 1] === ':id') {
                splittedRoute.shift();
                splittedRoute[1] = String(0);
                const route = `/${splittedRoute.join('/')}`;
                console.log('route', route);
                navigate(route);
                return;
            }
            navigate(module.route);
        }
    };
    useEffect(() => {
        const route = window.location.pathname.slice(1, window.location.pathname.length).split('/');
        const taskRoute = TASKS_ROUTE.split('/');
        const docRoute = DOC_ROUTE.split('/');
        const newModules = [...modules];
        if (route[0] === 'hello') {
            setIsStarterPage(true);
        } else {
            setIsStarterPage(false);
        }
        if (route[0] === 'users') {
            setCurrentPage((prev) => ({ ...prev, main: true }));
        } else if (route[0] === 'admin') {
            setCurrentPage((prev) => ({ ...prev, admin: true }));
        } else if (route[0] === taskRoute[1]) {
            newModules[0].active = true;
        } else if (route[0] === docRoute[1]) {
            newModules[1].active = true;
        }
        setModules(newModules);
        if (route) {
            setCurrentModule((prev) => ({ ...prev, [route[0]]: true }));
        }
    }, []);

    return (
        <nav className="navbar">
            <div className="navbar__container">

                <div className="navbar-header">
                    <button type="button" className="navbar-toggler" onClick={() => setShowNavbar(!showNavbar)}>
                        <span />
                        <span />
                        <span />
                    </button>
                    <h4 onClick={() => toPage(STARTER_ROUTE)}>
                        Трансмаш
                        <span>прибор</span>
                    </h4>
                </div>
                <div className={`navbar-menu ${showNavbar && 'active'}`}>

                    <ul className="navbar-nav">
                        {isStarterPage ? (
                            <>
                                <li>
                                    <div className="link">
                                        <Link to={LOGIN_ROUTE}>Вход</Link>
                                    </div>
                                </li>
                                <li>
                                    <div className="link">
                                        <Link to={REGISTER_ROUTE}>Регистрация</Link>
                                    </div>
                                </li>
                            </>

                        ) : (
                            <>
                                <li className={`${currentPage.main && 'active'}`}>
                                    <div className="link">
                                        <Link to={getModule(MAIN_MODULE)}>Кабинет</Link>
                                    </div>
                                </li>
                                <li className={`navbar-dropdown ${isModuleOpen() && 'active'}`}>
                                    <div
                                        className="dropdown-toggler"
                                        onClick={() => setShowModules(!showModules)}
                                    >
                                        Модули
                                        <span className={`arrow ${showModules && 'show'}`}><SelectArrowSvg /></span>
                                    </div>
                                    <ul className={`dropdown ${showModules && 'show'}`}>
                                        {modules.map((module, index) => (
                                            <li>
                                                <div
                                                    onClick={() => openPage(index)}
                                                    className={`link ${module.active ? 'active' : ''}`}
                                                >
                                                    {module.text}
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                                <li className={`${currentPage.admin ? 'active' : ''}`}>
                                    <div className="link">
                                        <Link to={ADMIN_ROUTE}>Администрирование</Link>
                                    </div>
                                </li>
                                <li><div className="link" onClick={onExit}><a href="#">Выход</a></div></li>
                            </>
                        )}

                    </ul>
                </div>
            </div>
        </nav>
    );
});

export default Header;
