import { memo } from 'react';
import styles from './StartSchema.module.scss';
import { classNames } from '../../../../../utils/classNames/classNames';
import { HStack, VStack } from '../../../../../components/ui/Stack';
import { OrgSchemaEmptyBg } from '../../../../../assets/svgs/orgschema/OrgSchemaEmptyBg';
import { Text, TextSize } from '../../../../../components/ui/Text/Text';
import { Button, ThemeButton } from '../../../../../components/ui/Button/Button';

interface StartSchemaProps {
  className?: string;
  onOpenCreateSchemaModal: () => void;
}

export const StartSchema = memo((props: StartSchemaProps) => {
    const { className, onOpenCreateSchemaModal } = props;
    return (
        <HStack gap="50" max justify="center" align="center" className={classNames(styles.StartSchema, {}, [className])}>
            <OrgSchemaEmptyBg size={600} className={styles.bg} />

            <VStack gap="32" align="start">
                <Text title="Схема организации" size={TextSize.XL} className={styles.text} text="Схема организации — это визуальное представление структуры компании, демонстрирующее иерархию и взаимосвязи между различными отделами и сотрудниками. Она помогает понять распределение ролей и обязанностей, а также облегчает процесс коммуникации внутри организации. Используя данную схему, вы сможете быстро ориентироваться в структуре компании, выявлять ключевых сотрудников и их функции, а также эффективно планировать ресурсы и управлять проектами." gap="16" />

                <Button onClick={onOpenCreateSchemaModal} theme={ThemeButton.DEFAULT}>Создание схемы</Button>
            </VStack>

        </HStack>
    );
});
