/* eslint-disable jsx-a11y/iframe-has-title */
import { useEffect, useState } from 'react';

const StarterPresentation = ({ children }: any) => {
    require('../../assets/styles/starter/components/starter_presentation.scss');

    useEffect(() => {

    }, []);
    return (
        <div className="starter_presentation">
            <menu className="menu">
                <ul>
                    <li className="rezise_prez">
                        <svg fill="#000000" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                            <g id="SVGRepo_iconCarrier">
                                {' '}
                                <path d="M20.469 24.469l-3.719 3.721v-24.379l3.719 3.719c0.136 0.135 0.323 0.218 0.529 0.218 0.415 0 0.751-0.336 0.751-0.751 0-0.206-0.083-0.393-0.218-0.528l-5.001-5c-0.067-0.067-0.147-0.122-0.236-0.159l-0.005-0.002-0.002-0.002-0.013-0.001c-0.081-0.034-0.175-0.054-0.274-0.055h-0c-0.036 0.005-0.069 0.012-0.1 0.020l0.005-0.001c-0.038 0.005-0.071 0.012-0.103 0.021l0.005-0.001c-0.132 0.032-0.246 0.095-0.338 0.181l0-0-5 5c-0.131 0.135-0.212 0.319-0.212 0.523 0 0.414 0.336 0.75 0.75 0.75 0.203 0 0.388-0.081 0.523-0.213l3.72-3.72v24.378l-3.72-3.72c-0.136-0.134-0.322-0.218-0.528-0.218-0.415 0-0.751 0.336-0.751 0.751 0 0.207 0.083 0.394 0.219 0.529l5.004 5.002c0.066 0.066 0.145 0.119 0.232 0.157l0.005 0.002 0.002 0.002c0.085 0.035 0.184 0.056 0.287 0.057h0c0.104-0 0.203-0.022 0.293-0.060l-0.005 0.002c0.69-0.437 0.769-0.49 0.848-0.543l-0.605 0.383 5.001-5c0.136-0.136 0.22-0.324 0.22-0.531 0-0.415-0.336-0.751-0.751-0.751-0.207 0-0.395 0.084-0.531 0.22v0z" />
                                {' '}
                            </g>
                        </svg>
                    </li>
                    <li className="add_link">
                        <a href="/news.html/add_presentation">
                            <svg fill="#000000" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                <g id="SVGRepo_iconCarrier">
                                    {' '}
                                    <path d="M30 15.25h-13.25v-13.25c0-0.414-0.336-0.75-0.75-0.75s-0.75 0.336-0.75 0.75v0 13.25h-13.25c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h13.25v13.25c0 0.414 0.336 0.75 0.75 0.75s0.75-0.336 0.75-0.75v0-13.25h13.25c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0z" />
                                    {' '}
                                </g>
                            </svg>
                        </a>
                    </li>
                </ul>
            </menu>
            <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vT2U0pKwJwmq_T6nvk44xUSzGeK8DfHmxuhLGjc9mhkEXzHk1MqW9Q38ccdsl7E-bLjxmf1CeHVuAhj/embed?start=false&loop=false&delayms=3000" />
        </div>
    );
};

export default StarterPresentation;
