import { memo } from 'react';
import styles from './Tree.module.scss';
import { classNames } from '../../../../utils/classNames/classNames';
import { VStack } from '../../../../components/ui/Stack';
import { ISkeletonNode, SkeletonNode } from '../Node/SkeletonNode';

interface SkeletonTreeProps {
  className?: string;
  node: ISkeletonNode;
}

export const SkeletonTree = memo((props: SkeletonTreeProps) => {
    const { className, node } = props;
    return (
        <VStack max className={classNames(styles.Tree, {}, [className])}>
            <SkeletonNode node={node} isChildren={false} />
        </VStack>
    );
});
