/* eslint-disable react/jsx-no-useless-fragment */
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import Breadcrumbs from './Header/Breadcrumbs';
import CurrentDate from './Header/CurrentDate';

const BackCrumbsDate = observer(() => {
    require('../assets/styles/header/back_crumbs_date.scss');
    const [showBack, setShowBack] = useState<boolean>(false);

    useEffect(() => {
        const path = window.location.pathname.split('/');
        path.shift();
        if (path[0] === '') {
            setShowBack(false);
        } else {
            setShowBack(true);
        }
    }, []);
    return (
        <>
            {showBack && (
                <div className="back_crumbs_date">
                    <div className="left">
                        {/* <span onClick={onBack}><BackArrowSvg /></span> */}
                        <Breadcrumbs />
                    </div>
                    <CurrentDate />
                </div>
            )}
        </>
    );
});

export default BackCrumbsDate;
