import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useMemo } from 'react';
import { OrgSChemaService } from '../../services/OrgSchema.service';
import { useSchemasStore } from '../../store/schemas';

export interface SchemaSettingsProps {
  isChildrenTogether: boolean;
  isSchemaHidden: boolean;
}

export const useNodeSettings = () => {
    const queryClient = useQueryClient();
    const activeSchemaId = useSchemasStore((state) => state.activeSchemaId);

    const { mutate } = useMutation({
        mutationKey: ['update user status'],
        mutationFn: ({ id, data, type }: {id: string, data: SchemaSettingsProps, type: 'blocks' | 'schemas'}) => OrgSChemaService.updateSchemaSettings(id, data, type),
        onSuccess: (response) => {
            toast.success('Настройки успешно изменены');

            queryClient.invalidateQueries({ queryKey: ['orgSchema', activeSchemaId] });
        },
        onError(error: any) {
            if (
                error.response
      && error.response.status
      && error.response.data.message
            ) {
                toast.error(error.response.data.message);
            } else {
                toast.error('Ошибка при создании схемы');
            }
        },
    });

    return useMemo(() => ({
        mutate,
    }), [mutate]);
};
