/* eslint-disable react/destructuring-assignment */
interface IProps {
    id: string,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    value: string|undefined,
    className?: string,
}
const InputFiled = (props: IProps) => {
    require('../../assets/styles/html/InputFiled.scss');
    return (
        <input
            type="text"
            className={`InputFiled ${props.className}`}
            id={props.id}
            name={props.id}
            onChange={props.onChange}
            value={props.value}
            placeholder=""
        />
    );
};

export default InputFiled;
