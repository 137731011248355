import { useState } from 'react';
import AdminUserEdit from '../components/Admin/Edits/AdminUserEdit';
import Layout from '../components/Layout';
import AdminUserTable from '../components/Admin/Tabels/AdminUserTable';
import AdminModuleTable from '../components/Admin/Tabels/AdminModuleTable';
import AdminEditModule from '../components/Admin/Edits/AdminEditModule';
import AdminPositionTable from '../components/Admin/Tabels/AdminPositionTable';
import AdminAddPosition from '../components/Admin/Edits/AdminAddPosition';
import AdminGroupTable from '../components/Admin/Tabels/AdminGroupTable';
import { IUser } from '../assets/interfaces/interfaces';

interface ITable {
    table: string,
    edit: boolean,
    showTable: boolean,
}
const Admin = () => {
    require('../assets/styles/admin/admin.scss');
    const [tables, setTables] = useState<ITable[]>([
        { table: 'users', edit: false, showTable: true },
        { table: 'createuser', edit: false, showTable: false },
        { table: 'group', edit: false, showTable: false },
        { table: 'module', edit: false, showTable: false },
        { table: 'position', edit: false, showTable: false },
    ]);

    const [editData, setEditData] = useState<any>({});
    const [editDataPosition, setEditDataPosition] = useState<any>({});

    const findTable = (table: string): ITable => tables.filter((obj: ITable) => obj.table === table)[0];
    const closeTables = () => {
        const newTable = tables.slice();
        newTable.forEach((val: ITable) => {
            val.showTable = false;
            val.edit = false;
        });
        setTables(newTable);
    };
    const openTable = (table: string, edit: boolean, data?: any) => {
        closeTables();
        const selectedTable: ITable = findTable(table);
        const indexTable = tables.indexOf(selectedTable);
        const newTable = tables.slice();
        if (edit) {
            if (table === 'position') {
                setEditDataPosition(data);
            } else if (table === 'users') {
                setEditData(data);
            }
            newTable[indexTable].edit = true;
            newTable[indexTable].showTable = false;
        } else {
            newTable[indexTable].edit = false;
            newTable[indexTable].showTable = true;
        }

        setTables(newTable);
    };
    return (
        <Layout>
            <div className="admin_container">
                <div className="admin_container__nav">
                    <div
                        className="btn"
                        onClick={() => openTable('users', false)}
                    >
                        Пользователи
                    </div>
                    <div
                        className="btn"
                        onClick={() => openTable('createuser', false)}
                    >
                        Создать пользователя
                    </div>
                    <div
                        className="btn"
                        onClick={() => openTable('group', false)}
                    >
                        Настройка групп
                    </div>
                    <div
                        className="btn"
                        onClick={() => openTable('module', false)}
                    >
                        Настройка модулей
                    </div>
                    <div
                        className="btn"
                        onClick={() => openTable('position', false)}
                    >
                        Список должностей
                    </div>
                </div>

                {findTable('users').edit && (
                    <AdminUserEdit
                        onSave={() => openTable('users', false)}
                        data={editData}
                    />
                )}
                {findTable('createuser').showTable && (
                    <AdminUserEdit
                        onSave={() => openTable('users', false)}
                    />
                )}
                {findTable('users').showTable && (
                    <AdminUserTable
                        onEdit={(data: IUser) => openTable('users', true, data)}
                    />
                )}

                {findTable('module').showTable && (
                    <AdminModuleTable
                        onEdit={() => openTable('module', true)}
                    />
                )}
                {findTable('module').edit && (
                    <AdminEditModule
                        onSave={() => openTable('module', false)}
                    />
                )}

                {findTable('group').showTable && (
                    <AdminGroupTable />
                )}

                {findTable('position').edit && (
                    <AdminAddPosition
                        data={editDataPosition}
                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => setEditDataPosition(e.target.value)}
                        onAdd={() => openTable('position', false)}
                    />
                )}

                {findTable('position').showTable && (
                    <AdminPositionTable
                        onEdit={(data: any) => openTable('position', true, data)}
                        onAdd={() => openTable('position', true)}
                    />
                )}

            </div>
        </Layout>
    );
};

export default Admin;
