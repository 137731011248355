import { useEffect, useState } from 'react';

const UserBlockWhatsapp = () => {
    require('../../assets/styles/user/whatsappblock.scss');
    const openWhatsapp = () => {
        const win: any = window;
        win.open('https://web.whatsapp.com/', '_blank').focus();
    };
    return (
        <div className="whatsappblock" onClick={openWhatsapp}>
            <div className="whatsappblock__left">
                <span className="link">Ссылка на WhatsApp</span>
                <span className="logo" />
            </div>
            <div className="whatsappblock__right">
                <div className="title">Наша группа в WhatsApp</div>
                <div className="desc">Это группа, в которой размещаются важные новости, сообщения, уведомления</div>
            </div>
        </div>
    );
};

export default UserBlockWhatsapp;
